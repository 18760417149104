<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      verificationSuccessInfo: {
        type: this.$t('email-verify.type-success'),
        messageI18Key: 'email-verify.success-message'
      },
      verificationErrorInfo: {
        type: this.$t('email-verify.type-error'),
        messageI18Key: 'email-verify.error-message'
      },
      createBookingErrorMessage: {
        type: 'Error',
        errorMessageI18Key: 'payment.booking_failed_title'
      },
      clientBookingData: null
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo'
    })
  },
  mounted() {
    const { id, expires, signature } = this.$route.params
    if ((id, expires, signature)) {
      let payload = { id, expires, signature }
      this.emailVerify(payload).then(data => {
        if (data && data.success) {
          this.getAccountInfo()
            .then(() => {
              this.setAuthStatus({ isAuthorized: true, socialAuthType: 'manual' })
              if (this.accountInfo) this.$router.push('/thank-you-page')
              else this.$router.push('/sign-in')
            })
            .catch(errors => {
              if (errors.success === false) {
                this.$validator.errors.add({
                  field: 'email',
                  msg: this.$t('signup.user-messages.wrong_email_or_password')
                })
              }
              this.$router.push('/not-found')
            })
          setTimeout(this.setSuccess(this.verificationSuccessInfo), 100)
        } else {
          this.$router.push('/not-found')
          setTimeout(this.setError(this.verificationErrorInfo), 100)
        }
      })
    } else {
      this.$router.push('/not-found')
      setTimeout(this.setError(this.verificationErrorInfo), 100)
    }
  },
  methods: {
    ...mapActions({
      emailVerify: 'user/emailVerify',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess',
      getAccountInfo: 'user/getAccountInfo',
      setAuthStatus: 'user/setAuthStatus'
    }),
    showModal() {
      this.$bvModal.show('verification-modal')
      this.$modal.show('verification-modal')
    }
  }
}
</script>
