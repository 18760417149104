import { StaffsRepository } from '@/_api/staff.repository'

const createStaff = ({ commit }, payload) => {
  return StaffsRepository.create(payload).then(({ data: staffResponse }) => {})
}

const getStaffBySalonId = ({ commit }, payload) => {
  return StaffsRepository.getAllBySalonId(payload).then(({ data }) => {
    commit('SET_STAFF_INFO', data.data)
  })
}

const getStaffListBySalonId = ({ commit }, salonId) => {
  return StaffsRepository.getListAllBySalonIdCalendar(salonId).then(({ data }) => {
    commit('SET_STAFF_LIST', data.data.items)
  })
}

const getListAllByParams = ({ commit }, payload) => {
  return StaffsRepository.getListAllByParams(payload).then(({ data }) => {
    let staffList = []

    if (data.data) {
      staffList = data.data.items
    }

    commit('SET_STAFF_LIST_BY_PARAMS', staffList)

    return data
  })
}

const updateStaffProfileInfo = ({ commit }, payload) => {
  return StaffsRepository.update(payload)
}

const createManager = ({ commit }, payload) => {
  return StaffsRepository.createManager(payload)
}

const createTopManager = ({ commit }, payload) => {
  return StaffsRepository.createTopManager(payload)
}

const updateManagerProfileInfo = ({ commit }, payload) => {
  return StaffsRepository.updateManager(payload)
}
const deleteStaff = ({ commit }, payload) => {
  return StaffsRepository.delete(payload.id).then(() => {
    commit('DELETE_STAFF', payload)
  })
}

const deleteManager = ({ commit }, payload) => {
  return StaffsRepository.deleteManager(payload.id).then(() => {
    commit('DELETE_MANAGER', payload)
  })
}

const getStaffProfileInfo = ({ commit }, staffId) => {
  return StaffsRepository.getProfileInfo(staffId).then(({ data: staffProfileInfo }) => {
    const profileInfo = { profile: { ...staffProfileInfo }, role: 'Staff' }
    commit('SET_WORKER_PROFILE_INFO', profileInfo)
  })
}
const getStaffServicesInfo = ({ commit }, staffId) => {
  return StaffsRepository.getProfileInfo(staffId).then(data => {
    commit('SET_STAFF_PROFILE_INFO', data.data)
  })
}
const getManagerProfileInfo = ({ commit }, managerId) => {
  return StaffsRepository.getManagerProfileInfo(managerId).then(({ data: managerProfileInfo }) => {
    const profileInfo = { profile: { ...managerProfileInfo }, role: 'Manager' }
    commit('SET_WORKER_PROFILE_INFO', profileInfo)
  })
}

export default {
  createStaff,
  createManager,
  getStaffBySalonId,
  updateStaffProfileInfo,
  updateManagerProfileInfo,
  getListAllByParams,
  getStaffProfileInfo,
  getManagerProfileInfo,
  deleteManager,
  getStaffListBySalonId,
  deleteStaff,
  getStaffServicesInfo,
  createTopManager
}
