import { WorkDaysRepository } from '@/_api/workdays.repository'

const getWorkDays = ({ commit }, payload) => {
  return WorkDaysRepository.getAllBySalonId(payload).then(response => {
    let result = response.data.items
    commit('SET_WORK_DAYS', result)

    return result
  })
}

const updateAll = ({ commit }, payload) => {
  return WorkDaysRepository.updateAll(payload).then(response => {
    let result = response.data.items
    commit('SET_WORK_DAYS', result)

    return result
  })
}

export default {
  getWorkDays,
  updateAll
}
