import { SalonsRepository } from '@/_api/salons.repository'

const createSalon = ({ commit }, payload) => {
  return SalonsRepository.create(payload).then(response => {
    const data = response.data.data
    commit('SET_SALON', data)
  })
}

const updateSalon = ({ commit }, payload) => {
  return SalonsRepository.update(payload)
    .then(response => {
      const data = response.data.data
      commit('SET_SALON', data)

      return data
    })
    .catch(errors => {
      return errors
    })
}

const getSalonCombination = ({ commit }, payload) => {
  return SalonsRepository.getSalonCombination(payload)
    .then(response => {
      return response.data.data
    })
    .catch(errors => {
      return errors
    })
}

const getInfo = ({ commit }, payload) => {
  return SalonsRepository.getInfo(payload)
    .then(response => {
      const data = response.data.data
      commit('SET_SALON', data)

      return data
    })
    .catch(errors => {
      return errors
    })
}

const getGeneral = ({ commit }, payload) => {
  return SalonsRepository.getGeneral(payload)
    .then(response => {
      const data = response.data.data
      commit('SET_GENERAL', data)

      return data
    })
    .catch(errors => {
      return errors
    })
}

const sendToModeration = ({ commit }, payload) => {
  return SalonsRepository.sendToModerate(payload).then(response => {
    const data = response.data
    commit('SET_GENERAL', data)

    return data
  })
}
const checkModeration = ({ commit }, payload) => {
  return SalonsRepository.checkModerate(payload).then(response => {
    const data = response.data
    commit('SET_MODERATE_FLAG', data)

    return data
  })
}

const changePayble = ({ commit }, payload) => {
  return SalonsRepository.changePayble(payload).then(response => {
    const data = response.data
    commit('SET_SALON_PAYBLE', data.payable)

    return data
  })
}

export default {
  createSalon,
  updateSalon,
  getInfo,
  getGeneral,
  getSalonCombination,
  sendToModeration,
  checkModeration,
  changePayble
}
