const getDefaultState = () => {
  return {
    total: {
      totalSum: 0,
      totalBookings: 0,
      totalAverage: 0
    },
    groupBookings: [],
    clients: {},
    booking: null,
    staff: {},
    salonRating: {},
    overview: null
  }
}

const SET_TOTAL = (state, payload) => {
  state.total = payload
}

const SET_GROUP_BOOKING_BYSERVICE = (state, payload) => {
  state.groupBookings = payload
}

const SET_CLIENTS = (state, payload) => {
  state.clients = payload

  if (!Array.isArray(payload.data)) {
    state.clients.data = Object.values(payload.data)
  }
}

const SET_BOOKINGS = (state, payload) => {
  state.booking = payload
}

const SET_STAFF = (state, payload) => {
  state.staff = payload

  if (!Array.isArray(payload.data)) {
    state.staff.data = Object.values(payload.data)
  }
}

const SET_SALON_RATING = (state, payload) => {
  state.salonRating = payload
}

const SET_OVERVIEW = (state, payload) => {
  state.overview = payload
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_TOTAL,
  SET_GROUP_BOOKING_BYSERVICE,
  SET_CLIENTS,
  SET_BOOKINGS,
  SET_STAFF,
  SET_SALON_RATING,
  SET_OVERVIEW,
  RESET_STATE,
  getDefaultState
}
