import { ProductsRepository } from '@/_api/products.repository'

const create = ({ commit }, payload) => {
  return ProductsRepository.create(payload).then(({ data }) => {
    return data
  })
}

const update = ({ commit }, payload) => {
  return ProductsRepository.update(payload).then(({ data }) => {
    return data
  })
}

const deleteProduct = ({ commit }, payload) => {
  return ProductsRepository.delete(payload).then(({ data }) => {
    commit('DELETE_PRODUCTS', payload)

    return data
  })
}

const getOne = ({ commit }, payload) => {
  return ProductsRepository.getOne(payload).then(({ data }) => {
    return data
  })
}

const getAll = ({ commit }, payload) => {
  return ProductsRepository.getAll(payload).then(response => {
    const data = response.data.data

    commit('SET_PRODUCTS', data)

    return data
  })
}

export default {
  create,
  update,
  deleteProduct,
  getOne,
  getAll
}
