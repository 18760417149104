import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import {
  required,
  min,
  max,
  alpha,
  email,
  alpha_num,
  numeric,
  confirmed,
  decimal,
  min_value,
  max_value
} from 'vee-validate/dist/rules.esm.js'

import en from 'vee-validate/dist/locale/en'
import de from 'vee-validate/dist/locale/de'
import fr from 'vee-validate/dist/locale/fr'

Validator.localize({ en: en, de: de, fr: fr })

Validator.extend('required', required)
Validator.extend('min', min)
Validator.extend('max', max)
Validator.extend('alpha', alpha)
Validator.extend('email', email)
Validator.extend('alpha_num', alpha_num)
Validator.extend('numeric', numeric)
Validator.extend('confirmed', confirmed)
Validator.extend('decimal', decimal)
Validator.extend('min_value', min_value)
Validator.extend('max_value', max_value)

const config = {
  locale: 'en',
  events: 'blur'
}

Vue.use(VeeValidate, config)
