const getDefaultState = () => {
  return {
    categories: {},
    categoriesForSelect: [],
    routeToRedirectFromCategoriesPage: null
  }
}

const SET_CATEGORIES = (state, payload) => {
  state.categories = payload

  if (!Array.isArray(payload.data)) {
    state.categories.data = Object.values(payload.data)
  }
}

const SET_CATEGORIES_FOR_SELECT = (state, payload) => {
  state.categoriesForSelect = payload
}

const DELETE_CATEGORY = (state, payload) => {
  let indexDeleted = state.categories.data.findIndex(category => category.id === payload)
  state.categories.data.splice(indexDeleted, 1)

  if (state.categories.total !== 0) {
    state.categories.total = state.categories.total - 1
  }
}
const SET_ROUTE_PATH = (state, payload) => {
  state.routeToRedirectFromCategoriesPage = payload
}
const CLEAR_ROUTE_PATH = (state, payload) => {
  state.routeToRedirectFromCategoriesPage = null
}
const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_CATEGORIES,
  SET_CATEGORIES_FOR_SELECT,
  DELETE_CATEGORY,
  SET_ROUTE_PATH,
  CLEAR_ROUTE_PATH,
  RESET_STATE,
  getDefaultState
}
