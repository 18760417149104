const getSalonsStaffInfo = state => state.salonStaff
const getStaffList = state => state.staffList
const getStaffListByParams = state => state.staffListByParams
const getProfileInfo = state => state.workerProfileInfo
const getStaffServices = state => state.staffServices

export default {
  getSalonsStaffInfo,
  getStaffList,
  getProfileInfo,
  getStaffListByParams,
  getStaffServices,
}
