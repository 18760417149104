<template>
  <main>
    <section class="contact-us-bg full-page-height navbar-offset-tablet">
      <NavbarPublic :has-border="false"></NavbarPublic>
      <div class="container pb-md-2 contact-us-page">
        <div class="row d-flex justify-content-center">
          <div class="col col-lg-10 col-xl-8 mt-md-5 mt-2">
            <h1 class="font-weight-semibold text-center mb-5">{{ $t('contact-us.title') }}</h1>
            <b-tabs content-class="mt-1-5" fill class="custom-tabs">
              <b-tab title-link-class="justify-content-center" title-item-class="w-auto" active>
                <template v-slot:title>
                  <span>
                    <font-awesome-icon :icon="['far', 'envelope']" class="mr-0-5"></font-awesome-icon>
                    <span class="text-black">Email</span>
                  </span>
                </template>
                <form class="mt-md-0 mt-4" @submit.prevent="sendMessage">
                  <div class="d-flex mt-5">
                    <div class="col pl-0">
                      <label for="repeatPass" class="poppins-font text-black mb-1 font-weight-medium">
                        {{ $t('contact-us.name-input-title') }}
                      </label>
                      <div class="input-group mb-2-5">
                        <input
                          id="name"
                          v-model="contactMessageData.full_name"
                          v-validate="'required|alpha'"
                          class="form-control contact-input w-100 py-2-5"
                          name="name"
                          type="text"
                          autocomplete="false"
                        />
                        <span v-if="errors.has('name')" class="error-message my-1">{{ errors.first('name') }}</span>
                      </div>
                    </div>
                    <div class="col pr-0">
                      <label for="repeatPass" class="poppins-font text-black mb-1 font-weight-medium">
                        {{ $t('contact-us.email-input-title') }}
                      </label>
                      <div class="input-group mb-2-5">
                        <input
                          id="emal"
                          v-model="contactMessageData.email"
                          v-validate="'required|email|min:5'"
                          class="form-control contact-input w-100 py-2-5"
                          name="email"
                          type="emal"
                          autocomplete="false"
                        />
                        <span v-if="errors.has('email')" class="error-message my-1">{{ errors.first('email') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-1">
                    <label for="repeatPass" class="poppins-font text-black mb-1 font-weight-medium">
                      {{ $t('contact-us.message-input-title') }}
                    </label>
                    <div class="input-group mb-2-5">
                      <textarea
                        id="message"
                        v-model="contactMessageData.message"
                        v-validate="'required'"
                        class="message-input form-control w-100"
                        name="message"
                        type="text"
                        autocomplete="false"
                        :data-vv-as="$t('booking.forms.label.message')"
                      />
                      <span v-if="errors.has('message')" class="error-message my-1">{{ errors.first('message') }}</span>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap">
                    <div class="col">
                      <p class="mb-0 mr-md-3 mt-0-8 mb-2">
                        {{ $t('contact-us.our-team') }}
                      </p>
                    </div>
                    <div class="col-lg-3 col-12">
                      <button type="submit" class="btn py-1 btn-primary btn-block">
                        <span>{{ $t('contact-us.submit-button-text') }}</span>
                      </button>
                    </div>
                  </div>
                  <div class="w-100 mt-2-5 d-flex align-items-center mb-2-5">
                    <div class="border-line col"></div>
                    <span class="mx-3 text-gray"> {{ $t('common.forms.label.or') }}</span>
                    <div class="border-line col"></div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <img src="@/assets/images/contact-us/email.svg" alt="" class="mw-100 mr-2" />
                    <h3 class="poppins-font mt-1">info@quiff.ch</h3>
                  </div>
                </form>
              </b-tab>
              <b-tab title-link-class="justify-content-center" content-class="pb-10" title-item-class="w-auto">
                <template v-slot:title>
                  <span>
                    <span class="text-black">{{ $t('contact-us.tab-title-second') }}</span>
                  </span>
                </template>
                <div class="mt-5 mb-2 col-12 d-flex flex-wrap justify-content-center">
                  <h3 class="poppins-font mb-3 text-center">{{ $t('contact-us.chat-with-us') }}</h3>
                  <div class="col-12 px-0 d-flex justify-content-center">
                    <a
                      href="https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=quiff.zendesk.com&settings=JTdCJTIyd2ViV2lkZ2V0JTIyJTNBJTdCJTIyY2hhdCUyMiUzQSU3QiUyMnRpdGxlJTIyJTNBbnVsbCUyQyUyMm1lbnVPcHRpb25zJTIyJTNBJTdCJTIyZW1haWxUcmFuc2NyaXB0JTIyJTNBdHJ1ZSU3RCUyQyUyMmRlcGFydG1lbnRzJTIyJTNBJTdCJTdEJTJDJTIycHJlY2hhdEZvcm0lMjIlM0ElN0IlMjJkZXBhcnRtZW50TGFiZWwlMjIlM0FudWxsJTJDJTIyZ3JlZXRpbmclMjIlM0FudWxsJTdEJTJDJTIyb2ZmbGluZUZvcm0lMjIlM0ElN0IlMjJncmVldGluZyUyMiUzQW51bGwlN0QlMkMlMjJjb25jaWVyZ2UlMjIlM0ElN0IlMjJhdmF0YXJQYXRoJTIyJTNBbnVsbCUyQyUyMm5hbWUlMjIlM0FudWxsJTJDJTIydGl0bGUlMjIlM0FudWxsJTdEJTdEJTJDJTIyY29sb3IlMjIlM0ElN0IlMjJhcnRpY2xlTGlua3MlMjIlM0ElMjIlMjIlMkMlMjJidXR0b24lMjIlM0ElMjIlMjIlMkMlMjJoZWFkZXIlMjIlM0ElMjIlMjIlMkMlMjJsYXVuY2hlciUyMiUzQSUyMiUyMiUyQyUyMmxhdW5jaGVyVGV4dCUyMiUzQSUyMiUyMiUyQyUyMnJlc3VsdExpc3RzJTIyJTNBJTIyJTIyJTJDJTIydGhlbWUlMjIlM0FudWxsJTdEJTdEJTdE&&locale=de-de&title=Live-Chat%20%C3%BCber%20Web%20Widget"
                      target="_blank"
                      title="Live Chat"
                      class="btn btn-primary mt-2 py-1 btn-lg"
                      ><span>Live Chat</span></a
                    >
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="justify-content-center" title-item-class="w-auto">
                <template v-slot:title>
                  <span>
                    <font-awesome-icon :icon="['far', 'layer-plus']" class="mr-0-5"></font-awesome-icon>
                    <span class="text-black">{{ $t('contact-us.tab-title-third') }}</span>
                  </span>
                </template>
                <div>
                  <h5 class="text-center my-5">{{ $t('contact-us.feature-request') }}</h5>
                  <div class="col d-flex justify-content-center">
                    <div class="col-lg-4 col-md-6 col">
                      <button class="btn btn-lg btn-block btn-primary px-1-5" @click="redirectToKamp">
                        {{ $t('contact-us.tab-title-third') }}
                      </button>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { isEmpty } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import NavbarPublic from '@/components/NavbarPublic'

export default {
  components: {
    NavbarPublic
  },
  data() {
    return {
      isActiveDefaultValue: 1,
      contactMessageData: {
        type: 'contact',
        full_name: '',
        email: '',
        message: ''
      },
      createSuccessMessage: {
        type: 'Success!',
        messageI18Key: 'common.alerts.actions.created'
      }
    }
  },
  computed: {
    isEmptyData() {
      return isEmpty(this.contactMessageData)
    },
    ...mapGetters({
      getAuthInfo: 'user/getAuthInfo'
    })
  },
  beforeCreate: function() {
    window.scrollTo(0, -200)
    if (!this.getAuthInfo || (this.getAuthInfo && !this.getAuthInfo.isAuthorized)) {
      let navbar = document.getElementById('top-navbar')
      if (navbar) {
        navbar.classList.remove('bg-white')
        navbar.classList.add('bg-inherit')
      }
    }
  },
  methods: {
    sendMessage() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.fetchContact(this.contactMessageData)
            .then(data => {
              if (data) this.setSuccess(this.createSuccessMessage)
            })
            .catch(err => {
              const errorPayload = {
                type: err.message,
                message: err.data.message,
                code: err.code
              }

              this.setError(errorPayload)
            })
        }
      })
    },
    ...mapActions({
      fetchContact: 'user/contact',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    redirectToKamp() {
      window.open('https://quiff.kampsite.co/', '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.message-input {
  height: 230px;
}
main:after {
  content: '';
  display: table;
  clear: both;
}
.contact-input {
  height: 48px !important;
  font-size: 16px !important;
}
@media (max-width: 1199.98px) {
  .contact-input {
    height: 42px !important;
    font-size: 15px !important;
  }
  .message-input {
    font-size: 15px !important;
  }
}
@media (max-width: 999.98px) {
  h3 {
    font-size: 1.4rem;
  }
  h1 {
    font-size: 1.8rem;
  }
}
</style>
