<template>
  <main>
    <NavbarPublic :has-border="true"></NavbarPublic>
    <div class="navbar-offset mt-7 mt-md-10 marketplace-main-info">
      <div class="container w-100">
        <h1 class="mb-2 mt-5">
          Du suchst einen Coiffeur in der Nähe? Wir haben ihn!
        </h1>
        <p class="mb-2">
          Die Suche nach dem perfekten Coiffeur hat sich in der Vergangenheit als die sprichwörtliche Suche nach der
          Nadel im Heuhaufen entpuppt? Diese Zeiten gehören ab sofort für immer der Vergangenheit an. Denn wir von Quiff
          haben es uns zur Aufgabe gemacht, Dir den für Dich perfekten Coiffeur in der Nähe zu präsentieren. Auf unserer
          Plattform findest Du den Coiffeursalon für Deine Wünsche und Anforderungen. Auch gezielt ein Coiffeur für
          Damen beziehungsweise einen Coiffeur für Herren. Wir bieten Dir eine vielfältige Liste, die für jeden
          Geschmack und jedes Geschlecht das richtige Angebot bereithält. Coiffeur Männer, Coiffeur Frauen, Coiffeur
          günstig oder Dienstleister, die Experten in ganz bestimmten Bereichen sind.
        </p>
        <p class="mb-2">
          Denn auf Quiff hast Du nicht nur die Möglichkeit, einen Coiffeur in der Nähe zu finden. Du kannst auch ganz
          gezielt nach Dienstleistungen suchen. Wenn Dir beispielsweise der wichtigste Tag Deines Lebens bevorsteht:
          Deine Hochzeit. Dann ist es besonders wichtig, dass Du perfekt aussiehst und Dich zudem wohl fühlst – mit
          Make-up, mit der Frisur, mit allem. Damit Dir das gelingt, musst Du einen Coiffeur in der Nähe finden, der
          Deinen Ansprüchen genügt. Der Dich versteht und der genau das umsetzt, was Du Dir wünschst.
        </p>
        <p class="mb-2">
          Aber auch, wenn Du einen ganz neuen Schnitt ausprobieren, Dir Deine Haare färben lassen oder Dir „nur“ die
          Spitzen schneiden lassen möchtest, ist Vertrauen wichtig. Schliesslich vertraust Du dem Coiffeur einen Teil
          Deiner Persönlichkeit an. Und Du vertraust darauf, dass Du Dich während Deines Besuchs und im Anschluss, mit
          Deiner neuen Frisur, wohlfühlst.
        </p>
        <h2 class="mb-2">
          Einen
          <a :href="coifeurOnlineBunchen" target="_blank" title="Coiffeur online buchen">Coiffeur online buchen</a> –
          leicht und unkompliziert
        </h2>
        <p class="mb-2">
          Wenn die Wahl auf Deinen Lieblingssalon gefallen ist, ist der Recht ein Kinderspiel. Du suchst Dir Deinen
          präferierten Termin aus und buchst Deinen Coiffeur mit einem Klick direkt online. Die Bestätigung unsererseits
          erfolgt unmittelbar danach – und zwar per E-Mail. Damit Du Deinen Termin auch garantiert nicht verpasst,
          senden wir Dir eine Stunde vorher noch einmal eine Erinnerung.
        </p>
        <p class="mb-2">
          Im Anschluss an den Besuch beim Coiffeur in der Nähe, hast Du die Möglichkeit, eine Bewertung abzugeben. Davon
          können dann auch andere potenzielle Kunden profitieren. Und profitieren kannst Du übrigens auch. Denn mit
          jeder Buchung sammelst Du wertvolle Treuepunkte, die Du anschliessend im Online-Shop Deines Coiffeurs einlösen
          kannst. Zum Beispiel kannst Du Deine favorisierten
          <router-link to="/" title="Haarprodukte"> Haarprodukte</router-link> oder Beautyproudukte auswählen. Stöbere
          doch einfach mal!
        </p>
        <div class="my-10 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.register')">
                {{ $t('footer-links.register') }}
              </router-link>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <a class="text-white" :href="`${clientAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.book') }}
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import NavbarPublic from '@/components/NavbarPublic'
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Viele Menschen möchten es unkompliziert und nah. Das gilt für`s Einkaufen, den Arztbesuch oder den Coiffeurbesuch. Den Coiffeur in der Nähe bietet Quiff!'
      }
    ]
  },
  components: {
    NavbarPublic
  },
  computed: {
    clientAppUrl() {
      return config.clientAppUrl
    },
    coifeurOnlineBunchen() {
      return `${this.clientAppUrl}/coiffeur-online-buchen`
    }
  },
  beforeCreate: function() {
    window.scrollTo(0, -200)
  }
}
</script>
