<template>
  <div class="signup-page">
    <div class="row no-gutters vh-100-lg desktop-sign-in-bg">
      <AuthSlider />

      <div class="col-md-12 col-lg col-xxl-6">
        <div class="row no-gutters h-100 px-1-5">
          <div class="col-12 mb-3 md-lg-0 mt-3 align-self-start px-lg-3 d-none d-lg-block">
            <p class="mb-0 text-center text-lg-right">
              <span class="text-secondary">{{ $t('signup.content.login_link_text') }}</span>
              <router-link to="/sign-in" class="ml-1" :title="$t('signup.content.login_link')">
                {{ $t('signup.content.login_link') }}
              </router-link>
              <LanguageDropdown :class="'ml-1-5'" :btn-class="'py-1-5'"></LanguageDropdown>
            </p>
          </div>

          <div class="col-12 col-md-8 col-lg-12 col-xl-8 mx-auto py-3">
            <div class="my-lg-auto my-lg-2-5 mx-lg-0 mx-auto w-100">
              <div class="text-center mb-2 mb-lg-3">
                <router-link to="/" class="navbar-brand mb-2-5 d-none d-lg-block d-lg-none">
                  <img src="@/assets/images/logo_quiff.svg" />
                </router-link>
                <h1 class="h2 mb-2">{{ $t('signup.content.title') }}</h1>
                <p>{{ $t('signup.content.subtitle') }}</p>

                <p class="mb-0 text-center d-block d-lg-none">
                  <span class="text-secondary">{{ $t('signup.content.login_link_text') }}</span>
                  <router-link to="/sign-in" class="ml-1" :title="$t('signup.content.login_link')">
                    {{ $t('signup.content.login_link') }}
                  </router-link>
                  <LanguageDropdown :class="'ml-1'" :btn-class="'py-1 px-0-5'"></LanguageDropdown>
                </p>
              </div>

              <form class="form-row no-gutters" autocomplete="off" @submit.prevent="signUp('manual')">
                <div class="col-12">
                  <label for="email" class="poppins-font text-black mb-1 font-weight-medium">{{
                    $t(emailField.i18KeyLabel)
                  }}</label>
                  <div class="input-group input-group-lg mb-2-5">
                    <input
                      id="email"
                      v-model="emailField.value"
                      v-validate="emailField.validateRules"
                      :type="emailField.type"
                      :name="emailField.name"
                      class="form-control w-100 px-1-5"
                      :placeholder="$t(emailField.i18KeyPlaceholder)"
                    />
                    <span v-if="errors.has(emailField.name)" class="error-message mt-1">{{
                      errors.first(emailField.name)
                    }}</span>
                  </div>
                </div>
                <div class="col-12">
                  <label for="number" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t('common.forms.label.auth.number') }}
                  </label>
                  <div class="input-group mb-md-2-5 mb-2">
                    <input
                      id="number"
                      v-model="numberField.value"
                      v-validate="numberField.validateRules"
                      class="form-control w-100"
                      :name="numberField.name"
                      :type="numberField.type"
                      :placeholder="$t(numberField.i18KeyPlaceholder)"
                      autocomplete="false"
                    />
                    <span v-if="errors.has(numberField.name)" class="error-message mt-1">{{
                      errors.first(numberField.name)
                    }}</span>
                  </div>
                </div>
                <div v-for="(field, index) in namesFields" :key="`${index}-field`" class="col-6">
                  <label for="firstName" class="poppins-font text-black mb-1 font-weight-medium">{{
                    $t(field.i18KeyLabel)
                  }}</label>
                  <div class="input-group input-group-lg mb-2-5">
                    <input
                      v-model="field.value"
                      v-validate="field.validateRules"
                      :name="field.name"
                      type="text"
                      :placeholder="$t(field.i18KeyPlaceholder)"
                      class="form-control w-100 px-1-5"
                    />
                    <span v-if="errors.has(field.name)" class="error-message mt-1">{{ errors.first(field.name) }}</span>
                  </div>
                </div>
                <div class="col-12">
                  <label for="password" class="poppins-font text-black mb-1 font-weight-medium">{{
                    $t(passwordField.i18KeyLabel)
                  }}</label>
                  <div class="input-group input-group-lg mb-2-5">
                    <input
                      id="password"
                      v-model="passwordField.value"
                      v-validate="passwordField.validateRules"
                      :type="passwordField.type"
                      :name="passwordField.name"
                      class="form-control px-1-5 border-right-0"
                      :placeholder="$t(passwordField.i18KeyPlaceholder)"
                    />
                    <div class="input-group-append" @click="changeType">
                      <span class="input-group-text text-gray bg-white px-1-5 py-1 border-left-0 cursor-pointer">
                        <font-awesome-icon :icon="['fas', passwordIcon]"></font-awesome-icon>
                      </span>
                    </div>
                    <span v-if="errors.has(passwordField.name)" class="error-message mt-1">{{
                      errors.first(passwordField.name)
                    }}</span>
                  </div>
                </div>
                <div class="col-12 form-group mb-1 form-check">
                  <div class="checkbox">
                    <label class="mb-0">
                      <input
                        v-model="confirmCondition"
                        v-validate="'required:true'"
                        type="checkbox"
                        name="terms"
                        :data-vv-as="'Terms'"
                      />
                      <i class="input-helper"></i>
                      <p class="text-black mb-0">
                        {{ $t('signup.content.agree_start') }}
                        <a :href="clientAppTermsUrl" target="_blank" :title="$t('signup.content.terms_link')">
                          {{ $t('signup.content.terms_link') }}
                        </a>
                        {{ $t('signup.content.terms_and') }}

                        <a :href="clientAppPrivacyUrl" target="_blank" :title="$t('signup.content.terms_link')">
                          {{ $t('signup.content.terms_link_second') }}
                        </a>
                        {{ $t('signup.content.terms_link_final') }}
                      </p>
                    </label>
                  </div>
                </div>

                <span v-if="errors.has('terms')" class="error-message mb-1-5 w-100">
                  {{ errors.first('terms') }}
                </span>

                <div class="col-12">
                  <button type="submit" class="btn btn-primary btn-block btn-lg mt-2-5">
                    {{ $t('signup.content.get_started') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import AuthSlider from './../../components/AuthSlider'
import { mapValues, keyBy, concat as _concat } from 'lodash'
import LanguageDropdown from '@/components/LanguageDropdown'
import config from 'config'

export default {
  name: 'AuthPage',
  components: {
    AuthSlider,
    LanguageDropdown
  },
  data() {
    return {
      fbSignInParams: {
        scope: 'email',
        return_scopes: true
      },
      confirmCondition: false,
      namesFields: [
        {
          i18KeyLabel: 'common.forms.label.auth.first_name',
          name: 'first_name',
          value: '',
          validateRules: 'required|alpha|max:40',
          i18KeyPlaceholder: 'common.forms.placeholder.auth.first_name'
        },
        {
          i18KeyLabel: 'common.forms.label.auth.last_name',
          name: 'last_name',
          value: '',
          validateRules: 'required|alpha|max:40',
          i18KeyPlaceholder: 'common.forms.placeholder.auth.last_name'
        }
      ],
      emailField: {
        i18KeyLabel: 'common.forms.label.auth.email',
        name: 'email',
        type: 'email',
        value: '',
        validateRules: 'required|email|min:5',
        i18KeyPlaceholder: 'common.forms.placeholder.auth.email'
      },
      numberField: {
        i18KeyLabel: 'common.forms.label.auth.number',
        name: 'phone',
        type: 'text',
        value: '',
        validateRules: 'required|decimal|min:5|max:13',
        i18KeyPlaceholder: 'common.forms.placeholder.auth.number'
      },
      passwordField: {
        i18KeyLabel: 'common.forms.label.auth.password',
        name: 'password',
        type: 'password',
        value: '',
        validateRules: 'required|min:8|max:16',
        i18KeyPlaceholder: 'common.forms.placeholder.auth.password'
      }
    }
  },
  computed: {
    passwordIcon() {
      return this.passwordField.type === 'password' ? 'eye' : 'eye-slash'
    },
    clientAppTermsUrl() {
      return `${config.clientAppUrl}/agb`
    },
    clientAppPrivacyUrl() {
      return `${config.clientAppUrl}/privacy-policy`
    }
  },
  mounted() {
    this.resetUserState()
    this.resetStateSalon()
    this.resetUserMessageState()
    this.resetSalonsState()
    this.resetHaircutrState()
    this.resetStateCalendar()
    this.resetStateStaff()
    this.resetStateDayOffs()
    this.resetStateServices()
  },
  methods: {
    registerRequiredFields() {
      let resultArray = JSON.parse(JSON.stringify(this.namesFields))
      resultArray.push(this.emailField, this.passwordField, this.numberField)
      return resultArray
    },
    ...mapActions('user', {
      register: 'register',
      login: 'login',
      getAccountInfo: 'getAccountInfo',
      socialAuth: 'socialAuth',
      setAuthStatus: 'setAuthStatus'
    }),
    ...mapActions('userMessages', {
      setError: 'setError'
    }),
    ...mapMutations({
      resetUserState: 'user/RESET_STATE',
      resetStateSalon: 'salons/RESET_STATE',
      resetUserMessageState: 'userMessages/RESET_STATE',
      resetSalonsState: 'salons/RESET_STATE',
      resetHaircutrState: 'haircutParams/RESET_STATE',
      resetStateCalendar: 'calendar/RESET_STATE',
      resetStateStaff: 'salonsStaff/RESET_STATE',
      resetStateDayOffs: 'dayoffs/RESET_STATE',
      resetStateServices: 'services/RESET_STATE'
    }),
    signUp() {
      this.$validator
        .validateAll()
        .then(result => {
          if (result) {
            const registerData = mapValues(keyBy(this.registerRequiredFields(), 'name'), 'value')
            this.register(registerData)
              .then(role => {
                this.getAccountInfo().then(() => {
                  this.setAuthStatus({ isAuthorized: true })

                  if (role === 'staff') this.$router.push('/calendar')
                  else if (role === 'manager') this.$router.push('/calendar')
                  else if (role === 'owner' || role === 'salon_manager') this.$router.push('/salon')
                })
              })
              .catch(errors => {
                let errorFields = Object.keys(errors.messages)
                let hasAnoterError = false

                errorFields.map(field => {
                  let errorString = errors.messages[field].join(', ')

                  if (field === 'email') {
                    this.$validator.errors.add({ field: field, msg: this.$t('common.error.wrong_email') })
                  } else {
                    hasAnoterError = true
                  }
                })

                if (hasAnoterError) {
                  const errorMessagesValues = errors.messages
                    ? Object.values(errors.messages)
                    : 'A sign up error occured!'
                  const errorMessagesString = Array.isArray(errorMessagesValues)
                    ? _concat(...errorMessagesValues).join(' ')
                    : 'A sign up error occured!'
                  this.setError({
                    type: 'sig_up_error',
                    message: errorMessagesString
                  })
                }
              })
          }
        })
        .catch(error => {
          let errrorMessage = {
            type: 'sign_up_validation_error'
          }

          if (error.data) {
            errrorMessage = {
              ...errrorMessage,
              ...{ message: error.data.message }
            }
          } else {
            errrorMessage = {
              ...errrorMessage,
              ...{ messageI18Key: 'common.alerts.actions.global_error' }
            }
          }

          this.setError(errrorMessage)
        })
    },

    changeType() {
      this.passwordField.type === 'password'
        ? (this.passwordField.type = 'text')
        : (this.passwordField.type = 'password')
    }
  }
}
</script>

<style lang="scss">
.sign-bg {
  background-size: auto !important;
}
.btn--google {
  height: 48px;
  border-radius: 5px;
  font-family: Poppins;
  text-transform: uppercase;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
}
body .fb-signin-button {
  height: 48px;
  border-radius: 5px;
  font-family: Poppins;
  text-transform: uppercase;
  background-color: #3b5998;
  color: #ffffff;
  svg {
    font-size: 1.25rem;
  }
}
</style>
