const getServices = state => state.services
const getServicesListByType = state => type => (state.serviceList[type] !== undefined ? state.serviceList[type] : [])
const getServicesListByTypes = state => types => {
  let serviceList = []

  types.forEach(type => {
    if (state.serviceList[type] !== undefined) {
      state.serviceList[type].forEach(service => serviceList.push(service))
    }
  })

  return serviceList
}

const getSalonServicesSelect = state => state.salonServicesSelect

export default {
  getServices,
  getServicesListByType,
  getServicesListByTypes,
  getSalonServicesSelect
}
