import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faFacebookF, faInstagram, faTwitter, faPinterestP, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import {
  faTrashAlt,
  faSortDown,
  faSortUp,
  faInfoCircle,
  faPlusCircle,
  faEllipsisV,
  faPhoneAlt,
  faUserSlash,
  faEnvelope as fasEnvelope,
  faUserCircle,
  faBan,
  faCalendarDay,
  faTimesCircle,
  faSyncAlt
} from '@fortawesome/free-solid-svg-icons'
import { faClock, faImage, faEnvelope } from '@fortawesome/free-regular-svg-icons'

import { faEye, faEyeSlash, faPencil, faDollarSign, faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import {
  faSearch,
  faTimes,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faPlus,
  faCheck,
  faCog,
  faGlobe,
  faLongArrowUp,
  faLayerPlus,
  faPhone,
  faRedoAlt,
  faMinus
} from '@fortawesome/pro-regular-svg-icons'
import { faLongArrowLeft, faLongArrowRight, faSignOutAlt, faEdit, faSave } from '@fortawesome/pro-light-svg-icons'
import {} from '@fortawesome/pro-duotone-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add([
  faFacebookF,
  faInstagram,
  faPinterestP,
  faLinkedinIn,
  faTwitter,
  faEyeSlash,
  faEye,
  faSearch,
  faTrashAlt,
  faPencil,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faSortDown,
  faSortUp,
  faInfoCircle,
  faDollarSign,
  faPlusCircle,
  faTimes,
  faLongArrowLeft,
  faLongArrowRight,
  faSignOutAlt,
  faCheckCircle,
  faCheck,
  faClock,
  faCog,
  faGlobe,
  faLongArrowUp,
  faEllipsisV,
  faImage,
  faLayerPlus,
  faEnvelope,
  faPhone,
  faRedoAlt,
  faPhoneAlt,
  fasEnvelope,
  faUserSlash,
  faPlus,
  faMinus,
  faEdit,
  faSave,
  faUserCircle,
  faBan,
  faCalendarDay,
  faTimesCircle,
  faSyncAlt
])

Vue.component('font-awesome-icon', FontAwesomeIcon)
