import moment from 'moment/src/moment'
import Vue from 'vue'
import { groupBy, map, merge, flatten, _ } from 'lodash'

const getDefaultState = () => {
  return {
    // Event slot not working, interim fix - https://github.com/vuetifyjs/vuetify/issues/7790
    bookings: [],
    daysOff: [],
    selectedDayOffId: null,
    previewBookingId: null,

    selectedStaff: null,
    calendarType: 'week',
    calendarDate: moment().format('YYYY-MM-DD'),
    choosenEmptyDayData: null,
    calendarDateFormatData: null,
    calendarScrollWidth: 0,
    lastCalandarPath: null
  }
}

const SET_CALENDAR_BOOKING = (state, payload) => {
  state.bookings = payload || []
}

const SET_CALENDAR_DAYSOFF = (state, payload) => {
  state.daysOff = payload || []
}

const CLEAR_CALENDAR_BOOKING = state => {
  state.bookings = []
}

const CLEAR_CALENDAR_DAYSOFF = state => {
  state.daysOff = []
}

const SET_SELECTED_DAYSOFF_ID = (state, payload) => {
  state.selectedDayOffId = payload
}

const SET_PREVIEW_BOOKING_ID = (state, payload) => {
  state.previewBookingId = payload
}

const RESET_PREVIEW_BOOKING_ID = state => {
  state.previewBookingId = null
}

const SET_CALENDAR_CHOOSEN_TIME = (state, payload) => {
  state.calendarDateFormatData = payload
}

const SET_SELECTED_STAFF = (state, payload) => {
  state.selectedStaff = payload
}

const SET_CALENDAR_TYPE = (state, payload) => {
  state.calendarType = payload
}

const SET_CALENDAR_DATE = (state, payload) => {
  state.calendarDate = moment(payload).format('YYYY-MM-DD')
}
const SET_CHOOSEN_DAY_DATA = (state, payload) => {
  state.choosenEmptyDayData = payload
}
const CLEAR_CHOOSEN_DAY_DATA = (state, payload) => {
  state.choosenEmptyDayData = null
}
const SET_CALENDAR_SCROLL_WIDTH = (state, payload) => {
  if (payload) state.calendarScrollWidth = payload
}

const SET_LAST_CALENDAR_PATH = (state, payload) => {
  state.lastCalandarPath = payload
}
const SET_CREATED_DAYOFF = (state, payload) => {
  if (!payload.start_time && !payload.end_time) {
    payload.start_time = `${payload.date} `
    payload.end_time = `${payload.date} `
  } else {
    payload.start_time = `${payload.date} ${payload.start_time}`
    payload.end_time = `${payload.date} ${payload.end_time}`
  }
  state.daysOff.push(payload)
}
const SET_EDITED_DAYOFF = (state, payload) => {
  if (!payload.start_time && !payload.end_time) {
    payload.start_time = `${payload.date} `
    payload.end_time = `${payload.date} `
  } else {
    payload.start_time = `${payload.date} ${payload.start_time}`
    payload.end_time = `${payload.date} ${payload.end_time}`
  }

  state.daysOff.forEach((el, idx) => {
    if (el.id === payload.id) Vue.set(state.daysOff, idx, payload)
  })
}

const SET_EDITED_BOOKING = (state, payload) => {
  state.bookings.forEach((el, idx) => {
    if (el.id === payload.id) Vue.set(state.bookings, idx, payload)
  })
}

const REMOVE_BOOKING_BY_ID = (state, payload) => {
  const index = state.bookings.findIndex(booking => booking.id === payload)
  state.bookings.splice(index, 1)
}

const REMOVE_DAYOFF_BY_ID = (state, payload) => {
  const index = state.daysOff.findIndex(dayOff => dayOff.id === payload)
  state.daysOff.splice(index, 1)
}

const SET_CREATED_BOOKING = (state, payload) => {
  if (payload) state.bookings.push(payload)
}
const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_CALENDAR_BOOKING,
  SET_CALENDAR_DAYSOFF,
  SET_CREATED_BOOKING,
  SET_SELECTED_DAYSOFF_ID,
  SET_PREVIEW_BOOKING_ID,
  RESET_PREVIEW_BOOKING_ID,

  SET_SELECTED_STAFF,
  SET_CALENDAR_TYPE,
  SET_CALENDAR_DATE,
  SET_CHOOSEN_DAY_DATA,
  CLEAR_CHOOSEN_DAY_DATA,
  SET_CALENDAR_CHOOSEN_TIME,
  SET_CALENDAR_SCROLL_WIDTH,
  SET_LAST_CALENDAR_PATH,
  SET_CREATED_DAYOFF,
  SET_EDITED_DAYOFF,
  SET_EDITED_BOOKING,
  REMOVE_BOOKING_BY_ID,
  REMOVE_DAYOFF_BY_ID,
  RESET_STATE,
  CLEAR_CALENDAR_BOOKING,
  CLEAR_CALENDAR_DAYSOFF,
  getDefaultState
}
