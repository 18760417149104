import { ClientsRepository } from '@/_api/clients.repository'

const getClients = ({ commit }, params) => {
  return ClientsRepository.getClients(params).then(({ data }) => {
    commit('SET_CLIENTS', data)
  })
}
const getClientsProfileInfo = ({ commit }, params) => {
  return ClientsRepository.getClientProfileInfo(params).then(({ data }) => {
    commit('SET_PROFILE_INFO', data)
  })
}

export default {
  getClients,
  getClientsProfileInfo
}
