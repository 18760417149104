<template>
  <main>
    <NavbarPublic :has-border="true"></NavbarPublic>
    <div class="mt-7 mt-md-10 marketplace-main-info">
      <div class="container w-100">
        <h1 class="mb-2 mt-5">Quiff – eine Coiffeur Software, die mehr kann</h1>
        <p class="mb-2">
          Die Coiffeur Software von Quiff macht es möglich: Sie nimmt Dir die komplette Verwaltungsarbeit und die
          Organisation von Terminen und Co. ab. So können sich jede Salon Besitzer und auch das gesamte Team voll und
          ganz den Kunden widmen und zu 100 Prozent Dienstleister sein. Und das Beste an der Salon Software: Quiff ist
          zu 100 Prozent Lizenzfrei! Es entstehen Dir für die Nutzung keinerlei Kosten!
        </p>
        <p class="mb-2">
          Wer kennt die Situation nicht: Man ist gerade am Haareschneiden und in ein angenehmes Gespräch mit dem Kunden
          vertieft. Auch die Kollegen gehen ihren Tätigkeiten nach und sorgen für ein maximales Wohlfühlgefühl bei ihren
          Kunden. Dann klingelt das Telefon. Ein Interessent möchte einen Termin. Klar, dass man das Läuten nicht
          ignorieren kann, schliesslich ist man nicht nur für seine aktuellen, sondern auch für die potenziellen Kunden
          da. Ärgerlich ist es dennoch, dass man seine Tätigkeiten unterbrechen muss. Schlimmstenfalls gleich mehrmals
          während eines Schnitts und mitten in einem angeregten Dialog.
        </p>
        <p class="mb-2">
          Und genau an diesem Punkt setzt die Coiffeur Software von Quiff an. Sie macht es möglich, dass Deine
          (potenziellen) Kunden ihren Termin online buchen und dass das Telefon in Eurem Salon im Idealfall stillsteht.
          Doch Quiff kann noch viel mehr. Dahinter steckt ein komplexes Softwaresystem, das für den Nutzer in der
          Handhabung dafür umso einfacher und verständlicher ist.
        </p>
        <h2 class="mb-2">
          Wie funktioniert unsere Coiffeur Software freeware?
        </h2>
        <p class="mb-2">
          Wir von Quiff haben uns nicht mehr und nicht weniger auf unsere Fahnen geschrieben, als den Coiffeurmarkt
          revolutionieren zu wollen. Und wer sich solch hohe Ziele setzt, der muss auch hohe Ansprüche an sich stellen –
          und diese maximal erfüllen. Mit unserer Coiffeur Software, die gleichzeitig auch Kassensoftware ist, ist uns
          das gelungen. Und die Nutzniesser unseres neuen Produkts sind Salonbetreiber und deren Mitarbeiter sowie
          Kunden gleichermassen. Für Eure Kunden bedeutet der Besuch auf unserer Plattform, dass sie sich bequem zuhause
          über ihren nächsten Friseurbesuch informieren können.
        </p>
        <p class="mb-2">
          Für Euch als Dienstleister bedeutet es tatsächlich, dass wir Euch die gesamte organisatorische Arbeit sowie
          das Marketing abnehmen. Wir bieten Euch und Eurem Salon eine Plattform, auf der ihr Euch präsentieren könnt
          und die von potenziellen Kunden in der ganzen Schweiz genutzt wird. Die Kunden klicken Euren Coiffeursalon an,
          bekommen freie Termine angezeigt und können ihren Coiffeur Termin online buchen. Dieser synchronisiert sich in
          Echtzeit mit Eurem Buchungssystem, sodass es keine Gefahr von Terminüberschneidungen gibt.
        </p>
        <p class="mb-2">
          Mit der neuen Coiffeur Software seid ihr rund um die Uhr erreichbar. So kann ein Kunde, der sich sonntags die
          Zeit nimmt, nach einem Salon zu stöbern, direkt buchen – und muss nicht noch zwei Tage warten. Auf diese Weise
          habt Ihr wiederum die Möglichkeit, dauerhaft präsent zu sein und auch Kunden zu akquieren, die Ihr bei einer
          rein telefonischen Terminbuchung nicht erreicht hättet.
        </p>
        <h2 class="mb-2">
          Jeder Mitarbeiter erhält seinen eigenen Zugang zur Software
        </h2>
        <p class="mb-2">
          Die Software macht es möglich, dass Du jeden Deiner Mitarbeiter im System hinterlegen kannst. Du kannst
          anschliessend selbst bestimmen, auf welche Bereiche Dein Team zugreifen kann und auf welche nicht. Wenn Du
          beispielsweise eine zweite Hand bei der Führung Deines Salons und damit auch der Coiffeur Software haben
          möchtest, kannst Du sogenannte Administratorenrechte vergeben. Du hast zudem die Möglichkeit, jeden einzelnen
          Mitarbeiter mit dessen Arbeitszeiten einzutragen. Das erkennt das System – und synchronisiert die freien
          Termine entsprechend. Der Kunde kann sogar einen bestimmten Mitarbeiter buchen! Nach dem Besuch bewertet der
          Kunde seinen Besuch direkt mit seinem persönlichen Login. Zusätzlich wird er vom System zur Bewertung
          aufgefordert bzw. höflich daran erinnert. Bewertungen sind heutzutage ein Muss und eine echte Orientierung für
          den Kunden.
        </p>
        <p class="mb-2">
          Doch das war und ist noch lange nicht alles. Du kannst das komplette, in Eurem Salon angebotene
          Dienstleistungsspektrum im System einpflegen. Make-up, Brautfrisuren, Haarverlängerung etc. Potenziellen
          Kunden, die danach suchen, wird Dein Coiffeur-Salon dann angezeigt und sie können ihren Coiffeur Termin online
          buchen.
        </p>
        <h2 class="mb-2">
          Intelligentes System für maximale Zeitersparnis
        </h2>
        <p class="mb-2">
          Mit dem System gehört das lästige Telefonieren der Vergangenheit an. Wir tun zudem alles dafür, dass Ihr in
          Eurem Salon keine Terminlücken mehr zu beklagen habt. Möglich macht dies unser Lückenfüllsystem. Das bedeutet:
          Wir haben ein dynamisches Preissystem entwickelt, welsches die Preise anpasst. Dadurch gibt es keine
          Terminlücken mehr. Dies ist der Schlüssel zur Maximierung Eurer Buchungen. Die Funktion ist optional wählbar –
          je nach Wunsch und individuellen Bedürfnissen. Im Anschluss erhält der Kunde eine Bestätigung und – eine
          Stunde vor dem Termin – eine Erinnerung per Mail.
        </p>
        <p class="mb-2">
          Darüber hinaus könnt Ihr beziehungsweise kann Euer Kunde entscheiden, wie er zahlen möchte. Denn die
          Coiffeursoftware ist auch zugleich Kassensoftware. Der Kunde kann entscheiden, ob er Eure Dienstleistungen
          online oder direkt in Eurem Salon zahlt. So sieht eine Revolution aus. So sieht die Coiffeur Software der
          Zukunft aus. Überzeuge auch Du Dich von den vielen Chancen und Möglichkeiten.
        </p>
        <div class="my-10 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.register')">
                {{ $t('footer-links.register') }}
              </router-link>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <a class="text-white" :href="`${clientAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.book') }}
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import NavbarPublic from '@/components/NavbarPublic'
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Die Coiffeur Software von Quiff revolutioniert den Markt. Online Reservierung und mehr! Einfach in der Anwendung für Salonbesitzer und Ihre Kunden! '
      }
    ]
  },
  components: {
    NavbarPublic
  },
  computed: {
    clientAppUrl() {
      return config.clientAppUrl
    }
  },
  beforeCreate: function() {
    window.scrollTo(0, -200)
  }
}
</script>
