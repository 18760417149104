<template>
  <main>
    <NavbarPublic :has-border="true"></NavbarPublic>
    <div class="mt-7 mt-md-10 marketplace-main-info">
      <div class="container w-100">
        <h1 class="mb-2 mt-5">
          Finde Deinen Coiffeur in Zürich und Umgebung
        </h1>
        <p class="mb-2">
          Du bist auf der Suche nach einem Coiffeur in Zürich? Du möchtest Dir beziehungsweise Deinen Haaren einen neuen
          Look verpassen lassen? Dir ist es wichtig, einen tollen Coiffeur bzw. Frisör in Zürich zu finden, der zu 100
          Prozent auf Deine Wünsche und Vorstellung eingeht? Herzlichen Glückwunsch! Du bist hier goldrichtig.
          Schliesslich bieten wir Dir eine Liste, die zahlreiche Coiffeure in Zürich und der näheren Umgebung
          bereithält. Das Spektrum reicht vom günstigen Coiffeur für die Preisbewussten bis hin zum exklusiven Friseur.
          Durch diese Vielfalt sorgen wir dafür, dass sich jeder mit seinen Wünschen und Anforderungen wiederfindet.
        </p>
        <p class="mb-2">
          Denn wenn wir eins wissen, dann dass Du Dich wohlfühlen musst. Dass Du Dich verstanden fühlst und deine
          Vorstellungen umgesetzt weisst. Haare sind Vertrauenssache. Deswegen ist es uns wichtig, dass Du den Coiffeur
          beziehungsweise den Hairstylist in Zürich und Umgebung findest, dem Du vertrauen kannst.
        </p>
        <h2 class="mb-2">
          Eine grosse Auswahl – für jeden Geschmack
        </h2>
        <p class="mb-2">
          Wir zeigen Dir, welche Coiffeure in Zürich welche Dienstleistungen anbieten. Wir bieten Dir eine komplette
          Übersicht, inklusive eines Feedbacks anderer Kunden. Das wiederum bietet Dir eine erste Orientierung. Die
          Entscheidung über den für Dich perfekten Coiffeur liegt natürlich in Deinen Händen. Schliesslich legt jeder
          andere Kriterien an, hat andere Anforderungen, findet andere Dinge wichtig. Vielleicht möchtest Du auch bei
          einem Besuch in Basel einen
          <a :href="coifeurBasel" title="Coiffeur in Basel" target="_blank">Coiffeur in Basel</a> besuchen, weil dieser
          grossartige Bewertungen hat? Oder lieber ein
          <a :href="coifeurBern" target="_blank" title="Coiffeur in Bern">Coiffeur in Bern?</a>
        </p>
        <p class="mb-2">
          Deswegen lautet unser Rat: Klicke Dich durch unser vielfältiges Angebot, schaue Dir die Salons in Ruhe an –
          und triff dann Deine Entscheidung. Wenn Du soweit bist, dann ist der Rest ganz einfach. Such Dir noch Deinen
          Wunschtermin und dann kannst mit einem Klick Deinen
          <a :href="coifeurBern" target="_blank" title="Coiffeur online buchen">Coiffeur online buchen</a>. Unmittelbar
          danach erhältst Du eine Bestätigung für Deine Reservierung. Auch kurzfristige Buchungen sind möglich. Das kann
          dann zum Tragen kommen, wenn Du nur zu Besuch oder auf Durchreise im wunderschönen Zürich bist. Wenn Du
          beispielsweise einen Coiffeur Zürich HB suchst. Dann zeigen wir Dir alle Frisöre in Zürich, die in der Nähe
          des Hauptbahnhofs sind und zu Deinem Wunschtermin Zeit haben. Auch im Falle einer kurzfristigen Buchung
          erhältst Du eine Bestätigung. Zudem versenden wir eine Erinnerung – sodass Du Deinen Termin beim Coiffeur in
          Zürich garantiert nicht vergisst.
        </p>
        <div class="my-10 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.register')">
                {{ $t('footer-links.register') }}
              </router-link>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <a class="text-white" :href="`${clientAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.book') }}
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import NavbarPublic from '@/components/NavbarPublic'
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Suche und buche deinen Coiffeur in Zürich und Umgebung. Ganz einfach und unkompliziert online und mit einer direkten Bestätigung. Einfach Quiff!'
      }
    ]
  },
  components: {
    NavbarPublic
  },
  computed: {
    clientAppUrl() {
      return config.clientAppUrl
    },
    coifeurOnlineBunchen() {
      return `${this.clientAppUrl}/coiffeur-online-buchen`
    },
    coifeurBern() {
      return `${this.clientAppUrl}/Coiffeur-Bern`
    },
    coifeurBasel() {
      return `${this.clientAppUrl}/Coiffeur-Basel`
    }
  },
  beforeCreate: function() {
    window.scrollTo(0, -200)
  }
}
</script>
