<template>
  <b-navbar
    toggleable="lg"
    type="light"
    fixed="top"
    class="tablet-navbar navbar py-0 border-bottom justify-content-center border-bottom-0 animated-0-3 fix"
    :class="{
      'py-1 squeezed': scrollY > 0,
      'py-2': scrollY <= 0,
      tabletNavbarIsOpened: scrollY <= 0 && isOpenedTabletMenu,
      'bg-inherit': this.$route.meta.layout === 'request' && scrollY <= 0,
      'bg-white': this.$route.meta.layout !== 'request' || (this.$route.meta.layout === 'request' && scrollY > 0)
    }"
  >
    <div class="container py-0 mx-0">
      <router-link to="/" class="px-1 px-sm-0">
        <img class="top-logo" src="@/assets/images/logo_quiff.svg" />
      </router-link>

      <div class="d-flex justify-content-end align-items-center">
        <a
          :href="clientAppUrl"
          class="text-primary d-inline-block d-lg-none mr-1"
          target="_blank"
          :title="$t('common.navbar.client-app-link')"
        >
          {{ $t('common.navbar.client-app-link') }}
        </a>

        <LanguageDropdown :class="'mr-1-5 py-0-8 d-inline-block d-lg-none'"></LanguageDropdown>

        <b-navbar-toggle target="nav-collapse" class="border-0 py-0-5"></b-navbar-toggle>
      </div>
      <b-collapse id="nav-collapse" is-nav @show="isOpenedTabletMenu = true" @hide="isOpenedTabletMenu = false">
        <b-navbar-nav class="align-items-center justify-conter-center w-100 pb-1 pb-lg-0 mr-4 d-flex">
          <li class="nav-item pl-1 ml-auto mr-0 d-none d-lg-inline-block">
            <a
              :href="clientAppUrl"
              class="nav-link btn btn-link text-primary px-0"
              target="_blank"
              :title="$t('common.navbar.client-app-link')"
            >
              {{ $t('common.navbar.client-app-link') }}
            </a>
          </li>
          <li class="nav-item pl-1 mr-0 d-none d-lg-inline-block">
            <router-link to="/request-demo" class="btn btn-checkbox p-1-3 custom-toggle">
              <span class="text-black"> {{ $t('common.navbar.client_request_demo_link') }} </span>
            </router-link>
          </li>
          <li class="nav-item pl-1 mr-0 d-none d-lg-inline-block">
            <router-link to="/contact-us" class="btn btn-checkbox p-1-3 custom-toggle">
              <span class="text-black"> {{ $t('common.navbar.client_contact_us_link') }}</span>
            </router-link>
          </li>

          <li class="w-100 d-none d-md-block d-lg-none pt-1 pb-2">
            <div class="col px-0 d-flex pt-1 justify-content-between">
              <div class="col col px-0 mr-1 nav-item mr-0 mt-1 d-lg-none inline-block">
                <router-link to="/contact-us" class="btn btn-checkbox py-1-3 btn-block custom-toggle">
                  <span class="text-black"> {{ $t('common.navbar.client_contact_us_link') }}</span>
                </router-link>
              </div>
              <div class="col col px-0 mx-1 nav-item mt-1 mr-0 d-lg-none inline-block">
                <router-link to="/request-demo" class="btn btn-checkbox py-1-3 btn-block custom-toggle">
                  <span class="px-0-2 text-black"> {{ $t('common.navbar.client_request_demo_link') }} </span>
                </router-link>
              </div>

              <div v-if="userRole" class="col col px-0 mx-1 nav-item mt-1 mr-0 d-lg-none inline-block">
                <b-link :to="'/salon'" class="btn btn-primary btn-block py-0-8 mr-2">
                  {{ $t('common.navbar.go_to_salon') }}
                </b-link>
              </div>

              <div v-if="userRole" class="col col px-0 mx-1 nav-item mt-1 mr-0 d-lg-none inline-block">
                <b-link :to="logOutItem.to" class="btn btn-checkbox btn-block py-0-8 mt-0-8 mt-md-0 mt-lg-0-8">
                  {{ $t(logOutItem.i18Key) }}
                  <font-awesome-icon :icon="['fal', 'sign-out-alt']" class="ml-0-5"></font-awesome-icon>
                </b-link>
              </div>

              <div v-if="!userRole" class="col col px-0 mx-1 nav-item mt-1 mr-0 d-lg-none inline-block">
                <b-link :to="'/sign-in'" class="btn btn-primary btn-block  py-0-8">
                  {{ $t('common.navbar.login-account-link') }}
                </b-link>
              </div>
            </div>
          </li>

          <li v-if="userRole" class="d-none d-lg-block nav-item px-0 ml-lg-1-5 mr-0 pt-1 pt-lg-0">
            <b-link :to="'/salon'" class="btn btn-primary py-0-8 mr-1-5">
              {{ $t('common.navbar.go_to_salon') }}
            </b-link>
          </li>

          <li v-if="userRole" class="d-none d-lg-block nav-item px-0 mr-0 pt-1 pt-lg-0">
            <b-link :to="logOutItem.to" class="btn btn-checkbox  py-0-8 mt-0">
              {{ $t(logOutItem.i18Key) }}
              <font-awesome-icon :icon="['fal', 'sign-out-alt']" class="ml-0-5"></font-awesome-icon>
            </b-link>
          </li>

          <li v-if="!userRole" class="d-none d-lg-block nav-item px-0 ml-lg-1-5 mr-0 pt-1 pt-lg-0">
            <b-link :to="'/sign-in'" class="btn btn-primary py-0-8">
              {{ $t('common.navbar.login-account-link') }}
            </b-link>
          </li>

          <li class="w-100 d-block px-1 px-sm-0 d-md-none pt-3 pb-2">
            <b-link v-if="userRole" :to="'/salon'" class="btn btn-primary py-0-8 w-100 mb-1">
              {{ $t('common.navbar.go_to_salon') }}
            </b-link>

            <b-link v-if="userRole" :to="logOutItem.to" class="btn btn-checkbox py-0-8 mt-0 w-100 mb-1">
              {{ $t(logOutItem.i18Key) }}
              <font-awesome-icon :icon="['fal', 'sign-out-alt']" class="ml-0-5"></font-awesome-icon>
            </b-link>

            <b-link v-if="!userRole" :to="'/sign-in'" class="btn btn-primary py-0-8 w-100 mb-1">
              {{ $t('common.navbar.login-account-link') }}
            </b-link>

            <b-link :to="'/contact-us'" class="btn btn-checkbox py-1-3 btn-block custom-toggle mb-1">
              <span class="text-black"> {{ $t('common.navbar.client_contact_us_link') }}</span>
            </b-link>

            <b-link :to="'/request-demo'" class="btn btn-checkbox py-1-3 btn-block custom-toggle">
              <span class="px-0-2 text-black"> {{ $t('common.navbar.client_request_demo_link') }} </span>
            </b-link>
          </li>

          <li class="nav-item ml-lg-1-5 mr-0 d-none d-lg-inline-block">
            <LanguageDropdown :btn-class="'py-1-2'"></LanguageDropdown>
          </li>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import LanguageDropdown from '@/components/LanguageDropdown.vue'
import { mapGetters } from 'vuex'
import config from 'config'

export default {
  name: 'NavbarPublic',
  components: {
    LanguageDropdown
  },
  data() {
    return {
      scrollY: 0,
      isOpenedTabletMenu: false,
      logOutItem: {
        i18Key: 'common.navbar.logout-link',
        to: '/sign-in'
      }
    }
  },
  watch: {
    isOpenedTabletMenu(newVal) {
      const navbar = document.querySelector('.tablet-navbar')
      if (navbar) {
        if (newVal) navbar.classList.add('tabletNavbarIsOpened')
        else navbar.classList.remove('tabletNavbarIsOpened')
      }
    }
  },
  computed: {
    ...mapGetters({
      userRole: 'user/getRole'
    }),
    clientAppUrl() {
      return config.clientAppUrl
    }
  },
  created: function() {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.scrollY = window.top.scrollY
  },
  destroyed: function() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(event) {
      this.scrollY = window.top.scrollY
    }
  }
}
</script>

<style lang="scss" scoped>
.header-profile a {
  font-size: 0.875rem;
}
</style>
