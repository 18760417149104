import Repository from './repository'

const listUrl = '/salon-services'
const listSelectUrl = '/get-salon-services'
const createUrl = '/salon-service-create'
const updateUrl = '/salon-service-update'
const deleteUrl = '/salon-service-delete'
const listServiceCategoriesUrl = '/services-categories'
const serviceDetailsUrl = '/service-details'
const salonServicesSelectUrl = 'salon-services-select'

export const ServicesRepository = {
  getAllBySalonId(payload) {
    return Repository.get(listUrl, { params: payload })
  },
  getListAllBySalonId(salonId) {
    return Repository.get(listSelectUrl, { params: { id: salonId } })
  },
  create(payload) {
    return Repository.post(createUrl, payload).then(data => data.data)
  },
  update(payload) {
    return Repository.post(updateUrl, payload).then(data => data.data)
  },
  delete(payload) {
    return Repository.delete(`${deleteUrl}/${payload}`)
  },
  getAllServicesCategories() {
    return Repository.get(listServiceCategoriesUrl)
  },
  getServiceDetailsById(payload) {
    return Repository.get(`${serviceDetailsUrl}?service_id=${payload}`)
  },
  getSalonServicesSelect(payload) {
    return Repository.get(`${salonServicesSelectUrl}?salon_id=${payload}`)
  }
}
