import Vue from 'vue'
import config from 'config'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: config.google_map_key,
    libraries: 'places',
    v: 3.38
  }
})
