import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import haircutParams from './modules/haircut-params'
import { userMessages } from './modules/user-messages'
import salonClients from './modules/clients'
import salons from './modules/salons'
import services from './modules/services'
import user from './modules/user'
import salonsStaff from './modules/salons-staff'
import workDays from './modules/work-days'
import gallery from './modules/gallery'
import dayoffs from './modules/dayoffs'
import calendar from './modules/calendar'
import payments from './modules/payments'
import booking from './modules/booking'
import categories from './modules/categories'
import products from './modules/products'
import reports from './modules/reports'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    haircutParams,
    userMessages,
    payments,
    salons,
    services,
    user,
    salonsStaff,
    workDays,
    gallery,
    salonClients,
    dayoffs,
    calendar,
    booking,
    categories,
    products,
    reports
  }
})

export default store
