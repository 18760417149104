import Vue from 'vue'
import {
  BNavbar,
  BNavbarNav,
  BNavbarBrand,
  BNavbarToggle,
  BNavItem,
  BNavItemDropdown,
  BDropdownItem,
  BLink,
  BButtonGroup,
  ModalPlugin,
  BCollapse,
  VBToggle,
  BButton,
  BDropdown,
  BFormRadioGroup,
  VBTooltip,
  BTooltip,
  BTabs,
  BTab,
  BAlert
} from 'bootstrap-vue'

Vue.component('b-collapse', BCollapse)
Vue.component('b-button', BButton)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-navbar', BNavbar)
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-navbar-brand', BNavbarBrand)
Vue.component('b-navbar-toggle', BNavbarToggle)
Vue.component('b-nav-item-dropdown', BNavItemDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-tooltip', BTooltip)

Vue.component('b-nav-item', BNavItem)
Vue.component('b-link', BLink)
Vue.component('b-button-group', BButtonGroup)
Vue.component('b-tabs', BTabs)
Vue.component('b-tab', BTab)
Vue.component('b-alert', BAlert)

Vue.directive('b-toggle', VBToggle)
Vue.directive('b-tooltip', VBTooltip)

Vue.use(ModalPlugin)
