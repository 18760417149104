<template>
  <section class="position-relative gradient-bg">
    <Video></Video>
  </section>
</template>
<script>
import Video from '@/components/Video'

export default {
  name: 'FifthSection',
  components: {
    Video
  }
}
</script>
