import Repository from './repository'

const calendarBookingsListByStaffUrl = '/bookings-by-staff'
const calendarBookingsListBySalonUrl = '/bookings-by-salon'
const dayOffsListByStaffUrl = '/booking-staff-daysoff'
const dayOffsListBySalonfUrl = '/booking-salon-daysoff'
const dayOffsListByManagerUrl = '/booking-manager-daysoff'

export const CalendarRepository = {
  /**
   * @param { salon_staff_id, day, search_param } payload
   */
  calendarBookingsListByStaffAndPeriod(payload) {
    return Repository.get(
      `${calendarBookingsListByStaffUrl}?salon_staff_id=${payload.salon_staff_id}&day=${payload.day}&search_param=${payload.period}`
    )
  },
  /**
   * @param { salon_staff_id, day, search_param } payload
   */
  calendarBookingStaffDaysoff(payload) {
    return Repository.get(
      `${dayOffsListByStaffUrl}?salon_staff_id=${payload.salon_staff_id}&day=${payload.day}&search_param=${payload.period}`
    )
  },
  calendarBookingManagerDaysoff(payload) {
    return Repository.get(
      `${dayOffsListByManagerUrl}?manager_id=${payload.manager_id}&day=${payload.day}&search_param=${payload.period}`
    )
  },
  /**
   * @param { salon_id, day, search_param } payload
   */
  calendarBookingsListBySalonAndPeriod(payload) {
    return Repository.get(
      `${calendarBookingsListBySalonUrl}?salon_id=${payload.salon_id}&day=${payload.day}&search_param=${payload.period}`
    )
  },
  /**
   * @param { salon_id, day, search_param } payload
   */
  calendarBookingSalonDaysoff(payload) {
    return Repository.get(
      `${dayOffsListBySalonfUrl}?salon_id=${payload.salon_id}&day=${payload.day}&search_param=${payload.period}`
    )
  }
}
