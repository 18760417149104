const getDefaultState = () => {
  return {
    salonBookingsHistory: null,
    paymentInfo: null,
    paymentReports: null
  }
}

const SET_BOOKINGS_HISTORY = (state, payload) => {
  state.salonBookingsHistory = payload
}

const SET_PAYMENTS_TOTAL = (state, payload) => {
  state.paymentInfo = payload
}

const SET_PAYMENTS_REPORTS = (state, payload) => {
  state.paymentReports = payload
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_BOOKINGS_HISTORY,
  SET_PAYMENTS_TOTAL,
  SET_PAYMENTS_REPORTS,
  RESET_STATE,
  getDefaultState
}
