import Vue from 'vue'
import App from './App.vue'
import config from 'config'
import router from './_router'
import store from './_store'
import i18n from './i18n'
import VueMeta from 'vue-meta'
import './_directives'
import './_services'
import './_themes'
// import './registerServiceWorker'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './assets/scss/bootstrap.scss'
import vuetify from './_themes/vuetify'
import LoadScript from '@/_utils/loadExternalScripts'

import VCalendar from 'v-calendar'
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  masks: {
    title: 'MMMM YYYY',
    weekdays: 'WWW',
    navMonths: 'MMM',
    input: ['MMM D, YYYY', 'DD/MM/YYYY'],
    dayPopover: 'WWW, MMM D, YYYY',
    data: ['MMM D, YYYY', 'DD/MM/YYYY']
  }
})

Vue.use(LoadScript)

Vue.config.productionTip = false

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.'
Vue.config.warnHandler = function(msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null
    vm = null
    trace = null
  }
}
Vue.use(VueMeta)

new Vue({
  i18n,
  vuetify,
  created() {
    window.fbAsyncInit = () => {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: config.fbAppId,
        status: true,
        cookie: true,
        xfbml: true,
        version: 'v5.0'
      })
      // eslint-disable-next-line no-undef
      FB.AppEvents.logPageView()
      // eslint-disable-next-line no-undef
      FB.getLoginStatus(response => {
        localStorage.setItem('facebook_auth', JSON.stringify(response))
      })
    }
    ;((d, s, id) => {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
