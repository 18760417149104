import Repository from './repository'

const workdaysListUrl = '/salon-workdays'
const updateAllUrl = '/salon-workdays-update'

export const WorkDaysRepository = {
  getAllBySalonId(paylaod) {
    return Repository.get(`${workdaysListUrl}?salon_id=${paylaod}`).then(data => data.data)
  },
  updateAll(payload) {
    return Repository.post(updateAllUrl, payload).then(data => data.data)
  }
}
