const getDefaultState = () => {
  return {
    params: {
      genders: [],
      hairLength: [],
      age: []
    }
  }
}

const SET_PARAMETERS_VALUES = (state, payload) => {
  const { genders, length, age } = payload
  state.params = { genders, hairLength: length, age }
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_PARAMETERS_VALUES,
  RESET_STATE,
  getDefaultState
}
