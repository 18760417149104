<template>
  <div id="app" :class="privatePage ? 'salon-flow' : ''">
    <SaleAlert v-if="saleModeOn" />

    <Navbar v-if="showNavBar" :has-border="false"></Navbar>

    <router-view v-show="!spinnerVisible" class="sale-offset" />

    <div v-show="spinnerVisible" class="navbar-offset-tablet">
      <div class="px-1-5 full-page-height d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    </div>

    <b-alert
      v-model="showCookieAlert"
      class="position-fixed fixed-bottom m-0 rounded-0 border-top row align-items-center px-0 bg-white"
      style="z-index: 2000;"
      variant="light"
    >
      <div class="col-12 col-md text-black text-center text-md-left">
        {{ $t('common.cookie_alert.title') }}
      </div>

      <div class="col-12 col-md-auto text-center mt-1-5 mt-md-0">
        <b-button class="px-3" variant="primary" @click="showCookieAlert = false">
          {{ $t('common.cookie_alert.button.ok') }}
        </b-button>
        <a class="ml-1-5 px-3 btn btn-primary" :href="clientAppPrivacyUrl" target="_blank">
          {{ $t('common.cookie_alert.button.learn_more') }}
        </a>
      </div>
    </b-alert>

    <Footer v-if="showFooter && isLoaded"></Footer>
  </div>
</template>

<script>
import { eventHub } from '@/_utils/eventhub'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import SaleAlert from '@/components/alert/Sale'
import { VApp } from 'vuetify/lib'
import config from 'config'

import Spinner from '@/views/Spinner'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    VApp,
    Spinner,
    SaleAlert
  },
  data() {
    return {
      isInit: false,
      isAuthorizedViaGoogle: false,
      isLoaded: true,
      spinnerVisible: false,
      showCookieAlert: true,
      zdassetsScript: '',
      saleModeOn: config.saleModeOn
    }
  },
  computed: {
    ...mapGetters({
      errorInfo: 'userMessages/getErrorInfo',
      successInfo: 'userMessages/getSuccessInfo',
      authInfo: 'user/getAuthInfo',
      accountInfo: 'user/getAccountInfo',
      acceptCookiesAlert: 'user/getAcceptCookiesAlert',
      siteLang: 'user/getSiteLang'
    }),
    showNavBar() {
      return this.$route.meta.layout !== 'auth' && this.$route.path !== '/contact-us'
    },
    privatePage() {
      return !this.$route.meta.public
    },
    showFooter() {
      return this.$route.name === 'home' || (this.$route.name && this.$route.name.includes('Coiffeur'))
    },
    clientAppPrivacyUrl() {
      return `${config.clientAppUrl}/privacy-policy`
    }
  },
  watch: {
    showCookieAlert(newVal) {
      this.toggleCookieAlert(true)
    },
    siteLang: {
      handler(newVal) {
        this.updatelang()
      }
    },
    errorInfo: {
      deep: true,
      handler(newVal) {
        if (newVal.errorType === 'info') {
          this.infoMessage(newVal)
          return
        }

        if (newVal.errorExists) {
          this.errorMessage(newVal)
        }
      }
    },
    successInfo: {
      deep: true,
      handler(newVal) {
        if (newVal.successExists) this.successMessage(newVal)
      }
    },
    $route(to, from) {
      if (to.name === 'sign-in' || to.name === 'sign-up') {
        this.manualLogout()
      }

      if (to.name === 'home' || to.name === 'contact-us') {
        this.addZdassetsScript()
      } else {
        this.hideZdassetsScript()
      }

      if (to.meta.i18PageTitle.length) {
        if (to.name.includes('Coiffeur')) document.title = to.meta.i18PageTitle
        else document.title = `Quiff | ${this.$t(to.meta.i18PageTitle)}`
      }

      this.isLoaded = true
    }
  },
  created() {
    eventHub.$on('before-request', this.showSpinner)
    eventHub.$on('request-error', this.hideSpinner)
    eventHub.$on('after-response', this.hideSpinner)
    eventHub.$on('after-response-without-timer', this.hideSpinnerWithoutTimer)
    eventHub.$on('response-error', this.hideSpinner)
  },
  mounted() {
    if (this.$route.name === 'home' || this.$route.name === 'contact-us') {
      this.addZdassetsScript()
    } else {
      this.hideZdassetsScript()
    }

    this.updatelang()
    this.showErrorAlerts()

    this.showCookieAlert = !this.acceptCookiesAlert

    if (this.$route.name === 'sign-in' || this.$route.name === 'sign-up') {
      this.manualLogout()
    }
  },
  methods: {
    ...mapActions({
      clearError: 'userMessages/clearError',
      clearSuccess: 'userMessages/clearSuccess',
      setError: 'userMessages/setError',
      getAccountInfo: 'user/getAccountInfo',
      setAuthStatus: 'user/setAuthStatus',
      manualLogout: 'user/logout'
    }),
    ...mapMutations('user', {
      toggleCookieAlert: 'TOGGLE_COOKIE_ALERT'
    }),
    addZdassetsScript() {
      this.$loadScript(
        'https://static.zdassets.com/ekr/snippet.js?key=76d654b0-0b77-45c1-bd7e-1aa99fd2304c',
        'ze-snippet'
      )
        .then(() => {
          this.removeZdassetsScript('show')
        })
        .catch(err => {})
    },
    hideZdassetsScript() {
      this.$unloadScript('https://static.zdassets.com/ekr/snippet.js?key=76d654b0-0b77-45c1-bd7e-1aa99fd2304c')
        .then(() => {
          this.removeZdassetsScript('hide')
        })
        .catch(err => {})
    },
    removeZdassetsScript(toogleType = 'hine') {
      let widgetIfrarme = document.querySelector('[data-product]')
      let widgetButton = document.getElementById('launcher')

      if (widgetIfrarme && widgetButton) {
        if (toogleType == 'hide') {
          widgetButton.classList.add('d-none')
          widgetIfrarme.classList.add('d-none')
        } else {
          widgetButton.classList.remove('d-none')
          widgetIfrarme.classList.remove('d-none')
        }
      }
    },
    showSpinner() {
      if (!this.spinnerVisible) {
        this.spinnerVisible = true
      }
    },
    hideSpinner() {
      setTimeout(() => {
        if (this.spinnerVisible) {
          this.spinnerVisible = false
        }
      }, 100)
    },
    hideSpinnerWithoutTimer() {
      if (this.spinnerVisible) {
        this.spinnerVisible = false
      }
    },
    updatelang() {
      this.$root.$i18n.locale = this.siteLang

      this.$nextTick(() => {
        if (this.$route.meta.i18PageTitle !== undefined && this.$route.meta.i18PageTitle.length) {
          document.title = `Quiff | ${this.$t(this.$route.meta.i18PageTitle)}`
        }

        this.$root.$validator.locale = this.siteLang
      })
    },
    showErrorAlerts() {
      if (this.errorInfo.errorExists) {
        switch (this.errorInfo.type) {
          case 'info':
            this.infoMessage(this.errorInfo)
            break
          case 'error':
            this.errorMessage(this.errorInfo)
            break
        }

        this.clearSuccess()
        this.clearError()
      }
    },
    toggleLoaded(val) {
      this.isLoaded = val
    },
    infoMessage(data) {
      let message = data.errorMessageI18Key !== '' ? this.$t(data.errorMessageI18Key) : data.errorMessage

      return this.$toasted.info(message, {
        position: 'top-center',
        duration: 2500,
        keepOnHover: true,
        action: {
          text: 'OK',
          onClick: (e, toastObject) => {
            this.clearError()
            toastObject.goAway(0)
          }
        },
        onComplete: () => {
          this.clearError()
        },
        singleton: true
      })
    },
    errorMessage(data) {
      let message = data.errorMessageI18Key !== '' ? this.$t(data.errorMessageI18Key) : data.errorMessage

      return this.$toasted.error(message, {
        position: 'top-center',
        duration: 2500,
        keepOnHover: true,
        action: {
          text: 'OK',
          onClick: (e, toastObject) => {
            this.clearError()
            toastObject.goAway(0)
          }
        },
        onComplete: () => {
          this.clearError()
        },
        singleton: true
      })
    },
    successMessage(data) {
      let message = data.successMessageI18Key !== '' ? this.$t(data.successMessageI18Key) : data.successMessage

      return this.$toasted.success(message, {
        position: 'top-center',
        duration: 2500,
        keepOnHover: true,
        action: {
          text: 'OK',
          onClick: (e, toastObject) => {
            this.clearSuccess()
            toastObject.goAway(0)
          }
        },
        onComplete: () => {
          this.clearSuccess()
        },
        singleton: true
      })
    }
  },
  beforeDestroy() {
    eventHub.$off('before-request', this.showSpinner)
    eventHub.$off('request-error', this.hideSpinner)
    eventHub.$off('after-response', this.hideSpinner)
    eventHub.$off('response-error', this.hideSpinner)
  }
}
</script>
<style>
label,
.group-label {
  color: #111517;
  font-family: Poppins;
}
.link-auth {
  position: absolute;
  right: 50px;
  top: 30px;
  z-index: 2;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fbfbfb !important;
}

.form-control {
  background-color: #fff !important;
}
</style>
