<template>
  <main>
    <NavbarPublic :has-border="true"></NavbarPublic>
    <div class="mt-7 mt-md-10 marketplace-main-info">
      <div class="container w-100">
        <h1 class="mb-2 mt-5">
          Mit Quiff werden Kunden verstärkt den Coiffeur Termin online buchen
        </h1>
        <p class="mb-2">
          Der Mehrwert für den Kunden und den Salon gleichermassen. Keine klingelnden Telefone mehr. Ausserdem die
          Chance, Dich und Deine Dienstleistungen mit Bild und Text zu präsentieren. Minimierung des
          Verwaltungsaufwands. Das sind nur einige der Vorteile, wenn Du Deine Kunden Ihren Coiffeur Termin online
          buchen lässt. Quiff ist die perfekte
          <router-link to="/Coiffeur-Software" title="Coiffeur Software"> Coiffeur Software</router-link> für Deinen
          Salon und übernimmt all die Aufgaben für Dich, die Dich davon abhalten, Deinem Job nachzugehen: dem
          Haareschneiden.
        </p>
        <p class="mb-2">
          Mit Quiff bietest Du Deinen Kunden die Möglichkeit, ihren Termin in Deinem Salon ganz bequem online zu buchen.
          Dieser wird dann eins zu eins in Dein System übertragen – und Du sparst Dir die Koordinierung. Doch es
          sprechen noch mehr Gründe dafür, dass Du als Coiffeur Dich mit Deinem Salon online auf Quiff präsentierst.
          Denn wir nehmen Dir nicht nur Verwaltungsarbeiten ab. Nein, wir übernehmen auch noch das Marketing für Dich,
          für Deinen Salon und für Deine Dienstleistungen.
        </p>
        <p class="mb-2">
          Und Du kannst die zahlreichen Möglichkeiten wahrnehmen, um Dich und Dein gesamtes Coiffeur Team zu
          präsentieren und damit Kunden zu animieren, ihren Coiffeur Termin online zu buchen. Du hast beispielsweise
          Spezialisten für spezielle Dienstleistungen in Deinem Team? Jemanden, der sich einen Namen damit gemacht,
          wunderschöne Brautfrisuren zu kreieren? Oder jemanden, der die Technik Balayage perfekt beherrscht? Du bietest
          spezielle Zusatzbehandlungen wie Wimpern und Augenbrauen färben an? Dann hast Du die Möglichkeit, das aktiv zu
          bewerben und auf diese Weise neue Kunden zu generieren.
        </p>
        <h2 class="mb-2">
          Preisliste Coiffeur: Deine Preise sind der Schlüssel
        </h2>
        <p class="mb-2">
          Kunden lieben maximale Transparenz, im Bezug auf Leistung, Fähigkeiten, und natürlich die Coiffeur Preisliste.
          Auf diese Weise generierst Du Vertrauen. Der Kunde weiss, was ihn erwartet, böse Überraschungen bleiben aus.
          Aber letzten Endes entscheidest Du ganz alleine, welche Details respektive Dienstleistungen Du als Coiffeur
          Deinen Kunden auf Quiff präsentieren möchtest. Im Gegensatz zu anderen Anbietern bietet Quiff Dir die Salon
          Software kostenlos an, teure monatliche Lizenzkosten entfallen! Deine Preisliste ist Teil des Konzepts und
          amortisiert die Plattform.
        </p>
        <p class="mb-2">
          Ein weiteres Gimmick: Du hast auch die Möglichkeit, Deine Pflege- und
          <a :href="`${clientAppUrl}/Haarprodukte`" title="Haarprodukte" target="_blank"> Haarprodukte</a> im Quiff
          Onlineshop zu präsentieren. Friseurbedarf online zu bestellen bzw. zu reservieren ist für viele Kunden ein
          Muss. Für Dich also die perfekte Chance den Verkauf zu fördern. Deine Kunden wiederum können ihre
          Lieblingsprodukte direkt online reservieren und bei ihrem nächsten Besuch in Deinem Coiffeursalon mitnehmen.
          Dadurch animierst Du sie dazu, auch ihren nächsten Coiffeur Termin online zu buchen und stärkst die
          Kundenbindung. Denn für jede online Buchung erhält der Kunde Treuepunkte.
        </p>
        <div class="my-10 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.register')">
                {{ $t('footer-links.register') }}
              </router-link>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <a class="text-white" :href="`${clientAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.book') }}
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import NavbarPublic from '@/components/NavbarPublic'
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Quiff ist die perfekt Coiffeur Plattform. Kostenlos! Saloninhaber und Kunden profitieren. Coiffeur Termin online verwalten, buchen & mehr!'
      }
    ]
  },
  components: {
    NavbarPublic
  },
  computed: {
    clientAppUrl() {
      return config.clientAppUrl
    },
    coifeurOnlineBunchen() {
      return `${this.clientAppUrl}/coiffeur-online-buchen`
    }
  },
  beforeCreate: function() {
    window.scrollTo(0, -200)
  }
}
</script>
