export default [
  '/salons-staff-filter',
  '/get-salon-combination',
  'users-search',
  'salon-page',
  '/staff-details',
  '/salon-workdays',
  '/booking-salon-daysoff',
  '/bookings-by-salon',
  '/salon-staffs-select-calendar',
  '/booking-staff-daysoff',
  '/bookings-by-staff',
  '/booking-manager-daysoff',
  '/dayoff-details',
  '/salon-daysoff-update',
  '/salon-daysoff-create',
  '/get-manual-book',
  '/products-remove',
  '/book-close',
  '/book-decline',
  '/book-pay',
  '/parameters',
  '/get-salon-services',
  '/manual-book',
  '/manual-book-update-addprices'
]
