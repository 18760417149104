const getTotal = state => state.total
const getGroupBookings = state => state.groupBookings
const getClients = state => state.clients
const getBookings = state => state.booking
const getStaff = state => state.staff
const getSalonRating = state => state.salonRating
const getOverview = state => state.overview

export default {
  getTotal,
  getGroupBookings,
  getClients,
  getBookings,
  getStaff,
  getSalonRating,
  getOverview
}
