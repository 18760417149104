import { ServicesRepository } from '@/_api/services.repository'

const getServicesBySalonId = ({ commit }, payload) => {
  return ServicesRepository.getAllBySalonId(payload).then(({ data }) => {
    commit('SET_SERVICES', data.data)

    return data.data
  })
}

const getServicesListBySalonId = ({ commit }, salonId) => {
  return ServicesRepository.getListAllBySalonId(salonId).then(({ data }) => {
    commit('SET_SERVICES_LIST', data.data)

    return data
  })
}

const createService = ({ commit }, payload) => {
  return ServicesRepository.create(payload).then(response => {
    const data = response.data.data

    return data
  })
}

const updateService = ({ commit }, payload) => {
  return ServicesRepository.update(payload).then(response => {
    const data = response.data.data

    return data
  })
}

const deleteServiceById = ({ commit }, payload) => {
  return ServicesRepository.delete(payload.id).then(() => {
    commit('DELETE_SERVICE', payload)
  })
}

const deleteSingleServiceById = ({ commit }, payload) => {
  return ServicesRepository.delete(payload).then(response => {
    const data = response.data

    return data
  })
}

const getServicesCategories = ({ commit }) => {
  return ServicesRepository.getAllServicesCategories().then(response => {
    const data = response.data.data
    commit('SET_SERVICES_CATEGORIES', data)

    return data
  })
}

const getServiceDetailsById = ({ commit }, payload) => {
  return ServicesRepository.getServiceDetailsById(payload).then(response => {
    const data = response.data.data

    return data
  })
}

const getSalonServicesSelect = ({ commit }, payload) => {
  return ServicesRepository.getSalonServicesSelect(payload).then(({ data }) => {
    commit('SET_SALON_SERVICES_SELECT', data.data)

    return data.data
  })
}

export default {
  getServicesBySalonId,
  createService,
  updateService,
  deleteServiceById,
  getServicesCategories,
  getServiceDetailsById,
  deleteSingleServiceById,
  getServicesListBySalonId,
  getSalonServicesSelect
}
