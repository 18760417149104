import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {}
})

const opts = {
  icons: {
    iconfont: 'fa',
    theme: { disable: true }
  },
  materialIcons: false,
  css: false,
  treeShake: true
}

export default new Vuetify(opts)
