import helper from './helper'

const getBookings = state => state.bookings
const getSalonBookings = state => state.salonBookings
const getSelectedStaff = state => state.selectedStaff
const getCalendarType = state => state.calendarType
const getCalendarDate = state => state.calendarDate
const getChoosenEmptyDay = state => state.choosenEmptyDayData
const getCalendarScrollWidth = state => state.calendarScrollWidth
const getLastCalandarPath = state => state.lastCalandarPath

const getSelectedDayOffId = state => state.selectedDayOffId
const getPreviewBookingId = state => state.previewBookingId

const getEventsByStaffID = state => id => {
  const bookings = helper.getStaffEvents(state)
  const daysOff = helper.getStaffDaysOff(state)
  const fullDayDaysOff = helper.getFullDaysOffByStaff(state)

  return bookings.concat(daysOff, fullDayDaysOff)
}

const getEventsSalonDayByParams = state => (staffList, startDate, type) => {
  if (type === 'week') {
    const bookings = helper.getSalonWeekEventsByParams(state, staffList, startDate, 'booking')
    const fullDayDaysOff = helper.getFullWeekSalonDaysOff(state, staffList, startDate)

    return bookings.concat(fullDayDaysOff)
  }

  const bookings = helper.getSalonDayEventsByParams(state, staffList, startDate, 'booking')
  const dayoffs = helper.getSalonDayEventsByParams(state, staffList, startDate, 'dayoff')
  const fullDayDaysOff = helper.getFullDaySalonDaysOff(state, staffList, startDate)

  return bookings.concat(dayoffs, fullDayDaysOff)
}

export default {
  getBookings,
  getSalonBookings,
  getSelectedStaff,
  getCalendarType,
  getChoosenEmptyDay,
  getCalendarDate,
  getCalendarScrollWidth,
  getLastCalandarPath,
  getEventsByStaffID,
  getEventsSalonDayByParams,
  getSelectedDayOffId,
  getPreviewBookingId
}
