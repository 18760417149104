import { ReportsRepository } from '@/_api/reports.repository'

const getTotal = ({ commit }, payload) => {
  return ReportsRepository.getTotal(payload).then(response => {
    const data = response.data.data

    commit('SET_TOTAL', data)

    return data
  })
}

const getByService = ({ commit }, payload) => {
  return ReportsRepository.getByService(payload).then(response => {
    const data = response.data.data

    commit('SET_GROUP_BOOKING_BYSERVICE', data)

    return data
  })
}

const getClient = ({ commit }, payload) => {
  return ReportsRepository.getClient(payload).then(response => {
    const data = response.data.data

    commit('SET_CLIENTS', data)

    return data
  })
}

const getBookings = ({ commit }, payload) => {
  return ReportsRepository.getBookings(payload).then(response => {
    const data = response.data.data

    commit('SET_BOOKINGS', data)

    return data
  })
}

const getStaffs = ({ commit }, payload) => {
  return ReportsRepository.getStaffs(payload).then(response => {
    const data = response.data.data

    commit('SET_STAFF', data)

    return data
  })
}

const getSalonRating = ({ commit }, payload) => {
  return ReportsRepository.getSalonRating(payload).then(response => {
    const data = response.data.data

    commit('SET_SALON_RATING', data)

    return data
  })
}

const getOverview = ({ commit }, payload) => {
  return ReportsRepository.getOverview(payload).then(response => {
    const data = response.data.data

    commit('SET_OVERVIEW', data)

    return data
  })
}

export default {
  getTotal,
  getByService,
  getClient,
  getBookings,
  getStaffs,
  getSalonRating,
  getOverview
}
