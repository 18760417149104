<template>
  <footer class="bg-black black-bg">
    <div class="container py-md-6 py-2">
      <div class="row align-items-center">
        <div class="col-md-2 col-12 d-flex justify-content-center">
          <a class="navbar-brand" href="#">
            <img src="@/assets/images/logo_quiff_white.svg" class="ml-2 ml-md-0" alt="Quiff" />
            <p class="mb-0 mt-1">© All rights reserved</p>
          </a>
        </div>
        <div class="col-xl-8">
          <ul class="nav justify-content-center mb-1 footer-nav">
            <li class="nav-item">
              <a class="nav-link" :href="clientAppUrl" :title="$t('common.footer.salon_owner')" target="_blank">
                {{ $t('common.footer.salon_owner') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="clientAppTermsUrl" :title="$t('common.footer.terms_of_use')" target="_blank">
                {{ $t('common.footer.terms_of_use') }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :href="clientAppTermsForPartnersUrl"
                :title="$t('common.footer.terms_of_use_for_partners')"
                target="_blank"
              >
                {{ $t('common.footer.terms_of_use_for_partners') }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :href="clientAppPrivacyUrl"
                :title="$t('common.footer.privacy_policy')"
                target="_blank"
              >
                {{ $t('common.footer.privacy_policy') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="clientAppImpressumUrl" :title="$t('common.footer.impressum')" target="_blank">
                {{ $t('common.footer.impressum') }}
              </a>
            </li>
            <li class="nav-item">
              <router-link to="/contact-us" class="nav-link">
                {{ $t('common.navbar.client_contact_us_link') }}
              </router-link>
            </li>
          </ul>
          <ul class="nav justify-content-center social-links">
            <li class>
              <a class href="https://www.linkedin.com/company/quiff-ch" target="_blank" title="Linkedin">
                <font-awesome-icon :icon="['fab', 'linkedin-in']" class="text-white"></font-awesome-icon>
              </a>
            </li>
            <li class>
              <a class href="https://www.instagram.com/quiff.ch/" target="_blank" title="Instagram">
                <font-awesome-icon :icon="['fab', 'instagram']" class="text-white"></font-awesome-icon>
              </a>
            </li>
            <li class>
              <a class href="https://www.facebook.com/quiff.ch" target="_blank" title="Facebook">
                <font-awesome-icon :icon="['fab', 'facebook-f']" class="text-white"></font-awesome-icon>
              </a>
            </li>
            <li class>
              <a class href="https://www.pinterest.ch/quiff_ch/" target="_blank" title="Pinterest">
                <font-awesome-icon :icon="['fab', 'pinterest-p']" class="text-white"></font-awesome-icon>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-xl-2 mt-1 mt-md-0 justify-content-md-end justify-content-center d-flex">
          <a
            href="#"
            class="scroll-to-top d-flex align-items-center justify-content-center"
            @click.prevent="scrollToTop"
          >
            <font-awesome-icon
              :icon="['far', 'long-arrow-up']"
              class="text-white w-24"
              style="font-size:24px"
            ></font-awesome-icon>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import config from 'config'

export default {
  name: 'Footer',
  computed: {
    clientAppUrl() {
      return config.clientAppUrl
    },
    clientAppTermsUrl() {
      return `${this.clientAppUrl}/agb`
    },
    clientAppTermsForPartnersUrl() {
      return `${this.clientAppUrl}/agb-for-partners`
    },
    clientAppPrivacyUrl() {
      return `${this.clientAppUrl}/privacy-policy`
    },
    clientAppImpressumUrl() {
      return `${this.clientAppUrl}/impressum`
    }
  },
  methods: {
    scrollToTop() {
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: 0
      })
    }
  }
}
</script>
