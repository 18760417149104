const getDefaultState = () => {
  return {
    images: []
  }
}

const SET_IMAGES = (state, payload) => {
  state.images = payload
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_IMAGES,
  RESET_STATE,
  getDefaultState
}
