<template>
  <div class="spinner">
    <PulseLoader :color="color" :size="size" />
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  components: {
    PulseLoader
  },
  data() {
    return {
      color: '#ff6b09',
      size: '25px'
    }
  }
}
</script>
<style>
.spinner {
  text-align: center;
}
</style>
