import Repository from './repository'
import axios from 'axios'
import config from 'config'

const salonPayAccountUrl = '/bank-account'
const filesVerificationUrl = 'https://files.stripe.com/v1/files'
const stripeTokenResource = 'https://api.stripe.com/v1/tokens'
const getHistoryBookingsUrl = '/bookings-history'
const paymentsListUrl = '/payment-info'
const paymentsReportsListUrl = '/payments-table'
const paymentsReportsPDFUrl = '/payments-pdf'
const manualPaymentUrl = '/manual-payment'
const publicStripeKey = config.stripePK

export const PaymentRepository = {
  sendTokens(payload) {
    return Repository.post(salonPayAccountUrl, payload)
  },
  stripeFilesVerification(payload) {
    return axios.post(filesVerificationUrl, payload, {
      headers: {
        Authorization: `Bearer ${publicStripeKey}`
      }
    })
  },
  createStripeToken(payload, stripeKey) {
    return axios.post(stripeTokenResource, payload, {
      headers: {
        Authorization: `Bearer ${stripeKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  getBookingsHistory(params) {
    return Repository.get(getHistoryBookingsUrl, { params })
  },
  getPaymentslist(payload) {
    return Repository.get(`${paymentsListUrl}/?salon_id=${payload}`)
  },
  getPaymentsReportslist(payload) {
    return Repository.get(paymentsReportsListUrl, { params: payload })
  },
  getPaymentsReportsPDF(payload) {
    return Repository.get(paymentsReportsPDFUrl, {
      params: payload,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf'
      }
    })
  },
  createManualPayment(payload) {
    return Repository.post(manualPaymentUrl, payload)
  }
}
