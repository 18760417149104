<template>
  <div class="col-12 col-lg-6 px-0">
    <div class="sign-bg h-100 w-100 d-flex flex-column justify-content-md-between">
      <router-link to="/" class="navbar-brand pl-2 pl-md-5 pt-3 pb-md-0 pb-1" :active="true">
        <img src="@/assets/images/logo_quiff_white.svg" alt="" />
      </router-link>

      <div id="carouselExampleIndicators" class="sliderPosition px-2 px-md-5 pb-lg-10" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <span class="h1 sliderHeadindFontSize text-white mb-1">
              {{ $t('signin.slider.title') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
