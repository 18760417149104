<template>
  <section class="">
    <div class="container cards pb-4 pb-md-5">
      <div class="row salonLpBottomCards">
        <div class="col-12 px-0 d-flex flex-wrap">
          <div id="scrollbar-5" class="col-xl-4 col-lg-6 col-12 mb-2">
            <div class="card points-bg-end pt-xxl-4 pt-2 px-2-5">
              <h3 class="d-none d-md-block text-center text-lg-left mb-2-5">
                {{ $t('main.sections.section_4.card_1.title') }}
              </h3>
              <h4 class="d-block d-md-none text-center text-lg-left mb-0 mb-lg-10">
                {{ $t('main.sections.section_4.card_1.title') }}
              </h4>
              <div class="d-none d-md-block" :class="{ 'pb-lg-8 pb-0': isDeutchLang, 'pb-lg-10 pb-0': !isDeutchLang }">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_1.description') }}
                </p>
              </div>

              <div class="pt-lg-5 d-flex justify-content-center pt-0 mb-md-1 mb-2">
                <img src="@/assets/images/salon-lp/free-marketing.svg" alt="" class="mw-100" />
              </div>
              <div class="mb-0-5 d-block d-md-none">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_1.description') }}
                </p>
              </div>
            </div>
          </div>
          <div id="scrollbar-6" class="col-xl-4 col-lg-6 col-12 mb-2">
            <div class="card points-bg-end pt-xxl-4 pt-2 px-2-5">
              <h3 class="mb-2-5 d-none d-md-block text-center text-lg-left">
                {{ $t('main.sections.section_4.card_2.title') }}
              </h3>
              <h4 class="d-block d-md-none mb-2-5 text-center text-lg-left">
                {{ $t('main.sections.section_4.card_2.title') }}
              </h4>
              <div class="mb-lg-6 mb-0 d-none d-md-block">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_2.description') }}
                </p>
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_2.description_2') }}
                </p>
              </div>
              <div
                class="mb-md-1 mb-2 d-flex justify-content-center"
                :class="{ 'pt-lg-8 pt-0': isDeutchLang, 'pt-lg-6 pt-0': !isDeutchLang }"
              >
                <img src="@/assets/images/salon-lp/no-calls.svg" alt="" class="mw-100" />
              </div>
              <div class="mb-0-5 d-block d-md-none">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_2.description') }}
                </p>
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_2.description_2') }}
                </p>
              </div>
            </div>
          </div>
          <div id="scrollbar-7" class="col-xl-4 col-lg-6 col-12 mb-2">
            <div class="card points-bg-end pt-xxl-4 pt-2 px-2-5">
              <h3 class="mb-2-5 d-none d-md-block text-center text-lg-left">
                {{ $t('main.sections.section_4.card_3.title') }}
              </h3>
              <h4 class="d-block d-md-none text-center mb-2-5 text-center text-lg-left">
                {{ $t('main.sections.section_4.card_3.title') }}
              </h4>
              <div class="d-none d-md-block" :class="{ 'mb-0': isDeutchLang, 'mb-6': !isDeutchLang }">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_3.description') }}
                </p>
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_3.description_2') }}
                </p>
              </div>
              <div class="mb-md-1 mb-2 d-flex justify-content-center">
                <img src="@/assets/images/salon-lp/filling-system.svg" alt="" class="mw-100" />
              </div>
              <div class="d-block d-md-none mb-0-5">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_3.description') }}
                </p>
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_3.description_2') }}
                </p>
              </div>
            </div>
          </div>
          <div id="scrollbar-8" class="col-xl-4 col-lg-6 col-12 mb-2">
            <div class="card points-bg-end pt-xxl-4 pt-2 px-2-5">
              <h3 class="d-none d-md-block mb-2-5 text-center text-lg-left">
                {{ $t('main.sections.section_4.card_4.title') }}
              </h3>
              <h4 class="d-block d-md-none text-center text-lg-left mb-2-5">
                {{ $t('main.sections.section_4.card_4.title') }}
              </h4>
              <div class="d-none d-md-block" :class="{ 'mb-lg-2 mb-0': isDeutchLang, 'mb-lg-6 mb-0': !isDeutchLang }">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_4.description') }}
                </p>
              </div>
              <div class="mb-md-1 mb-2 d-flex justify-content-center pt-0 pt-lg-6">
                <img src="@/assets/images/salon-lp/bookable.svg" alt="" class="mw-100" />
              </div>
              <div class="d-block d-md-none mb-0-5">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_4.description') }}
                </p>
              </div>
            </div>
          </div>
          <div id="scrollbar-9" class="col-xl-4 col-lg-6 col-12 mb-2">
            <div class="card points-bg-end pt-xxl-4 pt-2 px-2-5">
              <h3 class="mb-2-5 d-none d-md-block text-center text-lg-left">
                {{ $t('main.sections.section_4.card_5.title') }}
              </h3>
              <h4 class="d-block d-md-none text-center text-center text-lg-left ">
                {{ $t('main.sections.section_4.card_5.title') }}
              </h4>
              <div class="d-none d-md-block" :class="{ 'mb-lg-10 mb-0': isDeutchLang, 'mb-lg-8 mb-0': !isDeutchLang }">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_5.description') }}
                </p>
              </div>
              <div class="mb-md-1 pt-md-1 d-flex justify-content-center pt-0 mb-2">
                <img src="@/assets/images/salon-lp/reminders.svg" alt="" class="mw-100" />
              </div>
              <div class="d-block d-md-none mb-0-5">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_5.description') }}
                </p>
              </div>
            </div>
          </div>
          <div id="scrollbar-10" class="col-xl-4 col-lg-6 col-12 mb-2">
            <div class="card points-bg-end pt-xxl-4 pt-2 px-2-5">
              <h3 class="mb-2-5 d-none d-md-block text-center text-lg-left">
                {{ $t('main.sections.section_4.card_6.title') }}
              </h3>
              <h4 class="d-block d-md-none text-center mb-2-5 text-center text-lg-left">
                {{ $t('main.sections.section_4.card_6.title') }}
              </h4>
              <div class="d-none d-md-block mb-lg-4 mb-0">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_6.description') }}
                </p>
              </div>
              <div
                class="mb-md-1 mb-2 d-flex justify-content-center"
                :class="{ 'pt-lg-6 pt-0': isDeutchLang, 'pt-0 pt-lg-8': !isDeutchLang }"
              >
                <img src="@/assets/images/salon-lp/payments.svg" alt="" class="mw-100" />
              </div>
              <div class="d-block d-md-none mb-0-5">
                <p class="text-left">
                  {{ $t('main.sections.section_4.card_6.description') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 d-block d-md-none">
        <h4 class="mb-2 text-center">{{ $t('main.sections.section_4.still_have') }}</h4>

        <router-link class="btn py-1 mb-1 btn-block btn-primary btn-primary-bs" to="/contact-us">
          {{ $t('main.sections.section_4.contact') }}</router-link
        >
        <router-link class="btn py-1 btn-block btn-primary btn-primary-bs" to="/request-demo">
          {{ $t('main.sections.section_4.book-demo') }}</router-link
        >
      </div>
      <div class="col-12 mt-4 mt-md-2 px-0 d-flex flex-wrap justify-content-center">
        <h4 class="mr-2 mt-0 mt-md-1 text-center">{{ $t('main.sections.section_4.read-more-title') }}</h4>
        <div v-b-toggle="`accordion-1`" class="col-lg-2 col-md-4 col-12 mt-1 mt-md-0 px-0" block>
          <button class="btn btn-block btn-primary py-1">
            <span>{{ $t('main.sections.section_4.read-more') }}</span>
          </button>
        </div>
      </div>
      <b-collapse :id="`accordion-1`" accordion="my-root-accordion" role="tabpanel">
        <div class="col-12 px-0 mt-md-5 mt-3">
          <p class="mb-2">
            Die Coiffeur Software von Quiff macht es möglich: Sie nimmt Dir die komplette Verwaltungsarbeit und die
            Organisation von Terminen und Co. ab. So können sich jede Salon Besitzer und auch das gesamte Team voll und
            ganz den Kunden widmen und zu 100 Prozent Dienstleister sein. Und das Beste an der Salon Software: Quiff ist
            zu 100 Prozent Lizenzfrei! Es entstehen Dir für die Nutzung keinerlei Kosten!
          </p>
          <p class="mb-2">
            Wer kennt die Situation nicht: Man ist gerade am Haareschneiden und in ein angenehmes Gespräch mit dem
            Kunden vertieft. Auch die Kollegen gehen ihren Tätigkeiten nach und sorgen für ein maximales Wohlfühlgefühl
            bei ihren Kunden. Dann klingelt das Telefon. Ein Interessent möchte einen Termin. Klar, dass man das Läuten
            nicht ignorieren kann, schliesslich ist man nicht nur für seine aktuellen, sondern auch für die potenziellen
            Kunden da. Ärgerlich ist es dennoch, dass man seine Tätigkeiten unterbrechen muss. Schlimmstenfalls gleich
            mehrmals während eines Schnitts und mitten in einem angeregten Dialog.
          </p>
          <p class="mb-2">
            Und genau an diesem Punkt setzt die Coiffeur Software von Quiff an. Sie macht es möglich, dass Deine
            (potenziellen) Kunden ihren Termin online buchen und dass das Telefon in Eurem Salon im Idealfall
            stillsteht. Doch Quiff kann noch viel mehr. Dahinter steckt ein komplexes Softwaresystem, das für den Nutzer
            in der Handhabung dafür umso einfacher und verständlicher ist.
          </p>
          <h2 class="mb-2 text-center">Wie funktioniert unsere Coiffeur Software freeware?</h2>
          <p class="mb-2">
            Wir von Quiff haben uns nicht mehr und nicht weniger auf unsere Fahnen geschrieben, als den Coiffeurmarkt
            revolutionieren zu wollen. Und wer sich solch hohe Ziele setzt, der muss auch hohe Ansprüche an sich stellen
            – und diese maximal erfüllen. Mit unserer Coiffeur Software, die gleichzeitig auch Kassensoftware ist, ist
            uns das gelungen. Und die Nutzniesser unseres neuen Produkts sind Salonbetreiber und deren Mitarbeiter sowie
            Kunden gleichermassen. Für Eure Kunden bedeutet der Besuch auf unserer Plattform, dass sie sich bequem
            zuhause über ihren nächsten Friseurbesuch informieren können.
          </p>
          <p class="mb-2">
            Für Euch als Dienstleister bedeutet es tatsächlich, dass wir Euch die gesamte organisatorische Arbeit sowie
            das Marketing abnehmen. Wir bieten Euch und Eurem Salon eine Plattform, auf der ihr Euch präsentieren könnt
            und die von potenziellen Kunden in der ganzen Schweiz genutzt wird. Die Kunden klicken Euren Coiffeursalon
            an, bekommen freie Termine angezeigt und können ihren Coiffeur Termin online buchen. Dieser synchronisiert
            sich in Echtzeit mit Eurem Buchungssystem, sodass es keine Gefahr von Terminüberschneidungen gibt.
          </p>
          <p class="mb-2">
            Mit der neuen Coiffeur Software seid ihr rund um die Uhr erreichbar. So kann ein Kunde, der sich sonntags
            die Zeit nimmt, nach einem Salon zu stöbern, direkt buchen – und muss nicht noch zwei Tage warten. Auf diese
            Weise habt Ihr wiederum die Möglichkeit, dauerhaft präsent zu sein und auch Kunden zu akquieren, die Ihr bei
            einer rein telefonischen Terminbuchung nicht erreicht hättet.
          </p>
          <h2 class="mb-2 text-center">
            Jeder Mitarbeiter erhält seinen eigenen Zugang zur Software
          </h2>
          <p class="mb-2">
            Die Software macht es möglich, dass Du jeden Deiner Mitarbeiter im System hinterlegen kannst. Du kannst
            anschliessend selbst bestimmen, auf welche Bereiche Dein Team zugreifen kann und auf welche nicht. Wenn Du
            beispielsweise eine zweite Hand bei der Führung Deines Salons und damit auch der Coiffeur Software haben
            möchtest, kannst Du sogenannte Administratorenrechte vergeben. Du hast zudem die Möglichkeit, jeden
            einzelnen Mitarbeiter mit dessen Arbeitszeiten einzutragen. Das erkennt das System – und synchronisiert die
            freien Termine entsprechend. Der Kunde kann sogar einen bestimmten Mitarbeiter buchen! Nach dem Besuch
            bewertet der Kunde seinen Besuch direkt mit seinem persönlichen Login. Zusätzlich wird er vom System zur
            Bewertung aufgefordert bzw. höflich daran erinnert. Bewertungen sind heutzutage ein Muss und eine echte
            Orientierung für den Kunden.
          </p>
          <p class="mb-2">
            Doch das war und ist noch lange nicht alles. Du kannst das komplette, in Eurem Salon angebotene
            Dienstleistungsspektrum im System einpflegen. Make-up, Brautfrisuren, Haarverlängerung etc. Potenziellen
            Kunden, die danach suchen, wird Dein Coiffeur-Salon dann angezeigt und sie können ihren Coiffeur Termin
            online buchen.
          </p>
          <h2 class="mb-2 text-center">
            Intelligentes System für maximale Zeitersparnis
          </h2>
          <p class="mb-2">
            Mit dem System gehört das lästige Telefonieren der Vergangenheit an. Wir tun zudem alles dafür, dass Ihr in
            Eurem Salon keine Terminlücken mehr zu beklagen habt. Möglich macht dies unser Lückenfüllsystem. Das
            bedeutet: Wir haben ein dynamisches Preissystem entwickelt, welsches die Preise anpasst. Dadurch gibt es
            keine Terminlücken mehr. Dies ist der Schlüssel zur Maximierung Eurer Buchungen. Die Funktion ist optional
            wählbar – je nach Wunsch und individuellen Bedürfnissen. Im Anschluss erhält der Kunde eine Bestätigung und
            – eine Stunde vor dem Termin – eine Erinnerung per Mail.
          </p>
          <p class="mb-2">
            Darüber hinaus könnt Ihr beziehungsweise kann Euer Kunde entscheiden, wie er zahlen möchte. Denn die
            Coiffeursoftware ist auch zugleich Kassensoftware. Der Kunde kann entscheiden, ob er Eure Dienstleistungen
            online oder direkt in Eurem Salon zahlt. So sieht eine Revolution aus. So sieht die Coiffeur Software der
            Zukunft aus. Überzeuge auch Du Dich von den vielen Chancen und Möglichkeiten.
          </p>
        </div>
      </b-collapse>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FourthSection',
  computed: {
    ...mapGetters({
      getSiteLang: 'user/getSiteLang'
    }),
    isDeutchLang() {
      return this.getSiteLang === 'de'
    }
  }
}
</script>
