import { PaymentRepository } from '@/_api/payment.repository'

const getBookingsHistory = ({ commit }, params) => {
  return PaymentRepository.getBookingsHistory(params).then(({ data }) => {
    commit('SET_BOOKINGS_HISTORY', data.data)
  })
}

const getPayments = ({ commit }, payload) => {
  return PaymentRepository.getPaymentslist(payload).then(({ data }) => {
    commit('SET_PAYMENTS_TOTAL', data.data)
    return data.data
  })
}

const createManualPayment = ({ commit }, payload) => {
  return PaymentRepository.createManualPayment(payload).then(({ data }) => {
    commit('SET_PAYMENTS_TOTAL', data.data)
    return data.data
  })
}

const getPaymentsReports = ({ commit }, payload) => {
  return PaymentRepository.getPaymentsReportslist(payload)
    .then(({ data }) => {
      commit('SET_PAYMENTS_REPORTS', data.data.items)

      return data.data.items
    })
    .catch(error => {
      if (error) {
        commit('SET_PAYMENTS_REPORTS', [])
      }
    })
}
const getPaymentsReportsPDF = ({ commit }, payload) => {
  return PaymentRepository.getPaymentsReportsPDF(payload).then(data => {
    return data
  })
}

export default {
  getBookingsHistory,
  getPayments,
  createManualPayment,
  getPaymentsReports,
  getPaymentsReportsPDF
}
