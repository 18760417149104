import { UserRepository } from '@/_api/user.repository'

const register = ({ commit }, payload) => {
  return UserRepository.register(payload)
    .then(({ data }) => {
      if (data.role !== 'client') {
        commit('SET_ROLE', data.role)

        localStorage.setItem(
          'access_token',
          JSON.stringify({ access_token: data.access_token, token_type: data.token_type })
        )
      }

      return data.role
    })
    .catch(errors => {
      return Promise.reject(errors.data)
    })
}
const socialAuth = ({ commit }, payload) => {
  return UserRepository.socialAuth(payload).then(({ data }) => {
    localStorage.setItem(
      'access_token',
      JSON.stringify({ access_token: data.access_token, token_type: data.token_type })
    )

    return data
  })
}

const contact = ({ commit }, payload) => {
  return UserRepository.contact(payload).then(({ data }) => {
    return data
  })
}
const demoRequest = ({ commit }, payload) => {
  return UserRepository.demoRequest(payload).then(({ data }) => {
    return data
  })
}

// eslint-disale-line no-unused-vars
const login = ({ commit }, payload) => {
  return UserRepository.login(payload).then(({ data }) => {
    if ((data.role !== 'client' && data.role !== 'admin') || (data.role === 'admin' && payload.salonId)) {
      commit('SET_ROLE', data.role)

      localStorage.setItem(
        'access_token',
        JSON.stringify({ access_token: data.access_token, token_type: data.token_type })
      )
    }

    return data.role
  })
}

const logout = ({ commit, state }) => {
  if (state.isAuthorized) {
    return UserRepository.logout().then(() => {
      commit('LOGOUT')
    })
  }
}

const setAuthStatus = ({ commit }, authData) => {
  commit('SET_AUTH_STATUS', authData)
}

const resetPasswordRequest = ({ commit }, payload) => {
  return UserRepository.resetPasswordRequest(payload).then(() => {
    commit('SET_RESET_EMAIL', payload)
  })
}

const setNewPassword = ({ commit }, payload) => {
  return UserRepository.setNewPassword(payload).then(({ data }) => {
    if (data.role === 'owner') {
      localStorage.setItem(
        'access_token',
        JSON.stringify({ access_token: data.access_token, token_type: data.token_type })
      )
    } else {
      throw { statusCode: 401, success: false, data: { message: 'Please visit owner page.' } }
    }
  })
}

const getAccountInfo = ({ commit }) => {
  return UserRepository.getAccountInfo().then(({ data }) => {
    commit('SET_USER_ACCOUNT_INFO', data)
  })
}
const searchUsersByEmails = ({ commit }, payload) => {
  return UserRepository.searchUsersByEmails(payload).then(({ data }) => {
    commit('SET_SEARCH_USERS_BY_EMAIL', data)
  })
}
const emailVerify = ({ commit }, payload) => {
  return UserRepository.emailVerify(payload).then(data => {
    if (data && data.code === 200) {
      if (data.data.role === 'owner') {
        localStorage.setItem(
          'access_token',
          JSON.stringify({ access_token: data.data.access_token, token_type: data.data.token_type })
        )
      } else {
        throw { statusCode: 401, success: false, data: { message: 'Please visit owner page.' } }
      }
      commit('SET_ROLE', data.data.role)
    }
    return data
  })
}
const emailResend = ({ commit }, payload) => {
  return UserRepository.resendEmail(payload)
}

export default {
  socialAuth,
  getAccountInfo,
  setAuthStatus,
  register,
  setNewPassword,
  resetPasswordRequest,
  login,
  logout,
  contact,
  demoRequest,
  emailVerify,
  emailResend,
  searchUsersByEmails
}
