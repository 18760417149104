import axios from 'axios'
import config from 'config'
import router from '@/_router'
import disabledSpennerUrlList from '@/_constants/withoutLoaderUrls'

import { eventHub } from '@/_utils/eventhub'

const http = axios.create({
  baseURL: `${config.apiUrl}/api`,
  timeout: 25000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

const errorHandler = error => {
  eventHub.$emit('request-error')

  if (error.response.data.code === 404) router.push('/not-found')
  if (error.response.data.code === 403) router.push('/403')

  return Promise.reject({ ...error.response.data })
}

const requestHandler = request => {
  let url = request.url
  url = url.substring(0, url.indexOf('?'))

  if (!url) {
    url = request.url
  }

  if (!disabledSpennerUrlList.includes(url) && request.method !== 'delete') {
    eventHub.$emit('before-request')
  }

  const token = localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token')) : null
  if (token) request.headers.Authorization = `Bearer ${token.access_token}`

  return request
}

const successHandler = response => {
  let url = response.config.url
  url = url.substring(0, url.indexOf('?'))

  if (!url) {
    url = response.config.url
  }

  if (!disabledSpennerUrlList.includes(url) && response.method !== 'delete') {
    setTimeout(() => {
      eventHub.$emit('after-response')
    }, 600)
  }

  return response
}

http.interceptors.request.use(request => requestHandler(request))

http.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

export default http
