import { CategoriesRepository } from '@/_api/categories.repository'

const create = ({ commit }, payload) => {
  return CategoriesRepository.create(payload).then(({ data }) => {
    return data
  })
}

const update = ({ commit }, payload) => {
  return CategoriesRepository.update(payload).then(({ data }) => {
    return data
  })
}

const deleteCategory = ({ commit }, payload) => {
  return CategoriesRepository.delete(payload).then(({ data }) => {
    commit('DELETE_CATEGORY', payload)

    return data
  })
}

const getOne = ({ commit }, payload) => {
  return CategoriesRepository.getOne(payload).then(({ data }) => {
    return data
  })
}

const getAll = ({ commit }, payload) => {
  return CategoriesRepository.getAll(payload).then(response => {
    const data = response.data.data

    commit('SET_CATEGORIES', data)

    return data
  })
}

const getAllForSelect = ({ commit }, payload) => {
  return CategoriesRepository.getAllForSelect(payload).then(response => {
    const data = response.data.data

    commit('SET_CATEGORIES_FOR_SELECT', data)

    return data
  })
}

export default {
  create,
  update,
  deleteCategory,
  getAllForSelect,
  getOne,
  getAll
}
