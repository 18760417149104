<template>
  <main>
    <NavbarPublic :has-border="false"></NavbarPublic>
    <firstSection :class="'mt-8'"></firstSection>
    <secondSection></secondSection>
    <thirdSection></thirdSection>
    <fourthSection></fourthSection>
    <fifthSection></fifthSection>
  </main>
</template>
<script>
import NavbarPublic from '@/components/NavbarPublic'
import firstSection from '@/components/mainPageSections/firstSection'
import secondSection from '@/components/mainPageSections/secondSection'
import thirdSection from '@/components/mainPageSections/thirdSection'
import fourthSection from '@/components/mainPageSections/fourthSection'
import fifthSection from '@/components/mainPageSections/fifthSection'

export default {
  name: 'Home',
  metaInfo: {
    meta: [
      {
        description:
          'Die Coiffeur Software von Quiff revolutioniert den Markt. Online Reservierung und mehr! Einfach in der Anwendung für Salonbesitzer und Ihre Kunden! '
      }
    ]
  },
  components: {
    NavbarPublic,
    firstSection,
    secondSection,
    thirdSection,
    fourthSection,
    fifthSection
  },
  data() {
    return {
      zdassetsScript: ''
    }
  }
}
</script>
<style scoped></style>
