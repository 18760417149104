const getDefaultState = () => {
  return {
    products: {}
  }
}

const SET_PRODUCTS = (state, payload) => {
  state.products = payload

  if (!Array.isArray(payload.data)) {
    state.products.data = Object.values(payload.data)
  }
}

const DELETE_PRODUCTS = (state, payload) => {
  let indexDeleted = state.products.data.findIndex(product => product.id === payload)
  state.products.data.splice(indexDeleted, 1)

  if (state.products.total !== 0) {
    state.products.total = state.products.total - 1
  }
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_PRODUCTS,
  DELETE_PRODUCTS,
  RESET_STATE,
  getDefaultState
}
