import Repository from './repository'

const createUrl = '/category-create'
const updateUrl = '/category-update'
const deleteUrl = '/category-delete'
const getOneUrl = '/category-details'
const getAllUrl = '/categories-index'
const getAllForSelectUrl = '/categories-select'

export const CategoriesRepository = {
  create(payload) {
    return Repository.post(createUrl, payload).then(({ data }) => data)
  },
  update(payload) {
    return Repository.post(updateUrl, payload).then(({ data }) => data)
  },
  delete(payload) {
    return Repository.delete(`${deleteUrl}/${payload}`).then(({ data }) => data)
  },
  getOne(payload) {
    return Repository.get(getOneUrl, { params: payload }).then(data => data.data)
  },
  getAll(payload) {
    return Repository.get(getAllUrl, { params: payload })
  },
  getAllForSelect(payload) {
    return Repository.get(getAllForSelectUrl, { params: payload })
  }
}
