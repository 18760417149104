<template>
  <b-navbar
    v-if="getAuthInfo.isAuthorized"
    toggleable="xl"
    type="light"
    variant="light"
    fixed="top"
    class="tablet-navbar navbar bg-white py-0 px-md-1-5 pl-1-5 pr-0-2 border-bottom justify-content-center"
  >
    <div class="nav-tabs container-fluid px-0 py-0-5 py-xl-0 mx-0 border-0">
      <b-link
        v-if="showCaledarBtn"
        :to="calendarUrl"
        class="btn btn-checkbox px-1-5 d-inline-block d-xl-none"
        exact-active-class="active"
        :active="isActiveCalendarButton"
      >
        {{ $t('common.navbar.calendar-btn') }}
      </b-link>

      <LanguageDropdown :class="'ml-auto mr-1-5 d-inline-block d-xl-none'"></LanguageDropdown>

      <b-navbar-toggle target="nav-collapse" class="border-0 py-0-5"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="align-items-center justify-conter-center w-100 pb-3 pb-xl-0">
          <li class="nav-item ml-0 mr-auto d-none d-xl-inline-block">
            <b-link
              v-if="showCaledarBtn"
              :to="calendarUrl"
              class="btn btn-checkbox px-1-5"
              exact-active-class="active"
              :active="isActiveCalendarButton"
            >
              {{ $t('common.navbar.calendar-btn') }}
            </b-link>
          </li>

          <li>
            <ul class="navbar-nav pb-1-5 pb-xl-0">
              <b-nav-item
                v-for="(item, key) in menuListByRole"
                :key="`menu-item-${key}`"
                :to="item.to"
                class="mx-md-1 text-center"
                exact-active-class="active"
                link-classes="nav-link"
                :active="activeMenuItem(item.to)"
              >
                {{ $t(item.i18Key) }}
              </b-nav-item>
            </ul>
          </li>

          <li class="nav-item mx-auto pl-lg-1 ml-xl-auto mr-xl-0">
            <b-link :to="logOutItem.to" class="btn btn-checkbox px-1-5 py-0-5 text-sm">
              {{ $t(logOutItem.i18Key) }}
              <font-awesome-icon :icon="['fal', 'sign-out-alt']" class="ml-0-5"></font-awesome-icon>
            </b-link>
          </li>
          <li class="nav-item pl-1 mr-0 d-none d-xl-inline-block">
            <LanguageDropdown></LanguageDropdown>
          </li>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import LanguageDropdown from '@/components/LanguageDropdown.vue'
import { mapGetters } from 'vuex'
import moment from 'moment/src/moment'

export default {
  name: 'Navbar',
  components: {
    LanguageDropdown
  },
  data() {
    return {
      menuNavItems: [
        {
          access: ['admin', 'owner', 'salon_manager'],
          i18Key: 'common.navbar.salon-link',
          to: '/salon'
        },
        {
          access: ['admin', 'owner', 'salon_manager'],
          i18Key: 'common.navbar.products-link',
          to: '/products'
        },
        {
          access: ['admin', 'owner'],
          i18Key: 'common.navbar.payment-link',
          to: '/payments'
        },
        {
          access: ['admin', 'owner', 'salon_manager'],
          i18Key: 'common.navbar.revenue-reports-link',
          to: '/revenue-reports'
        },
        {
          access: ['admin', 'owner', 'salon_manager'],
          i18Key: 'common.navbar.order-link',
          to: '/salon/bookings-history'
        },
        {
          access: ['admin', 'owner', 'salon_manager'],
          i18Key: 'common.navbar.clients-link',
          to: '/salon/clients'
        },
        {
          access: ['admin', 'owner', 'salon_manager'],
          i18Key: 'common.navbar.booking-mask-link',
          to: '/booking-mask'
        }
      ],
      logOutItem: {
        i18Key: 'common.navbar.logout-link',
        to: '/sign-in'
      }
    }
  },
  computed: {
    ...mapGetters({
      userRole: 'user/getRole',
      getAuthInfo: 'user/getAuthInfo',
      generalInfo: 'salons/getGeneral'
    }),
    menuListByRole() {
      return this.menuNavItems.filter(item => item.access.includes(this.userRole))
    },
    showCaledarBtn() {
      return ['admin', 'owner', 'salon_manager', 'staff'].includes(this.userRole)
    },
    moderationCompleted() {
      return this.generalInfo.status === 'Active'
    },
    isActiveCalendarButton() {
      const calendarRouteNames = ['calendar', 'calendar-staff', 'calendar-salon']

      return calendarRouteNames.includes(this.$route.name)
    },
    calendarUrl() {
      let now = moment().format('YYYY-MM-DD')
      const type = 'day'

      return `/calendar/${type}/${now}`
    }
  },
  methods: {
    activeMenuItem(path) {
      return this.$route.path === path
    }
  }
}
</script>

<style lang="scss" scoped>
.header-profile a {
  font-size: 0.875rem;
}
</style>
