import { ParamsRepository } from '@/_api/params.repository'

const getParamsValues = ({ commit }) => {
  return ParamsRepository.get().then(({ data }) => {
    const payload = {
      genders: data[0],
      age: data[1],
      length: data[2]
    }

    commit('SET_PARAMETERS_VALUES', payload)

    return payload
  })
}

export default {
  getParamsValues
}
