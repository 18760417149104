<template>
  <section class="">
    <div class="container pt-lg-14 pt-7">
      <div class="text-center mb-lg-8-5 mb-4">
        <h2 class="mb-2-5 mob-line-height">
          {{ $t('main.sections.section_2.title') }}
        </h2>
        <p>
          {{ $t('main.sections.section_2.description') }}
        </p>
      </div>
      <div class="row card-list justify-content-center">
        <div class="col-lg-4">
          <div class="card">
            <div class="text-lg-center text-left px-lg-2-5 px-md-4 px-2-5 pt-lg-6 pt-md-5 pt-2 pb-lg-5">
              <h3 class="mb-2-5">
                {{ $t('main.sections.section_2.card_1.title') }}
              </h3>
              <p class="mb-3 mx-lg-1">
                {{ $t('main.sections.section_2.card_1.description') }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3 mt-lg-0">
          <div class="card">
            <div class="text-lg-center text-left px-lg-2-5 px-md-4 px-2-5 pt-lg-6 pt-md-5 pt-2 pb-lg-5">
              <h3 class="mb-2-5">
                {{ $t('main.sections.section_2.card_2.title') }}
              </h3>
              <p class="mb-3 mx-lg-1">
                {{ $t('main.sections.section_2.card_2.description') }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3 mt-lg-0">
          <div class="card">
            <div class="text-lg-center text-left px-lg-2-5 px-md-4 px-2-5 pt-lg-6 pt-md-5 pt-2 pb-lg-5">
              <h3 class="mb-2-5">
                {{ $t('main.sections.section_2.card_3.title') }}
              </h3>
              <p class="mb-3 mx-lg-1">
                {{ $t('main.sections.section_2.card_3.description') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'SecondSection'
}
</script>
