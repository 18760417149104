import Repository from './repository'

const createUrl = 'salon-create'
const updateUrl = 'salon-update'
const infoUrl = 'salon-page'
const generalUrl = 'salon-general'
const salonCombinationUrl = '/get-salon-combination'
const sendModerateUrl = '/send-moderate'
const checkModerateUrl = '/check-moderate'
const changePaybleUrl = '/change-payable'

export const SalonsRepository = {
  create(payload) {
    return Repository.post(createUrl, payload).then(data => data.data)
  },
  update(payload) {
    return Repository.post(updateUrl, payload).then(data => data.data)
  },
  getInfo(payload) {
    return Repository.get(`${generalUrl}/?salon_id=${payload}`)
  },
  getGeneral(payload) {
    return Repository.get(infoUrl, { params: { salon_id: payload } })
  },
  getSalonCombination(payload) {
    return Repository.get(salonCombinationUrl, { params: payload })
  },
  sendToModerate(payload) {
    return Repository.post(sendModerateUrl, payload).then(data => data.data)
  },
  checkModerate(payload) {
    return Repository.post(checkModerateUrl, payload).then(data => data.data)
  },
  changePayble(payload) {
    return Repository.post(changePaybleUrl, payload).then(data => data.data)
  }
}
