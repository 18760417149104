import Vue from 'vue'
import VueAnalitics from 'vue-analytics'
import router from '@/_router'
import config from 'config'

if (config.useAnalytics !== undefined && config.useAnalytics !== 'false') {
  Vue.use(VueAnalitics, {
    id: 'UA-171156364-1',
    router
  })
}
