import Repository from './repository'

const listUrl = '/salon-gallery'
const updateUrl = '/salon-gallery-update'
const deleteUrl = '/salon-gallery-delete'

export const GalleryRepository = {
  getAllBySalonId(payload) {
    return Repository.get(`${listUrl}?salon_id=${payload}`)
  },
  update(payload) {
    return Repository.post(updateUrl, payload).then(data => data.data)
  },
  delete(payload) {
    return Repository.post(deleteUrl, payload).then(data => data.data)
  }
}
