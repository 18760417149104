<template>
  <section class="position-relative gradient-bg">
    <div class="container pt-md-2 pt-lg-4 pt-xl-12 pt-0 pb-lg-6 pb-lg-14 pb-0">
      <div class="row align-items-center">
        <div class="col-lg-5 pt-1-5 pb-3">
          <h1
            class="mb-md-2-5 mb-1 landing-page-mob-text"
            style="line-height:1.43"
            v-html="$t('main.sections.section_1.title')"
          ></h1>
          <p class="h6 mb-md-6 mb-0 roboto-font font-weight-medium landing-page-mob-text mob-line-height text-black">
            {{ $t('main.sections.section_1.description') }}
          </p>
          <div class="d-none d-md-block  col-xxl-8 col-12 mb-md-6 mb-2 px-0 px-md-10 px-lg-0">
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <p class="pr-0-5 mb-0">{{ $t('main.sections.section_1.monthly-fee') }}</p>
                <span class=""> 0 CHF</span>
              </div>
              <div class="border-left pb-1"></div>
              <div class="d-flex">
                <p class="pr-0-5 mb-0">{{ $t('main.sections.section_1.comission-fee') }}</p>
                <span class=""> 0.0 %</span>
              </div>
            </div>
          </div>
          <router-link
            to="/sign-up"
            class="btn btn-primary btn-primary-bs d-none d-md-block col-xxl-8 col-12 btn-lg text-nowrap px-1 py-1"
          >
            {{ $t('main.sections.section_1.button_link') }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-lg-6 offset-lg-6 px-0 text-right bg-salon-lp">
      <picture v-if="isDeutchLang">
        <source
          srcset="
            @/assets/images/salon-lp/main-salon-lp-mob-de.png,
            @/assets/images/salon-lp/main-salon-lp-mob-de@2x.png 2x,
            @/assets/images/salon-lp/main-salon-lp-mob-de@3x.png 3x
          "
          media="(max-width:767px)"
        />
        <source
          srcset="
            @/assets/images/salon-lp/main-salon-lp-de.png,
            @/assets/images/salon-lp/main-salon-lp-de@2x.png 2x,
            @/assets/images/salon-lp/main-salon-lp-de@3x.png 3x
          "
          media="(min-width:767px)"
        />
        <img src="@/assets/images/salon-lp/main-salon-lp-de.png" class="mw-100" alt="" />
      </picture>
      <picture v-else>
        <source
          srcset="
            @/assets/images/salon-lp/main-salon-lp-mob-en.png,
            @/assets/images/salon-lp/main-salon-lp-mob-en@2x.png 2x,
            @/assets/images/salon-lp/main-salon-lp-mob-en@3x.png 3x
          "
          media="(max-width:767px)"
        />
        <source
          srcset="
            @/assets/images/salon-lp/main-salon-lp-en.png,
            @/assets/images/salon-lp/main-salon-lp-en@2x.png 2x,
            @/assets/images/salon-lp/main-salon-lp-en@3x.png 3x
          "
          media="(min-width:767px)"
        />
        <img src="@/assets/images/salon-lp/main-salon-lp-en.png" class="mw-100" alt="" />
      </picture>
    </div>
    <div class="container mt-2">
      <div class=" d-block d-md-none  col-xxl-8 col-xl-10 col-12 mb-md-6 mb-2 px-0">
        <div class="d-flex flex-wrap">
          <div class="d-flex w-100 mb-0-5 justify-content-center">
            <p class="pr-0-5 mb-0">{{ $t('main.sections.section_1.monthly-fee') }}</p>
            <span class=""> 0 CHF</span>
          </div>
          <div class="d-flex w-100 justify-content-center">
            <p class="pr-0-5 mb-0">{{ $t('main.sections.section_1.comission-fee') }}</p>
            <span class=""> 0.0 %</span>
          </div>
        </div>
      </div>
      <router-link
        to="/sign-up"
        class="btn py-1 btn-primary btn-primary-bs d-block d-md-none col text-nowrap px-1 py-1"
      >
        {{ $t('main.sections.section_1.button_link') }}
      </router-link>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FirstSection',
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getSiteLang: 'user/getSiteLang'
    }),
    isDeutchLang() {
      return this.getSiteLang === 'de'
    }
  }
}
</script>
