const getAccountInfo = state => state.accountInfo

const getAuthInfo = state => ({
  isAuthorized: state.isAuthorized
})
const getResetEmail = state => state.resetEmail
const getUserPosition = state => state.userPosition
const getUserAvatar = state => {
  return require('@/assets/images/default/default_avatar.png')
}

const getSiteLang = state => state.siteLang
const getRole = state => state.role
const getAcceptCookiesAlert = state => state.hideAcceptCookiesAlert
const getUsersByMail = state => state.usersByMail
const hideDeviceInfoMessage = state => state.hideDeviceInfoMessage

export default {
  getAccountInfo,
  getAuthInfo,
  getUserPosition,
  getResetEmail,
  getUserAvatar,
  getSiteLang,
  getRole,
  getAcceptCookiesAlert,
  getUsersByMail,
  hideDeviceInfoMessage
}
