import Repository from './repository'

const clientsUrl = '/client-base'
const clientProfileInfoUrl = '/client-base-by-id'

export const ClientsRepository = {
  getClients(params) {
    return Repository.get(clientsUrl, { params }).then(({ data }) => data)
  },
  getClientProfileInfo(params) {
    return Repository.get(clientProfileInfoUrl, { params }).then(({ data }) => data)
  }
}
