import Repository from './repository'

const listUrl = '/salon-daysoff'
const createUrl = '/salon-daysoff-create'
const updateUrl = '/salon-daysoff-update'
const deleteUrl = '/salon-daysoff-delete'
const detailsUrl = '/dayoff-details'

export const DayOffRepository = {
  getAllBySalonId(payload) {
    return Repository.get(listUrl, { params: payload })
  },
  getDetailsById(payload) {
    return Repository.get(`${detailsUrl}?dayoff_id=${payload}`)
  },
  create(payload) {
    return Repository.post(createUrl, payload)
  },
  update(payload) {
    return Repository.post(updateUrl, payload)
  },
  delete(payload) {
    return Repository.delete(`${deleteUrl}/${payload}`)
  }
}
