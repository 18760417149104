import { GalleryRepository } from '@/_api/gallery.repository'

const getAllBySalonId = ({ commit }, payload) => {
  return GalleryRepository.getAllBySalonId(payload).then(response => {
    let result = response.data.data.images

    commit('SET_IMAGES', result)

    return result
  })
}

const update = ({ commit }, payload) => {
  return GalleryRepository.update(payload).then(response => {
    let result = response.data.images
    commit('SET_IMAGES', result)

    return result
  })
}

const deleteSeleted = ({ commit }, payload) => {
  return GalleryRepository.delete(payload).then(response => {
    let result = response.data.images

    commit('SET_IMAGES', result)

    return result
  })
}

export default {
  getAllBySalonId,
  update,
  deleteSeleted
}
