import { DayOffRepository } from '@/_api/dayoff.repository'

const getDayOffBySalonId = ({ commit }, payload) => {
  return DayOffRepository.getAllBySalonId(payload).then(response => {
    const data = response.data.data

    commit('SET_DAY_OFF', data)

    return data
  })
}

const createDayOff = ({ commit }, payload) => {
  return DayOffRepository.create(payload).then(response => {
    const data = response.data.data

    return data
  })
}

const updateDayOff = ({ commit }, payload) => {
  return DayOffRepository.update(payload).then(response => {
    const data = response.data.data

    return data
  })
}

const deleteDayOffById = ({ commit }, payload) => {
  return DayOffRepository.delete(payload).then(response => {
    const data = response.data

    commit('DELETE_DAY_OFF', payload)
    return data
  })
}

const deleteSingleDayOffById = ({ commit }, payload) => {
  return DayOffRepository.delete(payload).then(response => {
    const data = response.data

    return data
  })
}

const getDayOffDetailsById = ({ commit }, payload) => {
  return DayOffRepository.getDetailsById(payload).then(response => {
    const data = response.data.data

    return data
  })
}

export default {
  getDayOffBySalonId,
  createDayOff,
  updateDayOff,
  deleteDayOffById,
  deleteSingleDayOffById,
  getDayOffDetailsById
}
