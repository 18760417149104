<template>
  <div class="w-100 mvh-100 d-flex align-items-center justify-content-center py-3">
    <div class="text-center error-block">
      <picture>
        <source
          srcset="
            @/assets/images/bg/page_error.png,
            @/assets/images/bg/page_error@2x.png 2x,
            @/assets/images/bg/page_error@3x.png 3x
          "
        />
        <img src="@/assets/images/bg/page_error.png" class="page-error-img mb-4" alt="" />
      </picture>

      <h2 class="mb-3-5">{{ $t('common.error.403_title') }}</h2>

      <router-link class="btn btn-primary btn-lg" to="/">{{ $t('common.error.link_to_home') }}</router-link>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style scoped></style>
