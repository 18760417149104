<template>
  <main>
    <section class="demo-bg">
      <NavbarPublic v-if="!getAuthInfo.isAuthorized" :has-border="false"></NavbarPublic>

      <div class="container pt-4 pb-md-4 pb-lg-14">
        <div class="row">
          <div class="col col-lg-10 col-xl-12 mt-5">
            <div class="row justify-content-center text-center">
              <div class="col-xl-7">
                <h1 class="font-weight-semibold">{{ $t('request-demo.title') }}</h1>

                <p class="mb-2 mb-md-0">
                  {{ $t('request-demo.title-text') }}
                </p>
              </div>
            </div>

            <div>
              <div
                v-show="deSiteLang"
                id="calendlyWidgetLangEn"
                class="calendly-inline-widget"
                :data-url="`https://calendly.com/quiff_ch/quiff-demo?primary_color=fe6a0a`"
                style="min-width:320px;height:750px;"
              ></div>
              <div
                v-show="enSiteLang"
                id="calendlyWidgetLangDe"
                class="calendly-inline-widget"
                :data-url="`https://calendly.com/quiff_ch/quiff-demo-en?primary_color=fe6a0a`"
                style="min-width:320px;height:750px;"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { isEmpty } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import NavbarPublic from '@/components/NavbarPublic'

export default {
  components: {
    NavbarPublic
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getAuthInfo: 'user/getAuthInfo',
      siteLang: 'user/getSiteLang'
    }),
    enSiteLang() {
      return this.siteLang === 'en'
    },
    deSiteLang() {
      return this.siteLang === 'de'
    }
  },
  mounted: function() {
    window.scrollTo(0, -200)

    let calendlyScript = document.createElement('script')

    calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    document.head.appendChild(calendlyScript)

    if (!this.getAuthInfo.isAuthorized) {
      let navbar = document.getElementById('top-navbar')

      if (navbar) {
        navbar.classList.remove('bg-white')
        navbar.classList.add('bg-inherit')
      }
    }
  }
}
</script>
<style scoped>
.message-input {
  height: 230px;
}
</style>
