<template>
  <main>
    <NavbarPublic :has-border="true"></NavbarPublic>
    <div class="navbar-offset mt-7 mt-md-10 marketplace-main-info">
      <div class="container w-100">
        <h1 class="mb-2 mt-5">
          Coiffeur ohne Voranmeldung – mit Quiff kein Problem
        </h1>
        <p class="mb-2">
          Du stehst auf Spontanität? Du gehörst eher zu den Kurzentschlossenen, auch wenn es darum geht, einen Termin
          beim <router-link to="/" title="Damen Coiffeur"> Damen Coiffeur</router-link> oder
          <router-link to="/" title="Herren Coiffeur"> Herren Coiffeur</router-link> wahrzunehmen? Kein Problem. Bei
          Quiff findest Du auch (D)einen Coiffeur ohne Voranmeldung. Denn unsere Liste enthält auch eine Vielzahl an
          Friseuren, zu deren Salons eigentlich keine Voranmeldung nötig ist.
        </p>
        <p>
          Dass Du dennoch über Quiff buchst, bringt viele Vorteile mit sich – und Du kannst auch weiterhin Deine
          Spontaneität ausleben. So kannst Du Dir beispielsweise in Ruhe aussuchen, welcher Coiffeur ohne Voranmeldung
          der Richtige für Dich ist. Hier kannst Du Dich auch von anderen inspirieren lassen und Dir Bewertungen
          durchlassen. Vielleicht ist es Dir aber auch wichtiger, einen
          <router-link to="/Coiffeur-in-der-Nähe" title="Coiffeur in der Nähe"> Coiffeur in der Nähe</router-link> zu
          finden. Oder Du suchst Dir einen Friseur ohne Termin respektive Voranmeldung nach den angebotenen
          Dienstleistungen aus. Den Wahlmöglichkeiten sind keine Grenzen gesetzt. Und wenn Du fündig geworden bist oder
          eine Vorauswahl getroffen hast, kannst Du die nächsten Schritte in Angriff nehmen: Du kannst Dir schon einmal
          ein Profil für Dich – und bei Bedarf auch für Familienmitglieder – anlegen. Und wenn Du dann Lust auf eine
          optische Veränderung hast, kannst Du Deinen Termin online buchen.
        </p>
        <p>
          Ausserdem schliesst Du durch die Online Reservierung das Risiko aus, evtl. doch eine Warteschlagen bei Deinem
          gewählten Coiffeur vorzufinden.
        </p>
        <h2 class="mb-2">
          Coiffeur online buchen, Treuepunkte sammeln
        </h2>
        <p class="mb-2">
          Ein weiterer grosser Vorteil, mit Quiff einen Termin beim
          <a :href="coifeurOnlineBunchen" target="_blank" title="Coiffeur online zu buchen"
            >Coiffeur online zu buchen</a
          >
          Du sammelst wertvolle Treuepunkte. Und diese kannst Du anschliessend auch wieder beim Coiffeur Deines
          Vertrauens einlösen. Hierzu hält beispielsweise jeder der bei uns gelisteten Salons einen Online-Shop bereit,
          in dem Du in Ruhe stöbern und Deine Wunschartikel anschliessend reservieren kannst. Die Treuepunkte werden Dir
          auf Deinen Kauf angerechnet – und die ausgewählten Produkte kannst Du bei Deinem nächsten Coiffeurbesuch
          mitnehmen.
        </p>
        <p class="mb-2">
          Du siehst: Es lohnt sich, auch einen Termin beim Coiffeur ohne Voranmeldung online zu buchen. Schau Dich um,
          lass Dich inspirieren – und triff die Wahl für den richtigen Salon mit Bedacht. Spontan kannst Du
          anschliessend immer noch sein: bei Deiner Buchung, bei Deiner neuen Frisur, in vielen Lebenslagen.
        </p>
        <div class="my-10 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.register')">
                {{ $t('footer-links.register') }}
              </router-link>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <a class="text-white" :href="`${clientAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.book') }}
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import NavbarPublic from '@/components/NavbarPublic'
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Coiffeur ohne Voranmeldung, Coiffeur mit Anmeldung, Coiffeur in der Nähe – wir haben für jeden Wunsch das richtige Angebot.'
      }
    ]
  },
  components: {
    NavbarPublic
  },
  computed: {
    clientAppUrl() {
      return config.clientAppUrl
    },
    coifeurOnlineBunchen() {
      return `${this.clientAppUrl}/coiffeur-online-buchen`
    }
  },
  beforeCreate: function() {
    window.scrollTo(0, -200)
  }
}
</script>
