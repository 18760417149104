import Repository from './repository'

const listUrl = '/salon-staffs'
const listSelectUrlForCalendar = '/salon-staffs-select-calendar'
const listSelectUrl = '/salon-staffs-select'
const createUrl = '/salon-staff-create'
const updateUrl = '/salon-staff-update'
const deleteUrl = '/salon-staff-delete'
const getProfileUrl = '/staff-details'
const createManagertUrl = '/manager-create'
const createTopManagerUrl = '/salon-manager-create'
const updateManagerUrl = '/manager-update'
const deleteManagerUrl = '/manager-delete'
const getManagerProfileUrl = '/manager-details'
const getSalonsStaffFilterUrl = '/salons-staff-filter'

export const StaffsRepository = {
  getAllBySalonId(payload) {
    return Repository.get(listUrl, { params: payload })
  },

  getListAllBySalonIdCalendar(salonId) {
    return Repository.get(`${listSelectUrlForCalendar}?salon_id=${salonId}`)
  },
  getListAllBySalonId(salonId) {
    return Repository.get(`${listSelectUrl}/?salon_id=${salonId}`)
  },
  getListAllByParams(payload) {
    return Repository.post(getSalonsStaffFilterUrl, payload)
  },
  createManager(payload) {
    return Repository.post(createManagertUrl, payload).then(data => data.data)
  },
  createTopManager(payload) {
    return Repository.post(createTopManagerUrl, payload).then(data => data.data)
  },
  updateManager(payload) {
    return Repository.post(updateManagerUrl, payload).then(data => data.data)
  },
  create(payload) {
    return Repository.post(createUrl, payload).then(data => data.data)
  },
  update(payload) {
    return Repository.post(updateUrl, payload).then(data => data.data)
  },
  getProfileInfo(id) {
    return Repository.get(`${getProfileUrl}?staff_id=${id}`).then(data => data.data)
  },
  getManagerProfileInfo(id) {
    return Repository.get(`${getManagerProfileUrl}?manager_id=${id}`).then(data => data.data)
  },
  delete(id) {
    return Repository.delete(`${deleteUrl}/${id}`)
  },
  deleteManager(id) {
    return Repository.delete(`${deleteManagerUrl}/${id}`)
  }
}
