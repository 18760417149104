import Repository from './repository'

const loginUrl = '/login'
const meUrl = '/me'
const logoutUrl = '/logout'
const registerManagerUrl = '/owner/register'
const changePasswordUrl = '/change-password'
const resetPasswordRequestUrl = '/reset-password-request'
const setNewPasswordUrl = '/reset-password-set'
const contactFormSalon = '/contact-from-salon'
const requestDemo = '/demo-request'
const searchUsers = 'users-search?email'
const emailVerification = 'email/verify/'
const emailResend = 'email/resend/'

export const UserRepository = {
  register(registerPayload) {
    return Repository.post(registerManagerUrl, registerPayload).then(data => data.data)
  },
  login(loginPayload) {
    return Repository.post(loginUrl, loginPayload).then(({ data }) => data)
  },
  getAccountInfo() {
    return Repository.get(meUrl).then(({ data }) => data)
  },
  resetPasswordRequest(email) {
    return Repository.post(resetPasswordRequestUrl, { email })
  },
  setNewPassword(payload) {
    return Repository.post(setNewPasswordUrl, payload).then(({ data }) => data)
  },
  updatePassword(passwordPayload) {
    return Repository.post(changePasswordUrl, passwordPayload).then(({ data }) => data)
  },
  logout() {
    return Repository.post(logoutUrl)
  },
  contact(payload) {
    return Repository.post(contactFormSalon, payload).then(({ data }) => data)
  },
  demoRequest(payload) {
    return Repository.post(requestDemo, payload).then(({ data }) => data)
  },
  searchUsersByEmails(payload) {
    return Repository.post(`${searchUsers}=${payload}`).then(({ data }) => data)
  },
  emailVerify(payload) {
    return Repository.get(
      `${emailVerification}${payload.id}?expires=${payload.expires}&signature=${payload.signature}`
    ).then(({ data }) => data)
  },
  resendEmail() {
    return Repository.get(emailResend).then(({ data }) => data)
  }
}
