const getDefaultState = () => {
  return {
    services: {},
    serviceCategories: [],
    serviceList: [],
    salonServicesSelect: null
  }
}

const SET_SERVICES = (state, payload) => {
  state.services = payload

  if (!Array.isArray(payload.data)) {
    state.services.data = Object.values(payload.data)
  }
}

const SET_SERVICES_LIST = (state, payload) => {
  state.serviceList = payload
}

const SET_SALON_SERVICES_SELECT = (state, payload) => {
  state.salonServicesSelect = payload
}

const SET_SERVICES_CATEGORIES = (state, payload) => {
  state.services = payload
}

const DELETE_SERVICE = (state, payload) => {
  let indexDeleted = state.services.data.findIndex(service => service.id === payload.id)
  state.services.data.splice(indexDeleted, 1)

  if (state.services.total !== 0) {
    state.services.total = state.services.total - 1
  }
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_SERVICES,
  SET_SERVICES_LIST,
  SET_SALON_SERVICES_SELECT,
  SET_SERVICES_CATEGORIES,
  DELETE_SERVICE,
  RESET_STATE,
  getDefaultState
}
