import Repository from './repository'

const getTotalUrl = '/revenue-totals'
const getByServiceUrl = '/revenue-by-service'
const getClientUrl = '/revenue-clients'
const getBookingsUrl = '/revenue-bookings'
const getStaffsUrl = '/revenue-staffs'
const getSalonRatingUrl = '/revenue-salon-rating?'
const getOverviewUrl = '/revenue-overview'

export const ReportsRepository = {
  getTotal(payload) {
    return Repository.get(getTotalUrl, { params: payload })
  },
  getByService(payload) {
    return Repository.get(getByServiceUrl, { params: payload })
  },
  getClient(payload) {
    return Repository.get(getClientUrl, { params: payload })
  },
  getBookings(payload) {
    return Repository.get(getBookingsUrl, { params: payload })
  },
  getStaffs(payload) {
    return Repository.get(getStaffsUrl, { params: payload })
  },
  getSalonRating(payload) {
    return Repository.get(getSalonRatingUrl, { params: payload })
  },
  getOverview(payload) {
    return Repository.get(getOverviewUrl, { params: payload })
  }
}
