import { CalendarRepository } from '@/_api/calendar.repository'

const getCalendarBookingsByStaffAndPeriod = ({ commit }, payload) => {
  return CalendarRepository.calendarBookingsListByStaffAndPeriod(payload).then(response => {
    let result = response.data.data

    commit('SET_CALENDAR_BOOKING', result)

    return result
  })
}
const getCalendarDayOffsByStaffAndPeriod = ({ commit }, payload) => {
  return CalendarRepository.calendarBookingStaffDaysoff(payload).then(response => {
    let result = response.data.data
    commit('SET_CALENDAR_DAYSOFF', result)

    return result
  })
}
const getCalendarBookingManagerDaysoff = ({ commit }, payload) => {
  return CalendarRepository.calendarBookingManagerDaysoff(payload).then(response => {
    let result = response.data.data

    commit('SET_CALENDAR_DAYSOFF', result)

    return result
  })
}
const getCalendarBookingsBySalonAndPeriod = ({ commit }, payload) => {
  return CalendarRepository.calendarBookingsListBySalonAndPeriod(payload).then(response => {
    let result = response.data.data

    commit('SET_CALENDAR_BOOKING', result)

    return result
  })
}
const getCalendarDayOffsBySalonAndPeriod = ({ commit }, payload) => {
  return CalendarRepository.calendarBookingSalonDaysoff(payload).then(response => {
    let result = response.data.data ? (response.data.data.items ? response.data.data.items : response.data.data) : []

    commit('SET_CALENDAR_DAYSOFF', result)

    return result
  })
}

export default {
  getCalendarBookingsByStaffAndPeriod,
  getCalendarDayOffsByStaffAndPeriod,
  getCalendarBookingsBySalonAndPeriod,
  getCalendarDayOffsBySalonAndPeriod,
  getCalendarBookingManagerDaysoff
}
