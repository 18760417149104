import { BookingRepository } from '@/_api/booking.repository'

const createBooking = ({ commit }, payload) => {
  return BookingRepository.create(payload).then(({ data }) => {
    return data
  })
}

const updateBooking = ({ commit }, payload) => {
  return BookingRepository.update(payload).then(({ data }) => {
    return data
  })
}

const getBookingById = ({ commit }, payload) => {
  return BookingRepository.get(payload).then(response => {
    const data = response.data

    return data
  })
}

const declineBooking = ({ commit }, payload) => {
  return BookingRepository.decline(payload).then(({ data }) => {
    return data
  })
}

const closeBooking = ({ commit }, payload) => {
  return BookingRepository.close(payload).then(({ data }) => {
    return data
  })
}

const declineProduct = ({ commit }, payload) => {
  return BookingRepository.declineProduct(payload).then(({ data }) => {
    return data
  })
}

const payForProducts = ({ commit }, payload) => {
  return BookingRepository.payForProducts(payload).then(({ data }) => {
    return data
  })
}

const payForServices = ({ commit }, payload) => {
  return BookingRepository.payForServices(payload).then(({ data }) => {
    return data
  })
}

const updateAddPrices = ({ commit }, payload) => {
  return BookingRepository.updateAddPrices(payload).then(({ data }) => {
    return data
  })
}

export default {
  createBooking,
  updateBooking,
  getBookingById,
  declineBooking,
  closeBooking,
  declineProduct,
  payForProducts,
  payForServices,
  updateAddPrices
}
