import Vue from 'vue'

const getDefaultState = () => {
  return {
    dayOffs: {}
  }
}

const SET_DAY_OFF = (state, payload) => {
  state.dayOffs = payload

  if (!Array.isArray(payload.data)) {
    state.dayOffs.data = Object.values(payload.data)
  }
}

const DELETE_DAY_OFF = (state, payload) => {
  let indexDeleted = state.dayOffs.data.findIndex(day => day.id === payload)
  state.dayOffs.data.splice(indexDeleted, 1)

  if (state.dayOffs.total !== 0) {
    state.dayOffs.total = state.dayOffs.total - 1
  }
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_DAY_OFF,
  DELETE_DAY_OFF,
  RESET_STATE,
  getDefaultState
}
