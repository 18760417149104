const getDefaultState = () => ({
  clients: null,
  clientProfileInfo: {
    commonInfo: null,
    clientOrders: null
  }
})

const SET_CLIENTS = (state, payload) => {
  state.clients = payload
}
const SET_PROFILE_INFO = (state, payload) => {
  let { client_name, phone, email, avatar_path } = payload
  const { booking } = payload
  const firstName = client_name.split(' ')[0]
  const lastName = client_name.split(' ')[1]

  state.clientProfileInfo.commonInfo = {
    firstName,
    lastName,
    phone,
    email,
    avatar_path
  }

  state.clientProfileInfo.clientOrders = booking
}
const RESET_CLIENTS_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  getDefaultState,
  RESET_CLIENTS_STATE,
  SET_CLIENTS,
  SET_PROFILE_INFO
}
