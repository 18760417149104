// Salon Group
const SalonGeneral = () => import('@/views/salon/General')
const SalonGallery = () => import('@/views/salon/Gallery')
const SalonInfo = () => import('@/views/salon/Info')
const CardCreate = () => import('@/views/salon/SalonCardCreate')
const SalonBookingsHistory = () => import('@/views/salon/SalonBookingsHistory')
const SalonClients = () => import('@/views/salon/clients/List')
const SalonClientProfile = () => import('@/views/salon/clients/Profile')

const SalonServices = () => import('@/views/salon/services/List')
const SalonServicesCreate = () => import('@/views/salon/services/Form')
const SalonServicesUpdate = () => import('@/views/salon/services/Form')

const SalonWorkingHours = () => import('@/views/salon/WorkingHours')

const SalonStaffs = () => import('@/views/salon/Staffs')
const SalonStaffsForm = () => import('@/views/salon/staffs/Form')
const SalonStaffsList = () => import('@/views/salon/staffs/List')
const SalonManagerForm = () => import('@/views/salon/staffs/ManagerForm')

const SalonWeekends = () => import('@/views/salon/dayOffs/Index')
const SalonWeekendsForm = () => import('@/views/salon/dayOffs/Form')
const SalonWeekendsList = () => import('@/views/salon/dayOffs/List')

const Calendar = () => import('@/views/calendar/Index')
const CalendarStaff = () => import('@/views/calendar/Staff')
const CalendarSalon = () => import('@/views/calendar/Salon')

// const Booking = () => import('@/views/booking/Form')

const Payments = () => import('@/views/payments/Form')

const BookingMask = () => import('@/views/BookingMask')

const Products = () => import('@/views/products/List')
const ProductsForm = () => import('@/views/products/Form')

const Categories = () => import('@/views/categories/List')
const CategoriesForm = () => import('@/views/categories/Form')

const RevenueReports = () => import('@/views/reports/RevenueReports')
const SalonPartnersProducts = () => import('@/views/salon/SalonPartnersProducts')

const routes = [
  {
    path: '/salon',
    name: 'salon',
    meta: {
      i18PageTitle: 'general.page-title'
    },
    component: SalonGeneral
  },
  {
    path: '/salon/gallery',
    name: 'gallery',
    meta: {
      i18PageTitle: 'salon-gallery.page-title'
    },
    component: SalonGallery
  },
  {
    path: '/salon/info',
    name: 'info',
    meta: {
      i18PageTitle: 'salon-info.page-title'
    },
    component: SalonInfo
  },
  {
    path: '/salon/card-create',
    name: 'card-create',
    meta: {
      i18PageTitle: 'salon-info.page-title'
    },
    component: CardCreate
  },
  {
    path: '/salon/bookings-history',
    name: 'bookings-history',
    meta: {
      i18PageTitle: 'salon-bookings.page-title',
      needFullGeneral: true
    },
    component: SalonBookingsHistory
  },
  {
    path: '/salon/services',
    name: 'services',
    meta: {
      i18PageTitle: 'salon-services.page-title'
    },
    component: SalonServices
  },
  {
    path: '/salon/services/create',
    name: 'services-create',
    meta: {
      i18PageTitle: 'salon-services-create.page-title'
    },
    component: SalonServicesCreate
  },
  {
    path: '/salon/services/update/:id',
    name: 'services-update',
    meta: {
      i18PageTitle: 'salon-services-edit.page-title'
    },
    component: SalonServicesUpdate
  },
  {
    path: '/salon/clients',
    name: 'salon-clients',
    meta: {
      i18PageTitle: 'salon-clients.page-title',
      needFullGeneral: true
    },
    component: SalonClients
  },
  {
    path: '/salon/clients/:clientId',
    name: 'salon-client-profile',
    meta: {
      i18PageTitle: 'salon-clients.page-title',
      needFullGeneral: true
    },
    component: SalonClientProfile
  },
  {
    path: '/salon/staff',
    name: 'staff',
    meta: {
      i18PageTitle: 'salon-employees.page-title'
    },
    component: SalonStaffs,
    children: [
      {
        path: 'create',
        name: 'staff-create',
        meta: {
          i18PageTitle: 'salon-employees-create.page-title'
        },
        component: SalonStaffsForm
      },
      {
        path: 'update/:id',
        name: 'staff-update',
        meta: {
          i18PageTitle: 'salon-employees-edit.page-title'
        },
        component: SalonStaffsForm
      },
      {
        path: '/salon/staff',
        name: 'staff-list',
        meta: {
          i18PageTitle: 'salon-employees.page-title'
        },
        component: SalonStaffsList
      },
      {
        path: 'manager-create',
        name: 'manager-create',
        meta: {
          i18PageTitle: 'salon-employees-create.page-title'
        },
        component: SalonManagerForm
      },
      {
        path: 'manager-update/:id',
        name: 'manager-update',
        meta: {
          i18PageTitle: 'salon-employees-edit.page-title'
        },
        component: SalonManagerForm
      }
    ]
  },
  {
    path: '/salon/working-hours',
    name: 'working-Hours',
    meta: {
      i18PageTitle: 'salon-working-hours.page-title'
    },
    component: SalonWorkingHours
  },
  {
    path: '/booking-mask',
    name: 'booking-mask',
    meta: {
      i18PageTitle: 'booking_mask.page-title',
      needFullGeneral: true
    },
    component: BookingMask
  },
  {
    path: '/salon/days-off',
    name: 'days-off',
    meta: {
      i18PageTitle: 'salon-days-off.page-title'
    },
    component: SalonWeekends,
    children: [
      {
        path: 'create',
        name: 'create',
        meta: {
          i18PageTitle: 'salon-days-off-create.page-title'
        },
        component: SalonWeekendsForm
      },
      {
        path: 'update/:id',
        name: 'update',
        meta: {
          i18PageTitle: 'salon-days-off-edit.page-title'
        },
        component: SalonWeekendsForm
      },
      {
        path: '/salon/days-off',
        name: 'list',
        meta: {
          i18PageTitle: 'salon-days-off.page-title'
        },
        component: SalonWeekendsList
      }
    ]
  },
  {
    path: '/calendar/:type/:date',
    name: 'calendar',
    meta: {
      i18PageTitle: 'salon-calendar.page-title',
      needFullGeneral: true
    },
    component: Calendar,
    children: [
      {
        path: '/calendar/staff/:type/:date',
        name: 'calendar-staff',
        meta: {
          i18PageTitle: 'staff-calendar.page-title',
          needFullGeneral: true
        },
        component: CalendarStaff
      },
      {
        path: '/calendar/:type/:date',
        name: 'calendar-salon',
        meta: {
          i18PageTitle: 'salon-calendar.page-title',
          needFullGeneral: true
        },
        component: CalendarSalon
      }
    ]
  },
  // {
  //   path: '/booking/create',
  //   name: 'booking-create',
  //   meta: {
  //     i18PageTitle: 'booking.page-title-create',
  //     needFullGeneral: true
  //   },
  //   component: Booking
  // },
  // {
  //   path: '/booking/update/:id',
  //   name: 'booking-update',
  //   meta: {
  //     i18PageTitle: 'booking.page-title-update',
  //     needFullGeneral: true
  //   },
  //   component: Booking
  // },
  {
    path: '/payments',
    name: 'payments',
    meta: {
      i18PageTitle: 'payments.page-title',
      needFullGeneral: true
    },
    component: Payments,
    children: [
      {
        path: '/payments',
        name: 'payments-list',
        meta: {
          i18PageTitle: 'payments.page-title',
          needFullGeneral: true
        },
        component: Payments
      }
    ]
  },
  {
    path: '/categories',
    name: 'categories',
    meta: {
      i18PageTitle: 'categories.page-title',
      needFullGeneral: true
    },
    component: Categories
  },
  {
    path: '/categories/create',
    name: 'categories-create',
    meta: {
      i18PageTitle: 'categories_create.page-title',
      needFullGeneral: true
    },
    component: CategoriesForm
  },
  {
    path: '/categories/update/:id',
    name: 'categories-update',
    meta: {
      i18PageTitle: 'categories_edit.page-title',
      needFullGeneral: true
    },
    component: CategoriesForm
  },
  {
    path: '/products',
    name: 'products',
    meta: {
      i18PageTitle: 'products.page-title',
      needFullGeneral: true
    },
    component: Products
  },
  {
    path: '/products/create',
    name: 'products-create',
    meta: {
      i18PageTitle: 'products_create.page-title',
      needFullGeneral: true
    },
    component: ProductsForm
  },
  {
    path: '/products/update/:id',
    name: 'products-update',
    meta: {
      i18PageTitle: 'products_edit.page-title',
      needFullGeneral: true
    },
    component: ProductsForm
  },
  {
    path: '/revenue-reports',
    name: 'revenue-reports',
    meta: {
      i18PageTitle: 'revenue_reports.page-title',
      needFullGeneral: true
    },
    component: RevenueReports
  },
  {
    path: '/salon-partners',
    name: 'salon-partners',
    meta: {
      i18PageTitle: 'Salon partners',
      layout: 'header-bordered'
    },
    component: SalonPartnersProducts
  }
]

export default routes.map(route => {
  return route.children
    ? {
        ...route,
        meta: {
          ...route.meta,
          ...{ public: false, layout: 'default' }
        },
        children: route.children.map(childRoute => ({
          ...childRoute,
          meta: { ...childRoute.meta, ...{ public: false, layout: 'default' } }
        }))
      }
    : {
        ...route,
        meta: {
          ...route.meta,
          ...{ public: false, layout: 'default' }
        }
      }
})
