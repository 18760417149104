const getDefaultState = () => {
  return {}
}
const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  RESET_STATE,
  getDefaultState
}
