<template>
  <div class="embed-responsive embed-responsive-16by9">
    <iframe
      class="embed-responsive-item"
      src="https://www.youtube.com/embed/b2WiHLR1Lhw?controls=0&rel=0"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
import Video from '@/components/Video'

export default {
  name: 'FifthSection',
  components: {
    Video
  },
  data() {
    return {}
  }
}
</script>