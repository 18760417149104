import moment from 'moment/src/moment'
import { groupBy } from 'lodash'

function getStaffEvents(state) {
  const data = [{ start_time: '1900-01-01', name: '' }]

  state.bookings.forEach(booking => {
    let fromDate = moment(booking.start_time)
    let toDate = moment(booking.end_time)

    data.push({
      ...booking,
      ...{ type: 'booking' },
      ...{ inlineLayout: toDate.diff(fromDate, 'minutes') === 15 }
    })
  })

  return data
}

function getStaffDaysOff(state) {
  const data = [{ start_time: '1900-01-01', name: '' }]

  state.daysOff.forEach(dayoff => {
    if (dayoff.start_time.length <= 11) {
      data.push({
        ...dayoff,
        ...{ fullDay: true },
        ...{ type: 'dayoff' },
        ...{ start_time: `${dayoff.start_time.trim()} 00:00:00` },
        ...{ end_time: `${dayoff.end_time.trim()} 23:59:00` }
      })
    } else {
      data.push({
        ...dayoff,
        ...{ type: 'dayoff' },
        ...{ start_time: dayoff.start_time.trim() },
        ...{ end_time: dayoff.end_time.trim() }
      })
    }
  })

  return data
}

function getFullDaySalonDaysOff(state, staffList, startDate) {
  const data = [{ start_time: '1900-01-01', name: '' }]

  staffList.forEach((specialist, idx) => {
    specialist.workingHours.forEach(workingHour => {
      let monday = moment(startDate).isoWeekday(1)
      let weekendDay = monday.add(workingHour.work_day_id - 1, 'days').format('YYYY-MM-DD')
      let dayOff = {
        staff: specialist,
        staff_id: specialist.staff_id || specialist.manager_id,
        type: 'dayoff',
        isWeekend: true
      }

      let day = moment(startDate)
        .add(idx, 'days')
        .format('YYYY-MM-DD')

      if (
        !workingHour.start_time ||
        !workingHour.end_time ||
        (workingHour.start_time && workingHour.start_time === 'null') ||
        (workingHour.end_time && workingHour.end_time === 'null')
      ) {
        if (weekendDay === startDate) {
          data.push({
            ...dayOff,
            ...{ start_time: `${day} 00:00:00` },
            ...{ end_time: `${day} 23:59:59` }
          })
        }
      } else {
        if (weekendDay === startDate) {
          data.push({
            ...dayOff,
            ...{ start_time: `${day} 00:00:00` },
            ...{ end_time: `${day} ${workingHour.start_time}:00` }
          })

          data.push({
            ...dayOff,
            ...{ start_time: `${day} ${workingHour.end_time}:00` },
            ...{ end_time: `${day} 23:59:00` }
          })
        }
      }
    })
  })

  return data
}

function getFullWeekSalonDaysOff(state, staffList, startDate) {
  const data = [{ start_time: '1900-01-01', name: '' }]

  staffList.forEach((specialist, idx) => {
    specialist.dateKey = moment(startDate).format('YYYY-MM-DD HH:mm:ss')
    startDate = moment(startDate).add(1, 'hours')

    specialist.workingHours.forEach(workingHour => {
      if (
        !workingHour.start_time ||
        !workingHour.end_time ||
        (workingHour.start_time && workingHour.start_time === 'null') ||
        (workingHour.end_time && workingHour.end_time === 'null')
      ) {
        let monday = moment(startDate).isoWeekday(1)
        let weekendDay = monday.add(workingHour.work_day_id - 1, 'days').format('YYYY-MM-DD')

        if (weekendDay) {
          let startTimeKey = specialist.dateKey
          let endTimeKey = moment(specialist.dateKey).add(1, 'hours')

          data.push({
            staff: specialist,
            staff_id: specialist.staff_id || specialist.manager_id,
            type: 'dayoff',
            start_time: moment(weekendDay).format(`YYYY-MM-DD ${moment(startTimeKey).format('HH:mm:ss')}`),
            end_time: moment(weekendDay).format(`YYYY-MM-DD ${moment(endTimeKey).format('HH:mm:ss')}`),
            isWeekend: true
          })
        }
      }
    })
  })

  return data
}

function getSalonDayEventsByParams(state, staffList, startDate, type) {
  const data = [{ start_time: '1900-01-01', name: '' }]
  const stateFieldName = type === 'booking' ? 'bookings' : 'daysOff'
  const cachedStartDate = startDate

  staffList.forEach(specialist => {
    specialist.dateKey = moment(startDate).format('YYYY-MM-DD')
    startDate = moment(startDate).add(1, 'days')
  })

  state[stateFieldName].forEach(event => {
    if (!event.start_time.includes(cachedStartDate)) {
      return
    }

    let staff = staffList.find(specialist => {
      if (
        (specialist.staff_id && specialist.staff_id === event.staff_id) ||
        (specialist.manager_id && specialist.manager_id === event.manager_id)
      ) {
        return specialist
      }
    })

    let fromDate = moment(event.start_time)
    let toDate = moment(event.end_time)

    let formatedEvent = {
      ...event,
      type: type,
      staff_id: event.staff_id || event.manager_id,
      fullDay: event.start_time.length <= 11,
      inlineLayout: toDate.diff(fromDate, 'minutes') === 15
    }

    if (!event.staff_id && !event.manager_id) {
      if (event.start_time.length <= 11) {
        formatedEvent = {
          ...formatedEvent,
          ...{
            start_time: moment(staffList[0].dateKey).format(`YYYY-MM-DD 00:00:00`)
          },
          ...{
            end_time: moment(staffList[0].dateKey).format(`YYYY-MM-DD 23:59:59`)
          },
          ...{ allSalonEvent: true }
        }
      } else {
        formatedEvent = {
          ...formatedEvent,
          ...{
            start_time: moment(staffList[0].dateKey).format(`YYYY-MM-DD ${moment(event.start_time).format('HH:mm:ss')}`)
          },
          ...{
            end_time: moment(staffList[0].dateKey).format(`YYYY-MM-DD ${moment(event.end_time).format('HH:mm:ss')}`)
          },
          ...{ allSalonEvent: true }
        }
      }

      data.push(formatedEvent)
    }

    if (staff) {
      formatedEvent = {
        ...formatedEvent,
        ...{ staff: staff }
      }

      if (event.start_time.length <= 11) {
        formatedEvent = {
          ...formatedEvent,
          ...{ start_time: moment(staff.dateKey).format(`YYYY-MM-DD 00:00:00`) },
          ...{ end_time: moment(staff.dateKey).format(`YYYY-MM-DD 23:59:59`) }
        }
      } else {
        formatedEvent = {
          ...formatedEvent,
          ...{
            start_time: moment(staff.dateKey).format(`YYYY-MM-DD ${moment(event.start_time).format('HH:mm:ss')}`)
          },
          ...{
            end_time: moment(staff.dateKey).format(`YYYY-MM-DD ${moment(event.end_time).format('HH:mm:ss')}`)
          }
        }
      }

      data.push(formatedEvent)
    }
  })

  return data
}

function getSalonWeekEventsByParams(state, staffList, startDate, type) {
  const data = [{ start_time: '1900-01-01', name: '' }]

  const bookings = state.bookings
  const daysoff = state.daysOff

  startDate = moment(startDate)
  startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  startDate.toISOString()

  staffList.forEach(specialist => {
    specialist.dateKey = moment(startDate).format('YYYY-MM-DD HH:mm:ss')
    startDate = startDate.add(1, 'hours')

    const filterEventsCondition = event =>
      (event.staff_id && event.staff_id === specialist.staff_id) ||
      (event.manager_id && event.manager_id === specialist.manager_id) ||
      (!event.manager_id && !event.staff_id)

    const staffBookings = bookings.filter(event => filterEventsCondition(event))
    const staffDaysoff = daysoff.filter(event => filterEventsCondition(event))

    let groupBookingsByDate = groupBy(staffBookings, event => {
      return moment(event.start_time)
        .startOf('day')
        .format('YYYY-MM-DD')
    })

    let groupDaysoffByDate = groupBy(staffDaysoff, event => {
      return moment(event.start_time)
        .startOf('day')
        .format('YYYY-MM-DD')
    })

    let event = {
      type: type,
      staff: specialist,
      staff_id: specialist.staff_id || specialist.manager_id
    }

    const startTimeKey = specialist.dateKey
    const endTimeKey = moment(specialist.dateKey).add(1, 'hours')

    for (let i in groupBookingsByDate) {
      let startTimeKey = specialist.dateKey
      let endTimeKey = moment(specialist.dateKey).add(1, 'hours')

      data.push({
        ...event,
        type: 'booking',
        start_time: moment(i).format(`YYYY-MM-DD ${moment(startTimeKey).format('HH:mm:ss')}`),
        end_time: moment(i).format(`YYYY-MM-DD ${moment(endTimeKey).format('HH:mm:ss')}`),
        count: groupBookingsByDate[i].length
      })
    }
    for (let i in groupDaysoffByDate) {
      data.push({
        ...event,
        type: 'dayoff',
        start_time: moment(i).format(`YYYY-MM-DD ${moment(startTimeKey).format('HH:mm:ss')}`),
        end_time: moment(i).format(`YYYY-MM-DD ${moment(endTimeKey).format('HH:mm:ss')}`),
        count: groupDaysoffByDate[i].length
      })
    }
  })

  return data
}

function getFullDaysOffByStaff(state) {
  const data = [{ start_time: '1900-01-01', name: '' }]

  if (state.selectedStaff) {
    state.selectedStaff.workingHours.forEach(workingHour => {
      let monday = moment(state.calendarDateFormatData.date).isoWeekday(1)
      let weekendDay = monday.add(workingHour.work_day_id - 1, 'days').format('YYYY-MM-DD')
      let dayOff = {
        staff_id: state.selectedStaff.staff_id || state.selectedStaff.manager_id,
        type: 'dayoff',
        isWeekend: true
      }

      if (
        !workingHour.start_time ||
        !workingHour.end_time ||
        (workingHour.start_time && workingHour.start_time === 'null') ||
        (workingHour.end_time && workingHour.end_time === 'null')
      ) {
        if (weekendDay) {
          data.push({
            ...dayOff,
            ...{ start_time: `${weekendDay} 00:00:00` },
            ...{ end_time: `${weekendDay} 23:59:00` }
          })
        }
      } else {
        data.push({
          ...dayOff,
          ...{ start_time: `${weekendDay} 00:00:00` },
          ...{ end_time: `${weekendDay} ${workingHour.start_time}:00` }
        })

        data.push({
          ...dayOff,
          ...{ start_time: `${weekendDay} ${workingHour.end_time}:00` },
          ...{ end_time: `${weekendDay} 23:59:00` }
        })
      }
    })
  }

  return data
}

export default {
  getStaffEvents,
  getSalonDayEventsByParams,
  getFullDaysOffByStaff,
  getSalonWeekEventsByParams,
  getStaffDaysOff,
  getFullDaySalonDaysOff,
  getFullWeekSalonDaysOff
}
