import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from '@/_store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [].concat(routes)
})

router.beforeEach((to, from, next) => {
  const authenticated = localStorage.getItem('access_token')
  const allowWithoutSalon = ['salon', 'info', 'calendar', 'calendar-salon', 'calendar-staff', 'card-create']
  const isPublic = to.matched.some(record => record.meta.public)
  const requiredSalon = to.matched.some(record => !allowWithoutSalon.includes(record.name) && !isPublic)
  const userRole = store.getters['user/getRole']

  const staffRoutes = ['calendar-salon', 'calendar-staff', 'booking-update']
  const managerRoutes = ['calendar-salon', 'calendar-staff', 'booking-update', 'booking-create', 'booking-mask']

  const disabledTopManagerRoutes = ['info', 'payments', 'payments-list']

  let needModeration = to.meta.needFullGeneral !== undefined
  let salonId = store.state.user.accountInfo.salon_id
  let errorMessageBlocks = {
    type: 'info',
    messageI18Key: needModeration ? 'general.alerts.empty_all_block' : 'general.alerts.empty_salon_info_block'
  }
  let errorMessageAllBlocks = {
    type: 'info',
    messageI18Key: 'general.alerts.empty_all_block'
  }

  let toHome = false

  let moderationStatus = store.state.salons.general.status

  if (!to.matched.length) {
    return next('/not-found')
  } else if (to.name !== 'not-found') {
    if (!isPublic && !authenticated) {
      return next('/sign-in')
    }
  }

  if (requiredSalon || needModeration) {
    if (salonId === undefined || salonId === null) {
      store.dispatch('userMessages/setError', errorMessageBlocks)

      return next('/salon')
    } else {
      if (salonId) {
        if (moderationStatus === undefined) {
          store.dispatch('salons/getGeneral', salonId).then(response => {
            if (needModeration && response.status !== 'Active') toHome = true

            if (toHome) {
              store.dispatch('userMessages/setError', errorMessageAllBlocks)

              return next('/salon')
            } else {
              next()
            }
          })
        } else {
          if (needModeration && moderationStatus !== 'Active') {
            store.dispatch('userMessages/setError', errorMessageAllBlocks)
            return next('/salon')
          }
        }
      }
    }
  }

  if (
    (userRole === 'staff' && !staffRoutes.includes(to.name) && !isPublic) ||
    (userRole === 'manager' && !managerRoutes.includes(to.name) && !isPublic) ||
    (userRole === 'salon_manager' && disabledTopManagerRoutes.includes(to.name))
  ) {
    return next('/403')
  }

  next()
})

export default router
