const getDefaultState = () => {
  return {
    salon: {},
    general: {},
    moderateFlag: {},
    salonPayble: 0
  }
}

const SET_SALON = (state, payload) => {
  state.salon = payload
}

const SET_GENERAL = (state, payload) => {
  state.general = payload
}
const SET_MODERATE_FLAG = (state, payload) => {
  state.moderateFlag = payload
}
const MODERATE_IS_DECLINED = state => {
  state.moderateFlag = {}
}
const SET_SALON_PAYBLE = (state, payload) => {
  state.salonPayble = payload
}
const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_SALON,
  SET_GENERAL,
  RESET_STATE,
  SET_MODERATE_FLAG,
  MODERATE_IS_DECLINED,
  SET_SALON_PAYBLE,
  getDefaultState
}
