<template>
  <section class="">
    <div class="d-none d-md-block container py-lg-14 py-3">
      <div class="row align-items-end mb-xl-n8">
        <div class="col-12 mb-1 px-xl-1">
          <h2 class="mb-xxl-0 mb-2 text-center">{{ $t('main.sections.section_3.title') }}</h2>
        </div>
      </div>

      <b-tabs
        nav-wrapper-class="mt-0 mt-xl-8 col-12 pr-1 pr-xl-0 d-flex justify-content-center"
        nav-class="nav nav-tabs nav-fill nav nav-pills nav-fill pr-0 mb-xl-6 mb-4 salon-lp-tabs flex-column flex-nowrap col-lg-8 col-12 flex-md-row mt-md-3"
        content-class="mt-1-5"
        fill
      >
        <b-tab :title="$t('main.sections.section_3.tab_2.title')" title-link-class="justify-content-center">
          <div class="row align-items-xl-start align-items-center">
            <div class="col-xl-5 col-lg-6 mb-4 mb-lg-0 mt-xxl-5 order-2 order-lg-1 sectionTab">
              <h3 class="mb-2-5 text-left">
                {{ $t('main.sections.section_3.tab_2.title') }}
              </h3>
              <div>
                <p class="mb-2-5 text-left">
                  {{ $t('main.sections.section_3.tab_2.description_1') }}
                </p>

                <p class="mb-2-5 text-left">
                  {{ $t('main.sections.section_3.tab_2.description_2') }}
                </p>
                <p class="mb-4 text-left">
                  {{ $t('main.sections.section_3.tab_2.description_3') }}
                </p>
              </div>

              <router-link to="/sign-up" class="btn py-1 btn-primary w-100">
                {{ $t('main.sections.section_3.bottom_link') }}
              </router-link>
            </div>
            <div class="col-xl-6 offset-xl-1 col-lg-6 order-1 order-lg-2 mb-lg-0 mb-3 tab-content-bg">
              <div class="pt-3 pb-1-5">
                <img v-if="isDeutchLang" src="@/assets/images/salon-lp/live-calendar-de.png" alt="" class="mw-100" />
                <img v-else src="@/assets/images/salon-lp/live-calendar.png" alt="" class="mw-100" />
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('main.sections.section_3.tab_3.title')" title-link-class="justify-content-center" active>
          <div class="row align-items-xl-start align-items-center">
            <div class="col-xl-5 col-lg-6 mb-4 mb-lg-0 mt-xxl-5 order-2 order-lg-1">
              <h3 class="mb-2-5 text-left">
                {{ $t('main.sections.section_3.tab_3.title') }}
              </h3>
              <p class="mb-2-5 text-left">
                {{ $t('main.sections.section_3.tab_3.description_1') }}
              </p>

              <p class="mb-4 text-left">
                {{ $t('main.sections.section_3.tab_3.description_2') }}
              </p>
              <div class="row mb-xl-3 mb-2-5">
                <div
                  class="col-xl-6 mb-xl-0 mb-2-5 d-flex flex-row flex-xl-column align-items-center align-items-xl-start"
                >
                  <div>
                    <h5 class="mb-1-5">
                      {{ $t('main.sections.section_3.tab_3.card_1.title') }}
                    </h5>
                    <p class="mb-0">
                      {{ $t('main.sections.section_3.tab_3.card_1.description') }}
                    </p>
                  </div>
                </div>
                <div class="col-xl-6 d-flex flex-row flex-xl-column align-items-center align-items-xl-start">
                  <div>
                    <h5 class="mb-1-5">
                      {{ $t('main.sections.section_3.tab_3.card_2.title') }}
                    </h5>
                    <p class="mb-0">
                      {{ $t('main.sections.section_3.tab_3.card_2.description') }}
                    </p>
                  </div>
                </div>
              </div>
              <router-link to="/sign-up" class="btn py-1 btn-primary w-100">
                {{ $t('main.sections.section_3.bottom_link') }}
              </router-link>
            </div>
            <div class="col-xl-6 offset-xl-1 col-lg-6 order-1 order-lg-2 mb-lg-0 mb-3 tab-content-bg">
              <div class="pt-3 pb-1-5">
                <img v-if="isDeutchLang" src="@/assets/images/salon-lp/staff-management-de.png" alt="" class="mw-100" />
                <img v-else src="@/assets/images/salon-lp/staff-management.png" alt="" class="mw-100" />
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab
          :title="$t('main.sections.section_3.tab_4.title')"
          title-link-class="justify-content-center mt-6 mt-md-0"
        >
          <div class="row align-items-xl-start align-items-center">
            <div class="col-xl-5 col-lg-6 mb-4 mb-lg-0 mt-xxl-5 order-2 order-lg-1 sectionTab">
              <h3 class="mb-2-5 text-left">
                {{ $t('main.sections.section_3.tab_4.title') }}
              </h3>
              <div>
                <p class="mb-2-5 text-left">
                  {{ $t('main.sections.section_3.tab_4.description_1') }}
                </p>

                <p class="mb-4 text-left">
                  {{ $t('main.sections.section_3.tab_4.description_2') }}
                </p>
              </div>

              <router-link to="/sign-up" class="btn py-1 btn-primary w-100">
                {{ $t('main.sections.section_3.bottom_link') }}
              </router-link>
            </div>
            <div class="col-xl-6 offset-xl-1 col-lg-6 order-1 order-lg-2 mb-lg-0 mb-3 tab-content-bg">
              <div class="pt-3 pb-1-5">
                <img
                  v-if="isDeutchLang"
                  src="@/assets/images/salon-lp/project-management-de.png"
                  alt=""
                  class="mw-100"
                />
                <img v-else src="@/assets/images/salon-lp/project-management.png" alt="" class="mw-100" />
              </div>
            </div>
          </div>

          <!-- Content -->
        </b-tab>
        <b-tab :title="$t('main.sections.section_3.tab_5.title')" title-link-class="justify-content-center">
          <div class="row align-items-xl-start align-items-center">
            <div class="col-xl-5 col-lg-6 mb-4 mb-lg-0 mt-xxl-5 order-2 order-lg-1 sectionTab">
              <h3 class="mb-2-5 text-left">
                {{ $t('main.sections.section_3.tab_5.title') }}
              </h3>
              <div>
                <p class="mb-2-5 text-left">
                  {{ $t('main.sections.section_3.tab_5.description_1') }}
                </p>

                <p class="mb-4 text-left">
                  {{ $t('main.sections.section_3.tab_5.description_2') }}
                </p>
              </div>

              <router-link to="/sign-up" class="btn py-1 btn-primary w-100">
                {{ $t('main.sections.section_3.bottom_link') }}
              </router-link>
            </div>
            <div class="col-xl-6 offset-xl-1 col-lg-6 order-1 order-lg-2 mb-lg-0 mb-3 tab-content-bg">
              <div class="pt-3 pb-1-5">
                <img v-if="isDeutchLang" src="@/assets/images/salon-lp/statistics-de.png" alt="" class="mw-100" />
                <img v-else src="@/assets/images/salon-lp/statistics.png" alt="" class="mw-100" />
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div class="container pt-3 pb-0 d-block scrollButtons d-md-none">
      <div class="mb-2">
        <div class="col px-0">
          <h2 class="mb-xxl-0 mb-2 text-center">{{ $t('main.sections.section_3.title') }}</h2>
          <p class="mb-2 text-center">{{ $t('main.sections.section_3.scroll_description') }}</p>
          <div class="col-12 px-0 mb-2 d-flex">
            <div
              class="col-6 button pr-md-1 pr-lg-0-3 col-lg white-space-nowrap text-nowrap"
              @click="scrollToSelector('scrollbar-1')"
            >
              <button class="btn btn-outline-secondary-custom btn-block py-0-5 px-0-3">
                <span>{{ $t('main.sections.section_3.tab_2.title') }}</span>
              </button>
            </div>
            <div
              class="col-6 button pr-md-1 pr-lg-0-3 col-lg white-space-nowrap text-nowrap"
              @click="scrollToSelector('scrollbar-2')"
            >
              <button class="btn btn-outline-secondary-custom btn-block py-0-5 px-0-3">
                <span>{{ $t('main.sections.section_3.tab_3.title') }}</span>
              </button>
            </div>
          </div>
          <div class="col-12 px-0 mb-2 d-flex">
            <div
              class="col-6 button pr-md-1 pr-lg-0-3 col-lg white-space-nowrap text-nowrap"
              @click="scrollToSelector('scrollbar-3')"
            >
              <button class="btn btn-outline-secondary-custom btn-block py-0-5 px-0-3">
                <span>{{ $t('main.sections.section_3.tab_4.title') }}</span>
              </button>
            </div>
            <div
              class="col-6 button pr-md-1 pr-lg-0-3 col-lg white-space-nowrap text-nowrap"
              @click="scrollToSelector('scrollbar-4')"
            >
              <button class="btn btn-outline-secondary-custom btn-block py-0-5 px-0-3">
                <span>{{ $t('main.sections.section_3.tab_5.title') }}</span>
              </button>
            </div>
          </div>
          <div class="border mx-1 mb-2"></div>
          <div class="col-12 px-0 mb-2 d-flex">
            <div
              class="col-6 button pr-md-1 pr-lg-0-3 col-lg white-space-nowrap text-nowrap"
              @click="scrollToSelector('scrollbar-5')"
            >
              <button class="btn btn-outline-secondary-custom btn-block py-0-5 px-0-3">
                <span>{{ $t('main.sections.section_4.card_1.title') }}</span>
              </button>
            </div>
            <div
              class="col-6 button pr-md-1 pr-lg-0-3 col-lg white-space-nowrap text-nowrap"
              @click="scrollToSelector('scrollbar-6')"
            >
              <button class="btn btn-outline-secondary-custom btn-block py-0-5 px-0-3">
                <span>{{ $t('main.sections.section_4.card_2.title') }}</span>
              </button>
            </div>
          </div>
          <div class="col-12 px-0 mb-2 d-flex">
            <div
              class="col-6 button pr-md-1 pr-lg-0-3 col-lg white-space-nowrap text-nowrap"
              @click="scrollToSelector('scrollbar-7')"
            >
              <button class="btn btn-outline-secondary-custom btn-block py-0-5 px-0-3">
                <span>{{ $t('main.sections.section_4.card_3.title') }}</span>
              </button>
            </div>
            <div
              class="col-6 button pr-md-1 pr-lg-0-3 col-lg white-space-nowrap text-nowrap"
              @click="scrollToSelector('scrollbar-8')"
            >
              <button class="btn btn-outline-secondary-custom btn-block py-0-5 px-0-3">
                <span>{{ $t('main.sections.section_4.card_4.title') }}</span>
              </button>
            </div>
          </div>
          <div class="col-12 px-0 mb-2 d-flex">
            <div
              class="col-6 button pr-md-1 pr-lg-0-3 col-lg white-space-nowrap text-nowrap"
              @click="scrollToSelector('scrollbar-9')"
            >
              <button class="btn btn-outline-secondary-custom btn-block py-0-5 w-100 text-truncate px-0-3">
                <span>{{ $t('main.sections.section_4.card_5.title') }}</span>
              </button>
            </div>
            <div
              class="col-6 button pr-md-1 pr-lg-0-3 col-lg white-space-nowrap text-nowrap px-0-3"
              @click="scrollToSelector('scrollbar-10')"
            >
              <button class="btn btn-outline-secondary-custom btn-block py-0-5">
                <span>{{ $t('main.sections.section_4.card_6.title') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col d-flex flex-wrap px-0 mb-2">
        <div class="col-12 mb-2 px-0">
          <div id="scrollbar-1" class="card pt-2 px-2-5">
            <div class="row align-items-xl-start align-items-center">
              <div class="col-xl-5 px-0 col-lg-6 mb-2 mb-lg-0 mt-xxl-5 order-2 order-lg-1">
                <h4 class="mb-2-5 text-center text-nowrap">
                  {{ $t('main.sections.section_3.tab_2.title') }}
                </h4>
                <div class="col-xl-6 offset-xl-1 col-lg-6 order-1 order-lg-2 mb-lg-0 mb-2 tab-content-bg">
                  <div class="pt-3 pb-1-5">
                    <img
                      v-if="isDeutchLang"
                      src="@/assets/images/salon-lp/live-calendar-de.png"
                      alt=""
                      class="mw-100"
                    />
                    <img v-else src="@/assets/images/salon-lp/live-calendar.png" alt="" class="mw-100" />
                  </div>
                </div>
                <p class="mb-2-5 text-left mob-line-height">
                  {{ $t('main.sections.section_3.tab_2.description_1') }}
                </p>

                <p class="mb-2-5 text-left mob-line-height">
                  {{ $t('main.sections.section_3.tab_2.description_2') }}
                </p>
                <p class="mb-0 text-left">
                  {{ $t('main.sections.section_3.tab_2.description_3') }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-2 px-0">
          <div id="scrollbar-2" class="card points-bg-end pt-2 px-1-5">
            <div class="row align-items-xl-start align-items-center">
              <div class="col mb-lg-0 mt-xxl-5 order-2 order-lg-1">
                <h4 class="mb-2-5 text-left text-nowrap">
                  {{ $t('main.sections.section_3.tab_3.title') }}
                </h4>
                <div class="col-xl-6 offset-xl-1 col-lg-6 order-1 order-lg-2 mb-lg-0 mb-2 tab-content-bg">
                  <div class="pt-3 pb-1-5">
                    <img
                      v-if="isDeutchLang"
                      src="@/assets/images/salon-lp/staff-management-de.png"
                      alt=""
                      class="mw-100"
                    />
                    <img v-else src="@/assets/images/salon-lp/staff-management.png" alt="" class="mw-100" />
                  </div>
                </div>
                <p class="mb-2-5 mob-line-height">
                  {{ $t('main.sections.section_3.tab_3.description_1') }}
                </p>

                <p class="mb-1 mob-line-height">
                  {{ $t('main.sections.section_3.tab_3.description_2') }}
                </p>
                <div class="row mb-xl-4 mb-1-5">
                  <div
                    class="col-xl-6 mb-xl-0 mb-2-5 d-flex flex-row flex-xl-column align-items-center align-items-xl-start"
                  >
                    <div>
                      <h5 class="mb-1 fz-1">
                        {{ $t('main.sections.section_3.tab_3.card_1.title') }}
                      </h5>
                      <p class="mb-0">
                        {{ $t('main.sections.section_3.tab_3.card_1.description') }}
                      </p>
                    </div>
                  </div>
                  <div class="col-xl-6 d-flex flex-row flex-xl-column align-items-center align-items-xl-start">
                    <div>
                      <h5 class="mb-1 fz-1">
                        {{ $t('main.sections.section_3.tab_3.card_2.title') }}
                      </h5>
                      <p class="mb-0">
                        {{ $t('main.sections.section_3.tab_3.card_2.description') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-0">
          <div class="mb-2">
            <div id="scrollbar-3" class="card points-bg-end pt-2 px-1-5">
              <div class="row align-items-xl-start align-items-center">
                <div class="col mb-lg-0 mt-xxl-5 order-2 order-lg-1">
                  <h4 class="mb-2-5 text-center text-nowrap">
                    {{ $t('main.sections.section_3.tab_4.title') }}
                  </h4>
                  <div class="col-xl-6 offset-xl-1 col-lg-6 order-1 order-lg-2 mb-lg-0 mb-2 tab-content-bg">
                    <div class="pt-3 pb-1-5">
                      <img
                        v-if="isDeutchLang"
                        src="@/assets/images/salon-lp/project-management-de.png"
                        alt=""
                        class="mw-100"
                      />
                      <img v-else src="@/assets/images/salon-lp/project-management.png" alt="" class="mw-100" />
                    </div>
                  </div>
                  <p class="mb-2-5 text-left mob-line-height">
                    {{ $t('main.sections.section_3.tab_4.description_1') }}
                  </p>

                  <p class="text-left mob-line-height">
                    {{ $t('main.sections.section_3.tab_4.description_2') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 px-0">
            <div class="">
              <div id="scrollbar-4" class="card points-bg-end pt-2 px-1-5">
                <div class="row align-items-xl-start align-items-center">
                  <div class="col mb-lg-0 mt-xxl-5 order-2 order-lg-1">
                    <h4 class="text-center mb-2-5">
                      {{ $t('main.sections.section_3.tab_5.title') }}
                    </h4>
                    <div class="col-xl-6 offset-xl-1 col-lg-6 order-1 order-lg-2 mb-lg-0 mb-2 tab-content-bg">
                      <div class="pt-3 pb-1-5">
                        <img
                          v-if="isDeutchLang"
                          src="@/assets/images/salon-lp/statistics-de.png"
                          alt=""
                          class="mw-100"
                        />
                        <img v-else src="@/assets/images/salon-lp/statistics.png" alt="" class="mw-100" />
                      </div>
                    </div>
                    <p class="mb-2-5 text-left mob-line-height">
                      {{ $t('main.sections.section_3.tab_5.description_1') }}
                    </p>

                    <p class="mob-line-height text-left">
                      {{ $t('main.sections.section_3.tab_5.description_2') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ThirdSection',
  computed: {
    ...mapGetters({
      getSiteLang: 'user/getSiteLang'
    }),
    isDeutchLang() {
      return this.getSiteLang === 'de'
    }
  },
  methods: {
    scrollToSelector(id) {
      let elem = document.getElementById(id)
      let top = elem.getBoundingClientRect().top + window.scrollY - 75
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: Math.round(top)
      })
    }
  }
}
</script>
