import { map } from 'lodash'

const getDefaultState = () => {
  return {
    salonStaff: {},
    staffList: [],
    staffListByParams: [],
    workerProfileInfo: null,
    staffServices: []
  }
}

const SET_STAFF_LIST_BY_PARAMS = (state, payload) => {
  if (payload === undefined || (payload && !payload.length)) state.staffListByParams = null
  else state.staffListByParams = payload
}

const SET_STAFF_INFO = (state, payload) => {
  state.salonStaff = payload

  if (!Array.isArray(payload.data)) {
    state.salonStaff.data = Object.values(payload.data)
  }
}

const SET_STAFF_LIST = (state, payload) => {
  state.staffList = JSON.parse(JSON.stringify(payload))
}
const SET_STAFF_PROFILE_INFO = (state, payload) => {
  state.staffServices = payload.services
}

const SET_WORKER_PROFILE_INFO = (state, payload) => {
  const profileInfo = payload.profile
  const role = payload.role
  const password = ''

  let formatedPayload = {
    ...profileInfo,
    ...profileInfo.user
  }

  if (role === 'Staff') {
    let serviceParameterList = _(profileInfo.services)
      .groupBy('service.id')
      .map((items, id) => {
        return {
          id: Number(id),
          parameters: map(items, 'parameter_value.id')
        }
      })
      .value()

    formatedPayload = {
      ...formatedPayload,
      ...{ services: serviceParameterList }
    }

    state.workerProfileInfo = formatedPayload
  } else {
    const { avatar } = profileInfo
    const { email, first_name: firstName, last_name: lastName } = profileInfo
    const formatedPayload = {
      textFields: [firstName, lastName, email, password],
      avatar
    }

    state.workerProfileInfo = formatedPayload
  }
}

const DELETE_STAFF = (state, payload) => {
  let indexDeleted = state.salonStaff.data.findIndex(staff => staff.id === payload.id && staff.role === payload.role)
  state.salonStaff.data.splice(indexDeleted, 1)

  if (state.salonStaff.total !== 0) {
    state.salonStaff.total = state.salonStaff.total - 1
  }
}

const DELETE_MANAGER = (state, payload) => {
  let indexDeleted = state.salonStaff.data.findIndex(
    staff => staff.user.id === payload.id && staff.role === payload.role
  )
  state.salonStaff.data.splice(indexDeleted, 1)

  if (state.salonStaff.total !== 0) {
    state.salonStaff.total = state.salonStaff.total - 1
  }
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_WORKER_PROFILE_INFO,
  SET_STAFF_LIST_BY_PARAMS,
  SET_STAFF_INFO,
  DELETE_STAFF,
  DELETE_MANAGER,
  SET_STAFF_LIST,
  RESET_STATE,
  SET_STAFF_PROFILE_INFO,
  getDefaultState
}
