import Repository from './repository'

const createUrl = '/product-create'
const updateUrl = '/product-update'
const deleteUrl = '/product-delete'
const getOneUrl = '/product-details'
const getAllUrl = '/products-index'

export const ProductsRepository = {
  create(payload) {
    return Repository.post(createUrl, payload).then(({ data }) => data)
  },
  update(payload) {
    return Repository.post(updateUrl, payload).then(({ data }) => data)
  },
  delete(payload) {
    return Repository.delete(`${deleteUrl}/${payload}`).then(({ data }) => data)
  },
  getOne(payload) {
    return Repository.get(getOneUrl, { params: payload }).then(data => data.data)
  },
  getAll(payload) {
    return Repository.get(getAllUrl, { params: payload })
  }
}
