<template>
  <main>
    <NavbarPublic :has-border="true"></NavbarPublic>
    <div class="mt-7 mt-md-10 marketplace-main-info">
      <div class="container w-100">
        <h1 class="mb-2 mt-5">
          Finde Deinen (Lieblings-)Coiffeur in St. Gallen
        </h1>
        <p class="mb-2">
          St. Gallen hat nicht nur im Hinblick auf Kultur und Architektur eine Menge zu bieten. Nein, hier kannst Du
          auch aktiv etwas für Dein Wohlbefinden und Dein Aussehen tun. Wir helfen Dir dabei und zeigen Dir, wo Du einen
          Coiffeur in St. Gallen findest, der Deinen Ansprüchen genügt und Dir das Aussehen verpasst, das Du Dir
          wünschst. Vom Coiffeur günstig und schnell bis hin zum Star Coiffeur – Du entscheidest!
        </p>
        <p class="mb-2">
          Denn wir halten eine Liste mit zahlreichen Salons in St. Gallen und der näheren Umgebung für Dich bereit.
          Quiff listet Dir den <router-link to="/" title="Damen Coiffeur"> Damen Coiffeur</router-link>, den
          <router-link to="/" title="Herren Coiffeur">Herren Coiffeur</router-link> aber auch den
          <router-link to="/" title="Kinder Coiffeur">Kinder Coiffeur</router-link>. Du findest alle Dienstleistungen,
          alle Referenzen, alle Bewertungen. So ist es für Dich ein Leichtes, Deinen bevorzugten Coiffeur in St. Gallen
          herauszufiltern. Du suchst einen günstigen Coiffeur St. Gallen? Du präferierst einen
          <router-link to="/Coiffeur-ohne-Voranmeldung" title="Coiffeur ohne Voranmeldung"
            >Coiffeur ohne Voranmeldung</router-link
          >, bei dem Du trotzdem ausreichend Planungssicherheit hast und im Vorfeld einen online Termin buchen kannst?
          Ein spezieller Herren Coiffeur soll Deinen Haaren einen peppigen oder klassischen Schnitt verpassen? Egal, wie
          Deine Ansprüche an den Coiffeur in St. Gallen sind, wir haben garantiert das richtige Angebot und eine grosse
          Auswahl für Dich.
        </p>
        <h2 class="mb-2">
          Durchstöbern, entscheiden, Coiffeur online buchen
        </h2>
        <p class="mb-2">
          Jeder Mensch ist individuell. Das drückt sich im Wesen, in der Kleidung, in den Vorlieben aus. Doch auch der
          Haarschnitt gehört zur Persönlichkeit jedes Einzelnen. Die einen stehen eher auf einen stylischen
          Kurzhaarschnitt, auf Bob, Undercut oder Buzz Cut. Die anderen bevorzugen lange Haare, mit oder ohne Pony,
          durchgestuft oder klassisch. Oder sie möchten ihren Haaren durch Farbe noch einmal das besondere Etwas
          verleihen: Balayage, Strähnen, Blondierung sind hier nur einige der zahlreichen Möglichkeiten.
        </p>
        <p class="mb-2">
          Wir zeigen Dir den Coiffeur in St. Gallen, der die Dienstleistungen anbietet, die Du suchst. Klicke Dich durch
          die Liste, schau Dir die Bilder in Ruhe an und triff dann Deine Entscheidung. Anschliessend suchst Du Dir
          einen (freien) Wunschtermin aus und kannst mit einem Klick den
          <a :href="coifeurOnlineBunchen" title="Coiffeur online buchen" target="_blank">Coiffeur online buchen</a>.
          Eine Bestätigung dafür erfolgt unmittelbar. Und eine Stunde vor Deinem Termin erinnern wir Dich noch einmal
          zusätzlich per E-Mail. Ob Du im Laden oder online zahlen willst, entscheidest Du selbst.
        </p>
        <div class="my-10 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.register')">
                {{ $t('footer-links.register') }}
              </router-link>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <a class="text-white" :href="`${clientAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.book') }}
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import NavbarPublic from '@/components/NavbarPublic'
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Du suchst einen Coiffeur in St. Gallen und der näheren Umgebung? Dann ist Quiff das perfekte Portal für Dich. Hier kannst Du bequem online buchen.'
      }
    ]
  },
  components: {
    NavbarPublic
  },
  computed: {
    clientAppUrl() {
      return config.clientAppUrl
    },
    coifeurOnlineBunchen() {
      return `${this.clientAppUrl}/coiffeur-online-buchen`
    }
  },
  beforeCreate: function() {
    window.scrollTo(0, -200)
  }
}
</script>
