const LANG_KEY = 'language'
const COOKIE_KEY = 'cookie'
const DEVICE_INFO = 'device_info'

const initLang = (() => {
  let siteLang = window.localStorage.getItem(LANG_KEY)
  return siteLang || 'de'
})()

const acceptCookiesAlert = (() => {
  let cookie = window.localStorage.getItem(COOKIE_KEY)

  return cookie
})()

const hideDeviceInfoMessage = (() => {
  let deviceInfo = window.localStorage.getItem(DEVICE_INFO)

  return deviceInfo
})()

const getDefaultState = () => {
  return {
    role: null,
    accountInfo: {},
    isAuthorized: false,
    socialAuthType: '',
    userPosition: {
      lat: 46.948,
      lng: 7.4474
    },
    resetEmail: '',
    siteLang: initLang,
    hideAcceptCookiesAlert: acceptCookiesAlert,
    usersByMail: [],
    hideDeviceInfoMessage: hideDeviceInfoMessage
  }
}

const SET_TOKEN = (state, token) => {
  localStorage.setItem('access_token', token)
}
const SET_ROLE = (state, payload) => {
  state.role = payload
}
const UPDATE_USER_PROFILE_IMAGE = (state, image) => {
  state.accountInfo.image = image
}

const SET_USER_ACCOUNT_INFO = (state, payload) => {
  state.accountInfo = payload
}

const SET_AUTH_STATUS = (state, authData) => {
  state.isAuthorized = authData.isAuthorized
}
const SET_RESET_EMAIL = (state, email) => {
  state.resetEmail = email
}
const SET_USER_POSITION = (state, { lat, lng }) => {
  state.userPosition.lat = lat
  state.userPosition.lng = lng
}

const LOGOUT = state => {
  let cachedLanguage = window.localStorage.getItem(LANG_KEY)
  let cachedCookie = window.localStorage.getItem(COOKIE_KEY)
  let cachedDeviceInfo = window.localStorage.getItem(DEVICE_INFO)

  Object.assign(state, { ...getDefaultState(), resetEmail: state.resetEmail })
  localStorage.clear()

  if (cachedLanguage) {
    state.siteLang = cachedLanguage
    window.localStorage.setItem(LANG_KEY, cachedLanguage)
  }

  if (cachedCookie) {
    window.localStorage.setItem(COOKIE_KEY, cachedCookie)
  }

  if (cachedDeviceInfo) {
    state.hideDeviceInfoMessage = cachedDeviceInfo
    window.localStorage.setItem(DEVICE_INFO, cachedDeviceInfo)
  }
}

const SET_SITE_LANG = (state, payload) => {
  state.siteLang = payload
  window.localStorage.setItem(LANG_KEY, payload)
}

const TOGGLE_COOKIE_ALERT = (state, payload) => {
  window.localStorage.setItem('cookie', payload)
  state.hideAcceptCookiesAlert = payload
}
const SET_SEARCH_USERS_BY_EMAIL = (state, payload) => {
  state.usersByMail = []
  payload.forEach(el => state.usersByMail.push(el))
}

const SET_SHOW_DEVICE_INFO_MESSAGE = (state, payload) => {
  window.localStorage.setItem(DEVICE_INFO, payload)
  state.hideDeviceInfoMessage = payload
}

const SET_SALON_ID = (state, payload) => {
  state.accountInfo.salon_id = payload
}
const RESET_STATE = state => {
  Object.assign(state, { ...getDefaultState(), resetEmail: state.resetEmail })
}

export default {
  getDefaultState,
  SET_TOKEN,
  SET_ROLE,
  UPDATE_USER_PROFILE_IMAGE,
  SET_USER_ACCOUNT_INFO,
  SET_AUTH_STATUS,
  LOGOUT,
  SET_RESET_EMAIL,
  SET_USER_POSITION,
  SET_SITE_LANG,
  TOGGLE_COOKIE_ALERT,
  SET_SEARCH_USERS_BY_EMAIL,
  SET_SHOW_DEVICE_INFO_MESSAGE,
  SET_SALON_ID,
  RESET_STATE
}
