<template>
  <div class="signup-page">
    <div class="row no-gutters vh-100-lg desktop-sign-in-bg">
      <AuthSlider  />
      <div class="col-md-12 col-lg col-xxl-6">
        <div class="row no-gutters h-100 px-1-5">
          <div
            class="col-12 mb-3 md-lg-0 mt-3 d-lg-flex flex-wrap align-items-center justify-content-between align-self-start px-lg-3 d-none d-lg-block"
          >
            <a :href="clientLoginLink" :title="$t('signin.content.client_login_link')">
              {{ $t('signin.content.client_login_link') }}
            </a>

            <p class="mb-0 text-center">
              <span class="text-secondary">{{ $t('signin.content.signup_link_text') }}</span>
              <router-link to="/sign-up" class="ml-1" :title="$t('signin.content.signup_link')">
                {{ $t('signin.content.signup_link') }}
              </router-link>
              <LanguageDropdown :class="'ml-1-5'" :btn-class="'py-1-5'"></LanguageDropdown>
            </p>
          </div>

          <div class="col-12 col-md-8 col-lg-12 col-xl-8 mx-auto py-3">
            <div class="my-lg-auto my-lg-2-5 mx-lg-0 mx-auto w-100">
              <div class="d-lg-none mb-1-5">
                <a :href="clientLoginLink" :title="$t('signin.content.client_login_link')">
                  {{ $t('signin.content.client_login_link') }}
                </a>
              </div>

              <div class="text-center mb-2 mb-lg-3">
                <router-link to="/" class="navbar-brand mb-2-5 d-none d-lg-block d-lg-none">
                  <img src="@/assets/images/logo_quiff.svg" />
                </router-link>
                <h1 class="h2 mb-2">
                  {{ $t('signin.content.title') }}
                </h1>
                <p>{{ $t('signin.content.subtitle') }}</p>

                <p class="mb-0 text-center d-block d-lg-none">
                  <span class="text-secondary">{{ $t('signin.content.signup_link_text') }}</span>
                  <router-link to="/sign-up" class="ml-0-5" :title="$t('signin.content.signup_link')">
                    {{ $t('signin.content.signup_link') }}
                  </router-link>
                  <LanguageDropdown :class="'ml-0-5'" :btn-class="'px-0-5'"></LanguageDropdown>
                </p>
              </div>

              <form class="form-row no-gutters" @submit.prevent="signIn('manual')">
                <div class="col-12">
                  <label for="email" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t(emailField.i18KeyLabel) }}
                  </label>
                  <div class="input-group input-group-lg mb-2-5">
                    <input
                      id="email"
                      v-model="emailField.value"
                      v-validate="emailField.validateRules"
                      :data-vv-as="$t(emailField.i18KeyLabel)"
                      :type="emailField.type"
                      :name="emailField.name"
                      class="form-control w-100 px-1-5"
                      :placeholder="$t(emailField.i18KeyPlaceholder)"
                    />
                    <span v-if="errors.has(emailField.name)" class="error-message mt-1">{{
                      errors.first(emailField.name)
                    }}</span>
                  </div>
                </div>

                <div class="col-12">
                  <label for="password" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t(passwordField.i18KeyLabel) }}
                  </label>
                  <div class="input-group input-group-lg mb-2-5">
                    <input
                      id="password"
                      v-model="passwordField.value"
                      v-validate="passwordField.validateRules"
                      :data-vv-as="$t(passwordField.i18KeyLabel)"
                      :name="passwordField.name"
                      :type="passwordField.type"
                      class="form-control px-1-5 border-right-0"
                      :placeholder="$t(passwordField.i18KeyPlaceholder)"
                    />
                    <div class="input-group-append" @click="changeType">
                      <span class="input-group-text text-gray bg-white px-1-5 py-1 border-left-0 cursor-pointer">
                        <font-awesome-icon :icon="['fas', passwordIcon]"></font-awesome-icon>
                      </span>
                    </div>
                    <span v-if="errors.has(passwordField.name)" class="error-message mt-1">{{
                      errors.first(passwordField.name)
                    }}</span>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group mb-2-5 px-0 form-check">
                    <div class="checkbox">
                      <label class="mb-0">
                        <input type="checkbox" />
                        <i class="input-helper"></i>
                        <div class="text-black">
                          {{ $t('signin.content.rememmber_me') }}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col text-right">
                  <router-link to="/reset-password-request">
                    {{ $t('signin.content.forgot_password_link') }}
                  </router-link>
                </div>

                <div class="col-12">
                  <button type="submit" class="btn btn-primary btn-block btn-lg">
                    {{ $t('signin.content.sign_in_link') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import AuthSlider from '../../components/AuthSlider'
import { mapValues, keyBy, values } from 'lodash'
import LanguageDropdown from '@/components/LanguageDropdown'
import config from 'config'

export default {
  name: 'AuthPage',
  components: {
    AuthSlider,
    LanguageDropdown
  },
  data() {
    return {
      fbSignInParams: {
        scope: 'email',
        return_scopes: true
      },
      emailField: {
        i18KeyLabel: 'common.forms.label.auth.email',
        name: 'email',
        type: 'email',
        value: '',
        validateRules: 'required|email|min:5',
        i18KeyPlaceholder: 'common.forms.placeholder.auth.email'
      },
      passwordField: {
        i18KeyLabel: 'common.forms.label.auth.password',
        name: 'password',
        type: 'password',
        value: '',
        validateRules: 'required|min:8|max:16',
        i18KeyPlaceholder: 'common.forms.placeholder.auth.password'
      }
    }
  },
  computed: {
    signInRequiredFields() {
      return [this.emailField, this.passwordField]
    },
    passwordIcon() {
      return this.passwordField.type === 'password' ? 'eye' : 'eye-slash'
    },
    clientAppUrl() {
      return config.clientAppUrl
    },
    clientLoginLink() {
      return `${this.clientAppUrl}/sign-in`
    }
  },
  mounted() {
    this.resetUserState()
    this.resetStateSalon()
    this.resetUserMessageState()
    this.resetSalonsState()
    this.resetHaircutrState()
    this.resetStateCalendar()
    this.resetStateStaff()
    this.resetStateDayOffs()
    this.resetStateServices()
  },
  methods: {
    ...mapActions('user', {
      register: 'register',
      login: 'login',
      getAccountInfo: 'getAccountInfo',
      socialAuth: 'socialAuth',
      setAuthStatus: 'setAuthStatus'
    }),
    ...mapMutations({
      resetUserState: 'user/RESET_STATE',
      resetStateSalon: 'salons/RESET_STATE',
      resetUserMessageState: 'userMessages/RESET_STATE',
      resetSalonsState: 'salons/RESET_STATE',
      resetHaircutrState: 'haircutParams/RESET_STATE',
      resetStateCalendar: 'calendar/RESET_STATE',
      resetStateStaff: 'salonsStaff/RESET_STATE',
      resetStateDayOffs: 'dayoffs/RESET_STATE',
      resetStateServices: 'services/RESET_STATE',
      setSalonId: 'user/SET_SALON_ID'
    }),
    signIn(type) {
      if (type === 'manual') {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.manualSignIn()
          }
        })
      } else if (type === 'google') {
        this.googleSignIn()
      }
    },
    onFacebookAuthSuccess() {
      // eslint-disable-next-line no-undef
      FB.api('/me?fields=id,name,email', res => {
        const dividedName = res.name ? res.name.split(' ') : ['', ''] // front error prevention
        const authPayload = {
          email: res.email,
          source: 'facebook',
          source_id: res.id,
          first_name: dividedName[0],
          second_name: dividedName[1],
          profile_url: null
        }
        this.socialAuth(authPayload).then(() => {
          this.setAuthStatus({ isAuthorized: true, socialAuthType: 'facebook' })
          this.$router.push('/salon')
        })
      })
    },
    onFacebookAuthError(err) {
      // eslint-disable-next-line
      console.error('Facebook Sign in error: ', err)
    },
    signUp(type) {
      if (type === 'google') {
        this.$gAuth
          .signIn()
          .then(userData => {
            const clientProfileData = values(userData)[2] // get object with profile data (is Google response object changing???)
            const clientId = userData.El
            const registerPayload = {
              source: 'google',
              source_id: clientId,
              email: clientProfileData.U3,
              first_name: clientProfileData.ofa,
              last_name: clientProfileData.wea,
              profile_url: null
            }
            this.socialAuth(registerPayload)
              .then(() => {
                this.setAuthStatus({ socialAuthType: 'google', isAuthorized: true })
                this.$router.push('/salon')
              })
              // eslint-disable-next-line
              .catch(err => console.error(`Social Sign Up error: ${err}`))
          })
          .catch(() => {
            this.setAuthStatus({ socialAuthType: '', isAuthorized: false })
          })
      } else if (type === 'manual') {
        const registerData = mapValues(keyBy(this.registerRequiredFields, 'name'), 'value')
        this.register(registerData)
          .then(() => {
            this.setAuthStatus({ isAuthorized: true, socialAuthType: 'manual' })
            this.$router.push('/salon')
          })
          // eslint-disable-next-line
          .catch(err => console.error(`Manual Sign Up error: ${err}`)) // TODO: implement existing error handling
      }
    },
    manualSignIn() {
      let loginData = mapValues(keyBy(this.signInRequiredFields, 'name'), 'value')

      const salonId = this.$router.currentRoute.query.k

      if (salonId && !isNaN(Number(salonId))) {
        loginData = {
          ...loginData,
          salonId: salonId
        }
      }

      this.login(loginData)
        .then(role => {
          if (role === 'client' || (role === 'admin' && !salonId)) {
            this.$validator.errors.add({ field: 'email', msg: this.$t('common.error.wrong_account') })
            return false
          }
          this.getAccountInfo()
            .then(() => {
              this.setAuthStatus({ isAuthorized: true, socialAuthType: 'manual' })

              if (role === 'staff') this.$router.push('/calendar')
              else if (role === 'manager') this.$router.push('/calendar')
              else if (role === 'owner' || role === 'salon_manager') this.$router.push('/salon')
              else if (role === 'admin') {
                this.setSalonId(salonId)
                this.$router.push('/salon')
              }
            })
            .catch(errors => {
              if (errors.success === false) {
                this.$validator.errors.add({ field: 'email', msg: this.$t('common.error.wrong_account') })
              }
            })
        })
        .catch(errors => {
          if (errors.success === false) {
            this.$validator.errors.add({ field: 'email', msg: this.$t('common.error.wrong_account') })
          }
        })
    },
    googleSignIn() {
      this.$gAuth
        .signIn()
        .then(userData => {
          const clientId = userData.El
          const clientProfileData = values(userData)[2] // get object with profile data (is Google response object changing???)
          const signInPayload = {
            source: 'google',
            source_id: clientId,
            email: clientProfileData.U3,
            first_name: clientProfileData.ofa,
            last_name: clientProfileData.wea,
            profile_url: null
          }
          this.socialAuth(signInPayload)
            .then(() => {
              this.getAccountInfo().then(() => {
                this.setAuthStatus({ isAuthorized: true, socialAuthType: 'google' })

                this.$router.push('/salon')
              })
            })
            // eslint-disable-next-line
            .catch(err => console.error('Google Sign In Error: ', err))
        })
        .catch(() => {
          this.setAuthStatus({ socialAuthType: '', isAuthorized: false })
        })
    },
    changeType() {
      this.passwordField.type === 'password'
        ? (this.passwordField.type = 'text')
        : (this.passwordField.type = 'password')
    }
  }
}
</script>
