// Auth Group
import SignUp from '@/views/auth/SignUp'
import SignIn from '@/views/auth/SignIn'
import ResetPassword from '@/views/auth/ResetPassword'
import Home from '@/views/Home'
import ContactUs from '@/views/contact/ContactUs'
import RequestDemo from '@/views/request-demo/RequestDemo'
import CoiffeurInDerNahe from '@/views/seo/CoiffeurInDerNahe'
import CoiffeurOhneVoranmeldung from '@/views/seo/CoiffeurOhneVoranmeldung'
import CoiffeurSoftware from '@/views/seo/CoiffeurSoftware'
import CoiffeurStGallen from '@/views/seo/CoiffeurStGallen'
import CoiffeurZürich from '@/views/seo/CoiffeurZürich'
import CoiffeurTerminOnline from '@/views/seo/CoiffeurTerminOnline'
import EmailVerification from '@/views/verification/EmailVerification'
import ThankYouPage from '@/views/thanks/ThankYouPage'

// Errors group
import NotFound from '@/views/404'
import RoleWrong from '@/views/403'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      i18PageTitle: 'main.page-title'
    },
    component: Home
  },
  {
    path: '/not-found',
    name: 'not-found',
    meta: {
      i18PageTitle: ''
    },
    component: NotFound
  },
  {
    path: '/403',
    name: '403',
    meta: {
      i18PageTitle: ''
    },
    component: RoleWrong
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    meta: {
      i18PageTitle: 'signup.page-title'
    },
    component: SignUp
  },
  {
    path: '/sign-in/:k?',
    name: 'sign-in',
    meta: {
      i18PageTitle: 'signin.page-title'
    },
    component: SignIn
  },
  {
    path: '/reset-password-request',
    name: 'reset-password-request',
    meta: {
      i18PageTitle: 'forgot_password.page-title',
      layout: 'auth'
    },
    component: ResetPassword
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password-new',
    meta: {
      i18PageTitle: 'forgot_password.page-title',
      layout: 'auth'
    },
    component: ResetPassword
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    meta: {
      i18PageTitle: 'contact-us.title',
      layout: 'request'
    },
    component: ContactUs
  },
  {
    path: '/request-demo',
    name: 'request-demo',
    meta: {
      i18PageTitle: 'contact-us.title',
      layout: 'request'
    },
    component: RequestDemo
  },
  {
    path: '/Coiffeur-in-der-Nähe',
    name: 'Coiffeur-in-der-Nähe',
    meta: {
      i18PageTitle: 'Coiffeur in de Nähe finden – leicht, unkompliziert, reservieren | Quiff',
      layout: 'seo'
    },
    component: CoiffeurInDerNahe
  },
  {
    path: '/Coiffeur-ohne-Voranmeldung',
    name: 'Coiffeur ohne Voranmeldung',
    meta: {
      i18PageTitle: 'Coiffeur ohne Voranmeldung gesucht? Dann bist Du bei Quiff goldrichtig | Quiff',
      layout: 'seo'
    },
    component: CoiffeurOhneVoranmeldung
  },
  {
    path: '/Coiffeur-Software',
    name: 'Coiffeur Software',
    meta: {
      i18PageTitle: 'Die revolutionäre Coiffeur Software – kostenfrei von Quiff!',
      layout: 'seo'
    },
    component: CoiffeurSoftware
  },
  {
    path: '/Coiffeur-Termin-online',
    name: 'Coiffeur-Termin-online',
    meta: {
      i18PageTitle: 'Coiffeur Termin online verwalten, buchen, Bewertungen sammeln. Quiff für Salons!',
      layout: 'seo'
    },
    component: CoiffeurTerminOnline
  },
  {
    path: '/Coiffeur-St-Gallen',
    name: 'Coiffeur St Gallen',
    meta: {
      i18PageTitle: 'Coiffeur in St. Gallen – finde Deinen Favoriten und buche online | Quiff',
      layout: 'seo'
    },
    component: CoiffeurStGallen
  },
  {
    path: '/Coiffeur-Zürich',
    name: 'Coiffeur-Zürich',
    meta: {
      i18PageTitle: 'Bester Coiffeur in Zürich? Wähle Deinen Favoriten und buche einen Termin | Quiff',
      layout: 'seo'
    },
    component: CoiffeurZürich
  },
  {
    path: '/verification/:id/:expires/:signature',
    name: 'verification',
    meta: {
      i18PageTitle: 'profile_credit-card.page-title',
      layout: 'auth'
    },
    component: EmailVerification
  },
  {
    path: '/thank-you-page',
    name: 'thank-you-page',
    meta: {
      i18PageTitle: 'Thank you page',
      layout: 'header-bordered'
    },
    component: ThankYouPage
  }
]

export default routes.map(route => {
  const meta = {
    public: true,
    onlyLoggedOut: true,
    layout: 'auth',
    ...route.meta
  }
  return { ...route, meta }
})
