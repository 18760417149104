import Repository from './repository'

const createUrl = '/manual-book'
const updateUrl = '/manual-book-update'
const detailsUrl = '/get-manual-book'
const desclineUrl = '/book-decline'
const closeUrl = '/book-close'
const declineProductUrl = '/products-remove'
const payProductsUrl = '/products-pay'
const payServicessUrl = '/book-pay'
const updateAddPricesUrl = '/manual-book-update-addprices'

export const BookingRepository = {
  create(payload) {
    return Repository.post(createUrl, payload).then(({ data }) => data)
  },
  update(payload) {
    return Repository.post(updateUrl, payload).then(({ data }) => data)
  },
  get(payload) {
    return Repository.get(`${detailsUrl}?book_id=${payload}`).then(data => data.data)
  },
  decline(payload) {
    return Repository.delete(`${desclineUrl}/${payload}`).then(data => data.data)
  },
  close(payload) {
    return Repository.post(closeUrl, payload).then(data => data.data)
  },
  declineProduct(payload) {
    return Repository.post(declineProductUrl, payload).then(data => data.data)
  },
  payForProducts(payload) {
    return Repository.post(payProductsUrl, payload).then(data => data.data)
  },
  payForServices(payload) {
    return Repository.post(payServicessUrl, payload).then(data => data.data)
  },
  updateAddPrices(payload) {
    return Repository.post(updateAddPricesUrl, payload).then(data => data.data)
  }
}
